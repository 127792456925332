.user-passwordsreset {
  padding-top: 50px;

  .card-header {
    align-self: center;
  }

  .navbar-brand.main-logo {
    width: unset;
  }

  .alert.alert-danger {
    top: 1rem !important;
    right: 1rem !important;
  }

  .main-card {
    @media (max-width: 576px) {
      width: calc(100% - 16px);
    }

    width: 424px;
    margin-left: auto;
    margin-right: auto;

    .card-header {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
      background-color: unset !important;
      border-bottom: 1px solid #f1f1f1 !important;

      .navbar-brand.main-logo {
        color: unset;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;

      .lock-icon {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .main-form {
        @media (max-width: 576px) {
          padding: 0 16px;
        }

        padding: 0 60px;

        h2 {
          padding-top: 8px;
          font-size: 20px;
          font-weight: bold;
          text-transform: capitalize;
        }

        .form-group {
          margin-bottom: 2rem;
          position: relative;
        }

        .showpass {
          position: absolute;
          right: 0;
          top: 1.5rem;
          cursor: pointer;
        }

        .form-control {
          padding: 1.5rem 1rem .25rem 0rem;
          // background-color:rgba(246, 246, 246, 1);
          border-radius: 0.0em;
          border: none;
          border-bottom: 2px solid rgba(0, 0, 0, 1);
          color: #000;
          height: auto;
          outline: unset;

          &:focus {
            outline: unset;

            box-shadow: unset;
          }
        }

        label {
          font-size: 1rem;
        }

        .form-control.is-invalid {
          background-image: unset !important;
          border-bottom: 2px solid #dc3545;

          &+label {
            color: #dc3545;
          }
        }

        /* added to resolve appearing text twice */
        .form-label-group>label {
          visibility: hidden;
        }

        .form-label-group input:not(:placeholder-shown)~label {
          visibility: visible;
          font-size: 0.875rem;
          left: 0rem;
        }
      }

      .login-btn {
        width: 100%;
        padding: 0.5rem 2rem !important;
        margin-top: 16px;

        img {
          margin-right: 7px;
        }
      }
    }
  }
}