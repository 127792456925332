::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.versioncode {
  bottom: 25px;
  position: fixed;
  width: 100%;
  text-align: center;
  left: 0;
}

footer {
  border-top: 8px solid #ffa500;
  background-color: #000;
  padding: 10px;
  color: #fff;
  .footerWrapper {
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 640px) {
      max-width: 100%;
    }
  }
  .footerMenu {
    display: flex;
    li {
      a {
        display: block;
        padding: 5px;
        color: #fff;
        font-weight: 500;
        margin: 0 10px;
      }
      a:hover {
        color: #fff;
      }
    }
    @media only screen and (max-width: 640px) {
      width: 76.5%;
      margin: auto;
    }
  }
  .copyright,
  .version_code {
    padding: 7px 0;
    display: block;
    @media only screen and (max-width: 640px) {
      text-align: center;
    }
  }
}

.form-label-group > label {
  top: 1.1rem;
  right: 1rem;
  text-align: right;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  top: 0 !important;
}
.loginpage {
  margin-top: -30px;
  max-width: 100% !important;
  padding: 0;
}
.z-index-1 {
  z-index: 500;
}
.wd-1024 {
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 0.5rem !important;
  }
}
.sort-arrow {
  transition-delay: 0.5s;
  position: absolute;
  top: 5px;
  img {
    width: 1rem;
  }
}
.sort-arrow-down {
  transform: rotateZ(0deg);
}
.sort-arrow-up {
  transform: rotateZ(180deg);
}
.product-list {
  .card-body {
    padding: 15px;
    .list-total {
      text-align: right;
    }
    .select-css-price {
      position: relative;
      // display: block;
      font-size: 17px;
      // color: #7d7d7d;
      line-height: 1.3;
      padding: 0rem 0 0 0.95rem;
      // width: 100%;
      // max-width: 75px;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 0.25em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      // background-color: #d3d3d3;
      background-image: url("../../assets/dropdown-arrow.png");
      background-repeat: no-repeat, repeat;
      /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
      background-position: left 0em top 50%, 0 0;
      /* icon size, then gradient */
      background-size: 0.7em auto, 100%;
      border: 1px solid rgba(0, 0, 0, 0);

      option {
        font-weight: normal;
        color: #000;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }
}
.text-right {
  text-align: left !important;
}
.mobileSearch {
  position: fixed;
  left: 0;
  right: 0;
  top: 58px;
  padding: 10px;
  background: #fff;
  .search-btn {
    top: 11px;
    right: 12px;
  }
}
.modal-header > button {
  margin: -1rem -1rem -1rem -1rem !important;
}
// .rounded-pill {
//     @media (max-width: 767.98px) {
//         height: 32px;
//     }
// }
.scroll-arrow {
  float: left;
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
}
.factbooks {
  text-align: left;
  div {
    display: inline-block;
  }
  div + a {
    margin-left: 10px;
  }
  a + a {
    margin-left: 10px;
  }
}
.bdr-saprator {
  display: block;
  background: #000;
  height: 3px;
  margin: 0.75rem 0;
}
.bdr-saprator-2 {
  display: block;
  background: #ccc;
  height: 1px;
  margin: 0.5rem 0;
}
.qtylable {
  text-align: center;
  background: orange;
  position: absolute;
  border-radius: 50%;
  left: 15px;
  top: 10px;
  width: 15px;
  height: 15px;
  color: #000;
  font-size: 0.75rem;
  padding: 0;
  border: 1px solid orange;
  line-height: 0.75rem;

  @media only screen and (max-width: 376px) {
    left: 20px;
  }
}

.nav-notification ~ .qtylable {
  right: 25px;
}

.reservedStcokqtylable {
  text-align: center;
  background: orange;
  position: absolute;
  border-radius: 50%;
  left: 20px;
  top: 5px;
  width: 15px;
  height: 15px;
  color: #000;
  font-size: 0.75rem;
  padding: 0;
  border: 1px solid orange;
  line-height: 0.75rem;
}
.basket {
  margin-right: 35px;
  margin-top: 2px;
}
.enlarged {
  background: #fff;
  // margin-left: 0 !important;
  left: calc(-100% - 10px) !important;
  direction: ltr;
  z-index: 1;
}
.cursor_pointer_decoration {
  cursor: pointer;
  text-decoration: underline;
}
.cursor_pointer {
  cursor: pointer;
}
.order-status {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  //border: 1px solid;
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 10px;

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    display: block;
  }

  &.placed {
    border: 1px solid;
    border-color: #616161;
  }
  &.confirm {
    border-color: #ffa500;
    background: transparent; /* Old browsers */
    background: -moz-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, transparent),
      color-stop(50%, transparent),
      color-stop(50%, #ffa500),
      color-stop(100%, #ffa500)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      transparent 0%,
      transparent 50%,
      #ffa500 50%,
      #ffa500 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* IE10+ */
    background: linear-gradient(to right, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#FFA500',GradientType=1 ); /* IE6-9 */

    &:after {
      content: "";
      position: absolute;
      right: 0.02rem;
      bottom: 0.01rem;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 0 0 50px 0;
    }
  }
  &.ready-to-ship {
    border-color: #ffa500;
    background: transparent; /* Old browsers */
    background: -moz-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, transparent),
      color-stop(50%, transparent),
      color-stop(50%, #ffa500),
      color-stop(100%, #ffa500)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      transparent 0%,
      transparent 50%,
      #ffa500 50%,
      #ffa500 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* IE10+ */
    background: linear-gradient(to right, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#FFA500',GradientType=1 ); /* IE6-9 */

    &:after {
      content: "";
      position: absolute;
      right: 0.02rem;
      bottom: 0.01rem;
      width: 50%;
      height: 0%;
      background-color: white;
      border-radius: 0 0 50px 0;
    }
  }
  &.in-transit {
    border-color: #ffa500;
    background: #ffa500; /* Old browsers */

    &:after {
      content: "";
      position: absolute;
      left: 0.02rem;
      top: 0.01rem;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 50px 0 0px 0;
    }
  }
  &.delivered {
    background: #008435;
    border-color: #008435;
    color: #fff;
    font-size: 0.75rem;
    padding: 2px;
  }
  &.cancelled {
    border-color: #8e1216;
    background: #8e1216;
    &::after {
      font-family: "kislev" !important;
      border-radius: 50%;
      content: "\e910";
      position: absolute;
      color: #fff;
      left: 4px;
      top: 2px;
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 740px) {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    img {
      width: 15px;
      height: 15px;
    }
  }
}
.order-status-track {
  position: relative;
  li {
    padding: 0px 25px 30px 0px;
    position: relative;
    color: rgb(155, 155, 155);
    h5 {
      color: rgb(155, 155, 155);
    }
    .status {
      width: 14px;
      height: 14px;
      border: 1px solid #008435;
      border-radius: 50%;
      display: block;
      position: absolute;
      right: 0px;
      top: 5px;
      background: #008435;
      z-index: 1;
      &::after {
        font-family: "kislev" !important;
        border-radius: 50%;
        content: "\e90f";
        position: absolute;
        color: #fff;
        left: 2px;
        top: 1px;
        font-size: 8px;
      }
    }

    &:first-child {
      color: #008435;
      h5 {
        color: #008435;
      }
      .status {
        width: 14px;
        height: 14px;
        border: 1px solid #008435;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 15px;
        top: 5px;
        background: #fff;
        z-index: 1;
        &::after {
          border-radius: 50%;
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #008435;
          left: 2px;
          top: 2px;
        }
      }
    }
    &.isCancelled {
      color: #8e1216;
      h5 {
        color: #8e1216;
      }
      .status {
        width: 14px;
        height: 14px;
        border: 1px solid #8e1216;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 15px;
        top: 5px;
        background: #fff;
        z-index: 1;
        &::after {
          border-radius: 50%;
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #8e1216;
          left: 2px;
          top: 2px;
        }
        @media only screen and (max-width: 415px) {
          left: 0px;
        }
      }
    }
    &:last-child {
      &::after {
        position: absolute;
        left: 20px;
        top: 5px;
        content: "";
        border-left: 0px solid #008435;
        height: 100%;
      }
    }
    &::after {
      position: absolute;
      right: 5px;
      top: 5px;
      content: "";
      border-left: 3px solid #008435;
      height: 100%;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  right: 10px;
  position: absolute;
}

.order-tab {
  // border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1.2rem;
  .nav-item {
    font-size: 1.2rem;
    .nav-link {
      &.active {
        background-color: transparent;
        font-weight: bold;
        color: #000;
        border-bottom: 2px solid #000;
      }
      &:hover {
        font-weight: bold;
        border-color: transparent;
        border-bottom: 2px solid #000;
      }
    }
  }
}
.order-search {
  position: absolute;
  top: 1rem;
  right: 7rem;
  z-index: 1;
  .form-control {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
  .btn-link {
    padding: 0.45rem 0.75rem 0.3rem 0.75rem;
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0px;
    text-decoration: none;
  }
  &.complaint {
    top: 1rem;
    right: 10rem;
  }
  &.stock_search {
    width: 200px;
    top: 0rem;
    left: 13rem;
  }
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    top: 6rem;
    right: 1em;
    width: 57%;
    .form-control {
      height: 36px;
    }
    .btn-link {
      padding: 0.25rem 0.75rem 0.3rem 1.75rem !important;
      top: 4px;
    }
    &.complaint {
      width: 100%;
      position: initial;
      left: 0;
      margin-right: -1rem;
    }
    &.stock_search {
      width: 100%;
      position: relative !important;
      right: 0;
      top: 0;
    }
  }

  @media only screen and (max-width: 475px) {
    top: 4.9em;
    right: 1em;
    width: 63%;
    .form-control {
      height: 36px;
    }
    .btn-link {
      padding: 0.25rem 0.75rem 0.3rem 1.75rem !important;
      top: 4px;
    }
    &.complaint {
      width: 100%;
      position: initial;
      left: 0;
      margin-right: -1rem;
    }
    &.stock_search {
      width: 100%;
      // position: relative !important;
      right: 0;

      top: 0;
    }
  }
  @media only screen and (max-width: 415px) {
    font-size: 15px;
    top: 4.1em;
    width: 63%;
  }
  @media only screen and (max-width: 376px) {
    top: 4.9rem;
    right: 1rem;
    width: 59%;
  }
  @media only screen and (max-width: 360px) {
    top: 5.1rem;
    right: 1rem;
    width: 55%;
    &.stock_search {
      width: 90%;
      right: 0;
      top: 0.5rem;
    }
  }
}
.sub-total-he {
  text-align: right !important;
}
.card-text:last-child {
  white-space: break-spaces;
  margin-bottom: 0;
}
.transparent-btn {
  background-color: transparent;
  color: #000;
}
.sortby {
  .select-css {
    background: #e7e7e7;
    position: relative;
    display: inline-block;
    font-size: 15px;
    color: #7d7d7d;
    line-height: 1;
    padding: 0.5rem 1.95rem 0.5rem 1rem;
    width: auto;
    max-width: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    border-radius: 1.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    border: none;
    background-image: url("../../assets/dropdown-arrow-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: left 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    padding-left: 2rem;
    /* Set options to normal weight */
    option {
      font-weight: normal;
      color: #000;
      text-align: center;
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }

    @media only screen and (max-width: 475px) {
      background-color: #e0e0e0;
      border: 1px solid #748892;
    }
  }
}
.complaint-btn {
  margin-bottom: 1rem;
  @media only screen and (max-width: 475px) {
    margin-bottom: 1.3rem;
    min-height: 36px;
    min-width: 6.6rem;
  }
  @media only screen and (max-width: 360px) {
    margin-bottom: 1.6rem;
  }
}
.order-complaint {
  margin-top: -4em;
  @media only screen and (max-width: 475px) {
    position: absolute;
    top: 3.2em;
    left: 1rem;
  }
  @media only screen and (max-width: 376px) {
    top: 5.4em;
  }
}
.order-search.complaint + .row button.rounded-pill.mr-3.transparent-btn.complaint-file-c.btn.btn-secondary {
  margin-left: 0.5rem;
}
button.rounded-pill.mr-3.transparent-btn.complaint-file-c.text-nowrap.btn.btn-secondary {
  margin-right: 0 !important ;
}
.reservedstock {
  margin-top: 1rem;
  & + .order-search {
    top: 1rem;
    right: 10rem;
    input {
      padding-left: 1.5rem;
    }
  }
}
.Toastify {
  & > .Toastify__toast-container.Toastify__toast-container--bottom-center {
    width: fit-content;
  }
}
.complaint-form {
  font-size: 1.1rem;
  .form-control {
    background: rgb(231, 231, 231);
    height: calc(2em + 0.75rem + 2px);
    border: 1px solid #e7e7e7;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container input {
      background: rgb(231, 231, 231);
      height: calc(2em + 0.5rem + 2px);
      border: 1px solid #e7e7e7;
      width: 100%;
      border-radius: 0.25em;
      padding-right: 1em;
    }
  }

  .select-css {
    background: #e7e7e7;
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.75rem 0.75rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #e7e7e7;
    border: none;
    /* Set options to normal weight */
    option {
      font-weight: normal;
      color: #000;
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }
    .email-error {
      color: $danger-color;
      white-space: nowrap;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  .account-security {
    width: 90%;
    .border-bottom {
      border-bottom-width: 2px !important;
    }
  }

  textarea.form-control {
    height: 320px;
    resize: none;
  }
  /*label{
        position: absolute;
        left: 25px;
        top: 10px;
    }*/
}

.complaint_status {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 10px;
  &.received {
    border-color: #616161;
  }

  &.processing {
    border-color: #ffa500;
    background: #ffa500;
  }
  &.approve {
    border-color: #008435;
    background: #008435;
    &::after {
      font-family: "kislev" !important;
      border-radius: 50%;
      content: "\e90f";
      position: absolute;
      color: #fff;
      left: 2px;
      top: 1px;
      font-size: 8px;
    }
  }
  &.reject {
    border-color: #8e1216;
    background: #8e1216;
    &::after {
      font-family: "kislev" !important;
      border-radius: 50%;
      content: "\e910";
      position: absolute;
      color: #fff;
      left: 2px;
      top: 1px;
      font-size: 8px;
    }
  }
}

.header-order + .tab-content > .tab-pane.active .card {
  @media only screen and (max-width: 475px) {
    padding-left: 0.5rem;
  }
}
.header-order + .tab-content > .tab-pane.active .card-body > .row > div:nth-child(5) {
  @media only screen and (max-width: 475px) {
    // margin-left: -2.7em;
    padding-right: 0 !important;
  }
}
.header-order + .tab-content > .tab-pane.active .card-body > .row > .dropmenu-icon {
  @media only screen and (min-width: 768px) {
    order: 7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  @media only screen and (max-width: 768px) {
    display: unset !important;
    position: absolute;
    left: 5px;
    span {
      top: -8px;
      transform: rotateZ(90deg);
      position: absolute;
      left: 5px;
      color: #00000073;
    }
  }
  @media only screen and (max-width: 475px) {
    display: unset !important;
    position: absolute;
    left: 5px;
    span {
      top: 8px;
      transform: rotateZ(90deg);
      position: absolute;
      left: 5px;
      color: #00000073;
    }
  }
  @media only screen and (max-width: 475px) and (min-width: 376px) {
    right: 3rem !important;
  }
  @media only screen and (max-width: 376px) and (min-width: 360px) {
    right: 0.5rem !important;
  }
}

.login-card {
  position: absolute;
  z-index: 1;
  right: 2%;
  padding: 3rem 2.5rem 1rem;
  width: 21rem;
  z-index: 9999;
  .form-control {
    padding: 1rem 1rem 0.25rem;
    background-color: rgba(246, 246, 246, 1);
    border-radius: 0em;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.45);
    color: #000;
    height: auto;
  }
  label {
    font-size: 1rem;
    left: -1rem;
  }
}
.minw-22 {
  min-width: 22rem;
  max-width: 22rem;
}
.minw-15 {
  min-width: 15rem;
  max-width: 15rem;
}

.user-nav {
  .ml-auto {
    margin-left: 0 !important;
    margin-right: auto;
  }
  .dropdown-menu {
    background-color: rgba(26, 26, 26, 1);
    color: #fff;
    left: -8rem;
    border-radius: 0;
    margin: 0.1rem 0 0 0;
    padding: 0;
    &.show:before {
      content: "";
      position: absolute;
      left: 10rem;
      top: -20px;
      z-index: 1001;
      font-size: 40px;
      line-height: 0;
      color: #fff;
      text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(26, 26, 26, 1);
      border-left: 10px solid transparent;
    }
    .dropdown-item {
      text-align: right;
      color: #ffff;
      white-space: pre-wrap;
      padding: 1rem 1.5rem;
      &:hover {
        background-color: transparent;
      }
      h5 {
        color: #fff;
        small {
          float: left;
          margin-top: 3px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .dropdown-item + .dropdown-item {
      border-top: 1px solid rgba(255, 255, 255, 0.35);
    }
  }
}

.dropdown-menu {
  left: -8rem;
  border-radius: 0;
  margin: 0.1rem 0 0 0;
  padding: 0;
  // &.show:before{
  //     content: "";
  //     position: absolute;
  //     left: 10rem;
  //     top: -20px;
  //     z-index: 1001;
  //     font-size: 40px;
  //     line-height: 0;
  //     color: #fff;
  //     text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
  //     border-top: 10px solid transparent;
  //     border-right: 10px solid transparent;
  //     border-bottom: 10px solid rgba(26,26,26,1);
  //     border-left: 10px solid transparent;
  // }
}

.dropmenu {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 5px #616161;
  width: 15rem;
  text-align: left;
  padding: 10px;
  z-index: 1025;
  border-radius: 5px;
  right: auto;
  left: 0;
  ul {
    li {
      a {
        color: #000;
        &:hover {
          color: #616161;
        }
      }
      display: block;
      list-style: none;
      padding: 10px 15px;
    }
  }
  @media only screen and (max-width: 475px) {
    left: 0rem;
    top: 1rem;
  }
}

.forgotpassword {
  position: absolute;
  max-width: 339px;
  text-align: center;
  box-shadow: 6px 6px 16px #00000008;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) !important;
  .modal-content {
    border-radius: unset !important;
  }
  .modal-body {
    .form-group {
      margin-top: 2rem !important;
      input[type="text"] {
        background-color: #e0e0e0;
        height: 3rem;
        border-bottom: 0.5px solid rgba(0, 0, 0, 1);
      }
    }
    padding: 3.125rem !important;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 2rem !important;
    }
    button {
      margin-top: 10px !important;
      box-shadow: 0px 3px 6px #00000029;
      height: 3rem;
      display: inline;
    }
    a {
      color: #000;
    }
  }
  .form-control {
    padding: 1rem 1rem 0.25rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 0em;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.45);
    color: #000;
    height: auto;
  }
}

.filter-icon-btn {
  vertical-align: middle;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-right: 1.5rem;
  display: inline-block;
  cursor: pointer;
  color: #9c9d9d;
  span:hover {
    color: #000 !important;
  }
  &.active {
    span {
      color: #000 !important;
    }
  }
  @media only screen and (max-width: 376px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

.search-btn {
  line-height: 1rem;
  position: absolute;
  top: 1px;
  left: 16px;
  background: #ffa500;
  border: 0;
  padding: 0.563rem 0.625rem;
  &:hover {
    border: none;
  }
  @media only screen and (max-width: 420px) {
    top: 0px;
    right: 0px;
  }
}
.rotate-90 {
  transform: rotate(90deg);
}
.filters-box {
  overflow-y: auto;
  width: 294px;
  background: url("../../assets/side-bg.png") no-repeat top;
  background-size: cover;
  height: 100%;
  @media (min-width: 768px) {
    height: calc(100% - 80px);
  }
  @media (max-width: 420px) {
    .filter-button_btn {
      position: absolute;
      bottom: 3rem;
      left: 0;
      display: flex;
      width: 100%;
      padding: 1.5rem 2rem 1rem;
      background-color: #000000;
      > div.row {
        margin-right: 0;
        margin-left: 0;
        &:nth-child(1) {
          order: 2;
          flex-basis: 55%;
        }
        &:nth-child(2) {
          flex: 1;
          margin-right: 1rem;
        }
        button.btn {
          height: 40px;
        }
      }
    }
  }
  position: fixed;
  z-index: 10000;
  top: 80px;
  right: -294px;
  color: #fff;
  padding: 2rem 2rem;
  transition: ease-in 2s linear;
  h3 {
    color: #fff;
    margin: 0 -10px 25px;
  }

  &.open {
    right: 0;
  }
  button.btn-primary {
    display: inline;
  }
  .close {
    color: #fff;
    opacity: 1;
    font-size: 1rem;
    line-height: 1.9rem;
    float: left;
  }

  label {
    color: rgba(255, 255, 255, 0.35);
    position: absolute;
    right: 0.75rem;
    top: 0;
    font-size: 16px;
  }
  .select-css {
    position: relative;
    display: block;
    font-size: 17px;
    color: #fff;
    line-height: 1.3;
    padding: 1.5rem 0.5rem 0.25rem;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: rgba(255, 255, 255, 0.15);
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
              for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
              
          */
    background-image: url("../../assets/white-arrow.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: left 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    /* Set options to normal weight */
    option {
      font-weight: normal;
      color: #000;
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }
  }
  .form-control {
    padding: 1.5rem 1rem 0.25rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 0em;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    color: #fff;
    height: auto;
  }
  .btn-outline-secondary {
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background: transparent;
      border: 1px solid #ffa500;
      color: #ffa500;
    }
  }

  @media only screen and (max-width: 420px) {
    top: 52px;
    width: 100%;
    right: -100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
    input::placeholder {
      color: #fff;
    }
  }
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    top: 0;
  }
}
.filter-on {
  &.container {
    width: calc(100% - 320px);
    margin-right: 320px;
    padding-right: 30px;
    @media only screen and (max-width: 960px) and (orientation: landscape) {
      width: calc(100% - 300px);
      margin-right: 300px;
      padding-left: 30px;
      max-width: 100% !important;
    }
    @media only screen and (max-width: 455px) {
      width: 100%;
      margin-right: 0px;
      padding-left: 15px;
      max-width: 100% !important;
    }
  }
}
.logo-wrapper {
  // margin-left: 2rem;
  max-width: 23%;
}
.mob-card {
  @media only screen and (max-width: 768px) {
    min-height: 156px;
  }
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    min-height: calc(100% - 20px);
  }
}
.mobile-view {
  position: relative;
  @media only screen and (max-width: 1024px) {
    position: absolute;
    max-width: 62%;
    left: 10px;
    bottom: -48px;
    z-index: 1;
  }
  @media only screen and (max-width: 768px) {
    max-width: 50%;
    left: 0px;
    bottom: 20px;
  }
  @media only screen and (max-width: 375px) {
    max-width: 45%;
    left: -5px;
    bottom: 10px;
    z-index: 1;
  }
}
.left-box {
  overflow-y: auto;
  width: 294px;
  background: url("../../assets/side-bg.png") no-repeat top;
  background-size: cover;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 75px;
  right: -100%;
  color: #fff;
  padding: 2rem 2rem;
  transition: ease-in 2s linear;

  &.open {
    right: 0;
  }

  @media only screen and (max-width: 420px) {
    top: 52px;
    width: 100%;
    left: -100%;
    z-index: 4;
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    top: 0;
  }

  .usernav {
    h3 {
      color: #ffa500;
      margin-bottom: 2rem;
    }
    ul {
      margin: 0 -2rem;
      li {
        font-size: 1rem;
        border-bottom: 1px solid #e0e0e075;
        span {
          display: inline-block;
          margin-left: 0.75rem;
        }
        a {
          color: #fff;
          font-weight: 700;
          padding: 0.844rem 2rem;
          display: block;
        }
        &.active {
          background: #ffa500;
          border: 0;

          a {
            color: #000;
            font-weight: bold;
          }
        }

        &:hover {
          background: #ffa500;
          a {
            color: #000;
            font-weight: bold;
          }
        }
      }
      li.active + li {
        border-top: 1px solid transparent;
        &:hover {
          border-top: 1px solid #e0e0e075;
        }
      }
      //li:last-child{border-bottom: 0px;}
    }
    & ul li:hover img {
      filter: invert(1);
    }
  }
}

.userCard {
  min-height: 460px;
  margin-bottom: 30px;
  .userField {
    background: rgba(222, 222, 222, 0.35);
    padding: 0.75rem 1rem;
    margin-top: 0.35rem;
  }
  .editfield {
    display: inline-block;
    padding: 0.5rem;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
  .userField-underline {
    padding: 0.75rem 0rem;
    margin-top: 0.35rem;
  }
}
.nav-item {
  position: relative;
}
.nav-item + .nav-item {
  margin-left: 1rem;
  @media only screen and (max-width: 740px) and (orientation: landscape) {
    margin-left: 0rem !important;
    margin-right: 1rem !important;
  }
  @media only screen and (max-width: 375px) {
    margin-left: 0rem !important;
    margin-right: 1rem !important;
  }
}
.user-nav {
  display: flex;
  flex: 1;
}
.nav-icon {
  font-size: 1.5rem;
  display: block;
  text-align: center;
}
.nav-txt {
  font-size: 0.9rem;
  display: block;
  text-align: center;
}
// .border-bottom{ border-bottom: 2px solid  !important;}
.filter-tag {
  display: inline-block;
  padding: 3px 5px;
  border: 1px solid #949494;
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 6px;
}
.filter-btn {
  .btn-outline-secondary {
    background-color: transparent;
  }
  .dropdown-menu {
    padding: 10px 0;
    min-width: 100%;
    transform: none !important;
  }
  .dropdown-item {
    padding: 0.25rem 0.75rem;
  }
}

.bdg {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;

  [class^="icon-"] {
    font-size: 0.65rem;
    line-height: 0.9rem;
  }
  @media only screen and (max-width: 1024px) {
    width: 17px;
    height: 17px;
  }
  @media only screen and (max-width: 475px) {
    width: 17px;
    height: 17px;
    [class^="icon-"] {
      font-size: 0.5rem;
      line-height: 0.7rem;
    }
  }
}

.bdg-small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;

  [class^="icon-"] {
    font-size: 0.65rem;
    line-height: 0.9rem;
  }
}

.bdg-big {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;

  [class^="icon-"] {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.fnt13 {
  font-size: 0.6875rem;
}
.fnt12 {
  font-size: 0.75rem;
}
.fnt13 {
  font-size: 0.95rem;
}
.fnt16 {
  font-size: 1rem;
}
.quntity {
  border: 1px solid #ccc;
  border-radius: 1px;
  font-weight: 700;
  min-width: 120px;
  display: inline-flex;
  .form-control {
    background: #e0e0e0;
    padding: 0;
    text-align: center;
    border-radius: 0;
    font-weight: 700;
    border: 0;
  }
  [class^="icon-"] {
    line-height: 2rem;
  }
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    float: right;
  }
  @media only screen and (max-width: 740px) and (orientation: landscape) {
    margin-top: 12px;
  }
}

.approve-icon {
  font-size: 3rem;
  text-align: center;
  line-height: 3rem;
  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    line-height: 2rem;
  }
  @media only screen and (max-width: 475px) {
    font-size: 2rem;
    line-height: 2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.page-title {
  padding: 20px;
  margin: 0px -20px;
  position: relative;

  .page-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .page-title-heading,
  .page-title-subheading {
    margin: 0;
    padding: 0;
  }
  .page-title-heading {
    font-size: 1.75rem;
    font-weight: 700;
    display: flex;
    align-content: center;
    align-items: center;
    color: $theme-heading-color;
  }
  .page-title-subheading {
    padding: 3px 0 0;
    font-size: 0.88rem;
    opacity: 0.6;
  }
  .page-title-icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 0.8333333333rem;
    margin: 0 30px 0 0;
    background: #fff;
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
      0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
    border-radius: 0.25rem;
    width: 60px;
    height: 60px;

    i {
      margin: auto;
    }
  }
  .rounded-circle {
    margin: 0 20px 0 0;
  }
}
.dark-btn {
  color: #000;
  border-color: #000;
  line-height: 1rem;
}
.recentView-1 {
  .cta-btn {
    @media only screen and (max-width: 667px) and (orientation: landscape) {
      padding: 10px 25px 5px;
      margin-right: 25px;
    }
  }
}
.cta-btn {
  max-width: 160px;
  width: 100%;
  padding: 7px 20px 7px;
  .icon-cart {
    position: relative;
    display: inline-block;
    .icon-plus {
      background: #000;
      position: absolute;
      border-radius: 50%;
      right: -6px;
      top: -5px;
      width: 12px;
      height: 12px;
      color: orange;
      font-size: 0.45rem;
      padding: 1px;
      border: 1px solid;
    }
  }
  @media only screen and (max-width: 1366px) {
    padding: 7px 17px 7px;
  }
  @media only screen and (max-width: 810px) {
    padding: 10px 25px 9px;
    .icon-cart {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 667px) and (orientation: landscape) {
    padding: 10px 10px 9px;
  }
  @media only screen and (max-width: 400px) {
    // margin-top: 10px;
    padding: 7px 17px 7px;
  }
}

/* added text underline class*/
.text-underline {
  text-decoration: underline;
}
.navbar {
  padding: 0.2rem 1rem;
}
.list-product-title {
  font-size: 1rem;
  font-weight: 700;
  text-decoration: underline;
  padding-right: 1rem !important;
  padding-left: 0;
}
.list-product-detail {
  @media only screen and (max-width: 475px) {
    max-width: 50%;
  }
}
.featureTag {
  background: #ffa500;
  padding: 1px 15px;
  position: absolute;
  right: 0;
  top: -12px;
  font-weight: 700;
  font-size: 0.95rem;
}
.product-title {
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 1rem;
  @media only screen and (max-width: 475px) {
    margin-bottom: 0.7rem;
  }
}
.total {
  font-size: 1.25rem;
  line-height: 1.25rem;
  @media only screen and (max-width: 740px) {
    font-size: 1rem;
    line-height: 1rem;
  }
}
.product-pic {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.modal-close {
  position: absolute;
  left: -20px;
  top: -20px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
.lang {
  &.select-css {
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.5rem 0.75rem;
    width: 100%;
    max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f2f2f2;
    background-image: url("../../assets/dropdown-arrow-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: left 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
  &.select-css-logout {
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.5rem 0.75rem;
    width: 100%;
    max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #d3d3d3;
    background-image: url("../../assets/dropdown-arrow-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: left 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 1);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
.product-details {
  .modal-body {
    padding: 2rem 1.5rem 1rem;
  }
  .title-wrapper {
    border-bottom: 2px solid #ffa500;
  }
  .product-title {
    font-size: 1.8rem;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;
  }
  .product-size {
    font-size: 1.6rem;
    display: inline-block;
    margin-right: 1rem;
  }
  .product-type {
    font-size: 1.6rem;
    display: inline-block;
    margin-right: 1rem;
  }

  .product-detail-footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 898px;
    left: 50%;
    padding: 25px 25px 25px 40px;
    right: auto;
    z-index: 1;
    transform: translate(-49.45%, 0px);
    button {
      font-size: 0.75rem;
      .icon-cart {
        font-size: 1rem;
        .icon-plus {
          @media only screen and (max-width: 475px) {
            right: -6px;
          }
        }
      }
    }
    & > .row > div:first-child,
    & > .row > div:nth-child(5) {
      @media only screen and (min-width: 1200px) {
        display: flex;
        align-items: center;
        // justify-content: space-between;
      }
    }
    & > .row > div:first-child .danger {
      @media only screen and (min-width: 1200px) {
        position: absolute;
        left: 13px;
        top: 14px;
      }
    }
    & > .row > div:nth-child(5) {
      @media only screen and (max-width: 376px) {
        text-align: left !important;
      }
    }
    @media only screen and (max-width: 769px) {
      left: 50%;
      width: 94%;
    }
    @media only screen and (max-width: 769px) and (orientation: landscape) {
      transform: translate(0);
      padding: 15px 40px 15px 15px;
      margin: 0 auto;
      left: 0;
      right: 0;
      div {
        & > :nth-child(5) {
          text-align: right !important;
          flex: 0 0 25%;
          max-width: 25%;
          margin: 0.5rem auto 0;
        }
      }
    }
    @media only screen and (max-width: 475px) {
      width: 100%;
      padding: 15px 15px 15px 30px;
      margin-left: 0px;
      left: 0;
      transform: translate(0%, 0px);
      & > .row > div:nth-child(5) {
        padding-right: 0;
      }
      button {
        .icon-cart {
          font-size: 1.5em;
          line-height: 1.7rem;
        }
      }
    }

    .select-css-price {
      position: relative;
      // display: block;
      font-size: 17px;
      // color: #7d7d7d;
      line-height: 1.3;
      padding: 0rem 0rem 0 0.95rem;
      // width: 100%;
      // max-width: 70px;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 0.25em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      // background-color: #d3d3d3;
      background-image: url("../../assets/dropdown-arrow.png");
      background-repeat: no-repeat, repeat;
      /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
      background-position: left 0em top 50%, 0 0;
      /* icon size, then gradient */
      background-size: 0.7em auto, 100%;
      border: 1px solid rgba(0, 0, 0, 0);

      option {
        font-weight: normal;
        color: #000;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  .product-body {
    display: flex;
    flex-wrap: wrap;
    .product-gallery {
      flex: 0 0 40%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;
      .product-img {
        max-width: 250px;
        flex: 0 0 80%;
        margin-right: 1rem;
        .imgwrapper {
          img {
            object-fit: contain;
          }
        }
      }
      ul {
        padding: 0;
        flex: 0 0 20%;
        list-style: none;
        li {
          border: 1px solid #ccc;
          position: relative;
          width: 60px;
          height: 60px;
          background: #fff;
          img {
            width: 90%;
            height: 90%;
            object-fit: contain;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        li + li {
          margin-top: 1rem;
        }
      }
    }
    .product-overview {
      flex: 0 0 60%;
      padding: 0 0rem 0 2rem;
      ul.features {
        list-style: none;
        padding: 0;
        margin-top: 1rem;
        li {
          display: inline-block;
          border: 2px solid #949494;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          vertical-align: middle;
          position: relative;
          font-size: 1rem;
          color: #949494;
        }
        li:first-child {
          font-size: 3.2rem;
          margin-left: 10px;
          border: none;
          color: #000;
        }
        li + li {
          margin-left: 10px;
        }
        li {
          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          img {
            width: 80%;
            height: 80%;
            object-fit: contain;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      ul.eu-label {
        list-style: none;
        padding: 0;
        margin-top: 1rem;
        li {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          font-size: 1rem;
          color: #000;
          width: 31.5%;
        }
        li + li {
          margin-right: 10px;
        }
      }
    }
    .other-details {
      padding-top: 15px;
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media only screen and (max-width: 768px) {
      .product-gallery {
        flex: 0 0 100%;
        flex-wrap: nowrap;
        .product-img {
          flex: 0 0 80%;
          max-width: 370px;
          .imgwrapper {
            margin: auto;
          }
        }
        ul {
          margin-bottom: 1rem;
          flex: 0 0 15%;
        }
      }
      .product-overview {
        flex: 0 0 100%;
        ul.eu-label {
          li {
            width: 21.5%;
            font-size: 13px;
          }
          li + li {
            margin-right: 10px;
          }
        }
      }
    }
    @media only screen and (max-width: 475px) {
      .product-gallery {
        flex: 0 0 100%;
        .product-img {
          flex: 0 0 76%;
          margin-right: 0.5rem;
        }
        ul {
          margin-bottom: 1rem;
          flex: 0 0 20%;
          max-width: 60px;
        }
      }
      .product-overview {
        flex: 0 0 100%;
        padding: 0;
        ul.features {
          li {
            width: 40px;
            height: 40px;
            font-size: 0.7rem;
            .h2 {
              font-size: 1.35rem;
            }
          }
          li:first-child {
            font-size: 2.2rem;
          }
          li + li {
            margin-left: 6px;
          }
        }
        ul.eu-label {
          li {
            width: 31%;
            font-size: 12px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 475px) {
    .modal-close {
      left: 0px;
      top: -30px;
    }
  }

  .product-overview + .other-details {
    .accordion__button {
      text-align: right;
      &::after {
        right: unset;
        left: 0px;
        margin-left: 12px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .product-title {
      font-size: 1rem;
      margin-bottom: 0rem;
    }
    .product-size {
      font-size: 1rem;
      margin-left: 0rem;
      margin-bottom: 1rem;
    }
    .product-type {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .modal-body {
      padding: 1rem;
    }
  }
  @media only screen and (max-width: 376px) {
    margin: 2.5rem 1.2rem 9rem;
    .product-title {
      font-size: 1rem;
      display: block;
    }
  }
}

.menu-box {
  overflow-y: auto;
  width: 294px;
  background: url("../../assets/side-bg.png") no-repeat top;
  background-size: cover;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0px;
  right: -100%;
  color: #fff;
  padding: 2rem 0;
  transition: ease-in 2s linear;
  &.active {
    right: 0;
  }
  .close {
    color: #fff;
    opacity: 1;
    font-size: 1rem;
    line-height: 1.9rem;
    float: none;
  }
  ul {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    li {
      list-style: none;
      padding: 10px;
      position: relative;
      &.has-submenu {
        &::before {
          display: inline-block;
          content: "";
          height: 10px;
          width: 10px;
          border-bottom: 2px solid currentColor;
          border-right: 2px solid currentColor;
          transform: rotate(-135deg);
          position: absolute;
          left: 1rem;
          top: 20px;
        }
        &.on::before {
          transform: rotate(45deg);
          top: 15px;
        }
      }

      ul {
        margin: 10px -10px 0;
        border-top: 2px solid #fff;
        li {
          padding-right: 3.35rem;
        }
        & > li:last-child {
          border-bottom: 1px solid #ffffff85;
        }
      }
    }
    li + li {
      border-top: 1px solid #ffffff85;
    }
  }
  a {
    color: #fff;
  }
  .icon-star,
  .icon-user {
    margin-left: 0.5em;
  }
  .icon-bell {
    margin-left: 0.7em;
  }
}
.shipment_detail-icon {
  font-size: 1rem;
  color: #949494;
  margin-top: 1.2rem;
  padding-top: 0.5rem;
  @media only screen and (max-width: 475px) {
    margin-top: 0;
  }
}
.shipment_detail {
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  font-size: 1rem;
  // font-weight: 700;
  color: #949494;
  margin-top: 0.75rem;
  padding-top: 0.5rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      padding: 0px 10px;
      vertical-align: middle;
    }
    li + li {
      border-left: 1px solid #000;
    }
  }
  @media only screen and (max-width: 500px) {
    border-top: 0;
    margin-top: 0rem;
    padding-top: 0rem;
  }
}

.mrg-2 {
  margin-right: 2px;
}
.total-wrapper {
  flex: 20%;
  max-width: 20%;
}
.delete-icon {
  position: absolute;
  top: 25px;
  left: 10px;
  .btn-link {
    color: #616161;
  }
  @media only screen and (max-width: 1366px) {
    top: 35px;
  }
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    top: 10px;
    left: 10px;
    right: unset;
    .btn-link {
      color: #616161;
    }
  }
  @media only screen and (max-width: 475px) {
    top: 10px;
    left: 10px;
    right: unset;
    .btn-link {
      color: #616161;
    }
  }
}
.sub-total-he {
  @media only screen and (max-width: 475px) {
    text-align: right !important;
    & > span {
      display: block;
    }
  }
}
.promocode_apply {
  .form-control {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.02);
  }
  .btn-secondary {
    background-color: #000;
  }
}

@media only screen and (max-width: 1024px) {
  .product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .w-100 {
    width: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .carousel-item {
    margin-top: 20px;
  }

  .navbar-nav {
    display: block;
    text-align: right;
  }
  .nav-item {
    display: inline-block;
  }
  // .col-sm-6 {
  //     flex: 0 0 100%;
  //     max-width: 100%;
  // }
  .product-details {
    .product-title {
      font-size: 1rem;
      margin-bottom: 0rem;
    }
    .product-size {
      font-size: 1rem;
      margin-left: 0rem;
      margin-bottom: 1rem;
    }
    .product-type {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .modal-body {
      padding: 1rem;
    }
  }
}

.product-details {
  .modal-body > .row:nth-child(1) {
    & > div:nth-child(1),
    & > div:nth-child(2) {
      @media only screen and (max-width: 475px) {
        width: fit-content;
      }
    }
  }
  .modal-body > .row:nth-child(3) {
    & > div:nth-child(1) {
      @media only screen and (max-width: 475px) {
        order: 2;
      }
      & > div.item-detail {
        @media only screen and (max-width: 960px) and (orientation: landscape) {
          display: grid;
          grid-template-columns: 30px 320px auto;
          grid-template-rows: auto auto;
          grid-template-areas: "b1 b2 b3" "b1 b2 b4";
        }
        @media only screen and (max-width: 475px) {
          display: grid;
          grid-template-columns: auto auto auto;
          grid-template-rows: auto auto;
          grid-template-areas: "b1 b2 b3" "b1 b2 b4";
        }
        & > div:nth-child(1) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b1;
          }
          @media only screen and (max-width: 475px) {
            grid-area: b1;
            padding-left: 0;
            padding-right: 0.5rem;
          }
        }
        & > div:nth-child(2) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b2;
            padding-right: 10px !important;
          }
          @media only screen and (max-width: 475px) {
            grid-area: b2;
            padding-right: 10px !important;
          }
        }
        & > div:nth-child(3) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b3;
          }
          @media only screen and (max-width: 475px) {
            grid-area: b3;
          }
        }
        & > div:nth-child(4) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b4;
          }
          @media only screen and (max-width: 475px) {
            grid-area: b4;
          }
        }
      }
      & > div:nth-child(3),
      & > div:nth-child(4),
      & > div:nth-child(5) {
        & > div:nth-child(2) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            text-align: right !important;
          }
          @media only screen and (max-width: 475px) {
            text-align: right !important;
          }
        }
        // & > div:nth-child(3){
        //     @media only screen and (max-width: 475px){
        //         display: none;
        //     }
        // }
        // & > div:nth-child(4){
        //     @media only screen and (max-width: 475px){
        //         width: fit-content;
        //     }
        // }
      }
    }
    & > div:nth-child(2) {
      @media only screen and (max-width: 475px) {
        order: 1;
        display: grid;
        grid-template-columns: 55% 45%;
        grid-template-rows: auto auto;
        grid-template-areas: "b1 b2" "b1 b3";
      }
      & > div:nth-child(1) {
        @media only screen and (max-width: 475px) {
          grid-area: b1;
          padding-right: 10px;
        }
      }
      & > div:nth-child(2) {
        @media only screen and (max-width: 475px) {
          grid-area: b2;
        }
      }
      & > div:nth-child(3) {
        @media only screen and (max-width: 475px) {
          grid-area: b3;
        }
      }
    }
  }
}

.product-page .product-list {
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    .card-body > .row {
      & > :nth-child(6),
      & > :nth-child(5) {
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 740px) and (orientation: landscape) {
    .card-body {
      padding: 1rem 0;
    }
    .card-body > .row {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      & > :nth-child(1) {
        max-width: 60%;
        flex: 0 0 60%;
        margin-bottom: 0.75rem;
      }
      & > :nth-child(3) {
        padding: 0;
        max-width: 40%;
        flex: 0 0 40%;
        margin-bottom: 0.75rem;
      }
      & > :nth-child(6) {
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
      & > :nth-child(8) {
        max-width: 45%;
        padding: 0;
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.custom_alert {
  &.alert-dark {
    .col-xl-8 {
      text-align: right;
    }
  }
  .btn-link {
    color: #fff;
  }
}

.product-page {
  & > .custom_alert:nth-child(1) {
    margin-top: 0rem;
  }
}

.promo_alert {
  &.alert-dark {
    background: #ffa500;
    color: #000;
    margin-bottom: 2.5rem;
  }
  .btn-link {
    color: #fff;
  }
  .promocode {
    color: #fff;
  }
  a {
    color: #fff;
    font-weight: 700;
  }
}
.alert.alert-dark {
  // margin-top: 1.5rem;
  .row > div:last-child > button:first-child {
    margin-left: 0.5em;
  }
}

.changepassword {
  margin: 5rem auto;
  .btn-link {
    color: #000;
  }
  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 2em #f6f6f6 inset;
      box-shadow: 0 0 0px 2em #f6f6f6 inset;
    }
  }

  .form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    padding-right: 0.8rem;
  }

  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    background-color: #f6f6f6;
    width: 100%;
    padding-right: 1em;
    padding-top: 0;
  }
  .form-control {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
    background-color: #f6f6f6;
  }
  .showpassword {
    position: absolute;
    left: 0;
    top: 10px;
    cursor: pointer;
    width: 20px;
    margin-left: 1em;
  }
  .rounded-pill.btn-block {
    width: fit-content;
    padding: 0.5em 1.5em 0.5em 1.5em;
  }
  @media only screen and (min-width: 455px) {
    .modal-content {
      width: 70%;
      .modal-body {
        padding: 3rem 3rem;
        h3 {
          text-align: center;
        }
      }
    }
  }
}
.slick-slider {
  .slick-slide {
    // margin-right: 30px;
    padding: 15px 0;
    box-sizing: border-box;
    .card-body {
      min-height: 176px;
      @media only screen and (max-width: 768px) {
        min-height: 176px;
        padding: 15px;
      }
      @media only screen and (max-width: 416px) {
        min-height: 176px;
        padding: 15px;
      }
    }
    @media only screen and (max-width: 768px) {
      // margin-right: 4px;
    }
    @media only screen and (max-width: 376px) {
      // margin-right: 0px;
    }
  }
  .slick-prev,
  .slick-next {
    width: 40px !important;
    height: 40px !important;
    &::before {
      font-size: 40px !important;
    }
  }
  .slick-prev {
    left: -40px !important;
    right: auto;
  }
  .slick-next {
    right: -45px !important;
    left: auto;
    @media only screen and (max-width: 769px) and (min-width: 568px) {
      right: -10px !important;
    }
  }
  .slick-list {
    @media only screen and (max-width: 415px) {
      height: fit-content !important;
      max-height: fit-content;
    }
  }
}
.cta-btn-carousel {
  padding: 10px 20px 10px;
  .icon-cart {
    position: relative;
    .icon-plus {
      background: #000;
      position: absolute;
      border-radius: 50%;
      right: -6px;
      top: -5px;
      width: 12px;
      height: 12px;
      color: orange;
      font-size: 0.45rem;
      padding: 1px;
      border: 1px solid;
    }
  }
  @media only screen and (max-width: 1366px) {
    padding: 7px 17px 7px;
  }
  @media only screen and (max-width: 740px) {
    margin-top: -8px;
    padding: 7px 32px 7px;
    .icon-cart {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 475px) {
    margin-top: 0.5rem;
    padding: 8px 32px 6px;
    .icon-cart {
      font-size: 1.5rem;
    }
  }
}
// .cta-btn-carousel{
//     padding: 10px 20px 10px;
//     .icon-cart{ position: relative;
//         .icon-plus{
//             background: #000;
//             position: absolute;
//             border-radius: 50%;
//             right: -6px;
//             top: -5px;
//             width: 12px;
//             height: 12px;
//             color: orange;
//             font-size: 0.45rem;
//             padding: 1px;
//             border: 1px solid;
//         }
//     }
//     @media only screen and (max-width: 1366px){
//         padding: 7px 17px 7px;
//     }
//     @media only screen and (max-width: 475px){
//         // margin-top: 10px;
//         padding: 10px 32px 7px;
//         .icon-cart{ font-size: 18px;
//         }
//     }
// }
#featureProductSliderProductPage {
  .slick-slide {
    //  margin-right: 30px;
    padding: 0 0px;
    box-sizing: border-box;
    .card-body {
      min-height: 190px;
    }
    @media only screen and (max-width: 960px) and (orientation: landscape) {
      margin-top: 15px;
      padding: 0 10px 0 0;
      margin-left: -2px;
    }
    @media only screen and (max-width: 810px) {
      padding: 0 10px 0 0;
      margin-left: -2px;
    }
    @media only screen and (max-width: 768px) {
      // margin-right: 5px;
    }
    @media only screen and (max-width: 740px) and (orientation: landscape) {
      direction: rtl;
    }
    @media only screen and (max-width: 475px) {
      margin-top: 15px;
      padding: 0 0px 0 10px;
      margin-right: -1px;
      direction: rtl;
    }
  }
  + .row .card-body > .row {
    @media only screen and (max-width: 475px) {
      & > div:nth-child(3) {
        align-self: start;
      }
      & > div:nth-child(7) {
        white-space: nowrap;
        padding-right: 0px;
        margin-right: -1rem;
        margin-top: -1.5rem;
      }
      & > div:last-child {
        text-align: right !important;
        padding-right: 0;
        button {
          padding-top: 0.7em;
          padding-bottom: 0.6em;
          margin-right: 1em;
          .icon-cart {
            font-size: 1.5em;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 475px) {
    margin-bottom: 15px;
  }
}
// .slick-list{overflow-x: hidden; overflow-y: visible;}
.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
  &::before {
    font-size: 40px !important;
  }
}
.slick-prev {
  left: -55px !important;
  z-index: 1;
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    left: -21px !important;
  }
  @media only screen and (max-width: 415px) {
    left: -21px !important;
  }
}
.slick-next {
  right: -55px !important;
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    right: -21px !important;
  }
  @media only screen and (max-width: 415px) {
    right: -21px !important;
  }
}
// .feature-product{
//     .slick-slide{
//         margin-right:10px;
//     }
// }
.slick-prev:before,
.slick-next:before {
  @media only screen and (max-width: 415px) {
    color: #cacaca;
  }
}
.slick-next:focus,
.slick-prev:focus {
  outline: unset;
}
.recentView-1 {
  .counter-align {
    right: unset;
    top: unset;
    left: 0px;
    bottom: 7%;
  }
  .select-css-price {
    position: relative;
    // display: block;
    font-size: 17px;
    // color: #7d7d7d;
    line-height: 1.3;
    padding: 0rem 0.25rem 0 1rem;
    width: 100%;
    max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    // background-color: #d3d3d3;
    background-image: url("../../assets/dropdown-arrow.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: left 0em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 0);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}
.recent-view-counter {
  position: absolute;
  left: 15px;
  bottom: 10px;
  @media only screen and (max-width: 1024px) {
    max-width: 40%;
  }
  @media only screen and (max-width: 415px) {
    max-width: 45%;
  }
}
.recent_view_cta {
  position: absolute;
  right: 15px;
  bottom: 10px;
  // @media only screen and (max-width: 415px){
  //     position: relative;
  // }
}
.feature-products {
  @media only screen and (max-width: 1024px) {
    .card-body {
      min-height: 230px;
      .counter-align {
        top: 100%;
      }
      .featur_btn {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
  }
  .select-css-price {
    position: relative;
    // display: block;
    font-size: 17px;
    // color: #7d7d7d;
    line-height: 1.3;
    padding: 0rem 0 0 0.95rem;
    // width: 100%;
    // max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    // background-color: #d3d3d3;
    background-image: url("../../assets/dropdown-arrow.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: left 0em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 0);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

// add ditional
.main-logo,
.navbar-brand {
  img {
    max-height: 38px;
  }
}
.navbar-nav {
  .login-link.active {
    font-weight: bold;
  }
  li.nav-item.dropdown {
    > .dropdown-menu {
      .dropdown-item {
        h5 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
    }
  }
}
.navbar {
  .search-btn {
    @media (max-width: 768px) {
      top: 0px;
      left: unset;
      right: unset;
    }
    @media (max-width: 415px) {
      left: unset;
    }
  }

  .navbar-nav {
    .nav-item {
      @media (max-width: 768px) {
        &:not(:last-child) {
          margin-left: 0.758rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  // .bg-landing-page{
  //     padding-top:1.375rem;
  //     .navbar-nav .nav-link{
  //         color:#000;
  //     }
  //     >div:nth-child(1){
  //         flex-basis: 18%;
  //         @media(min-width: 1800px){
  //             flex-basis: 10%;
  //         }
  //     }
  //     >div:nth-child(2){
  //         display: inline-block;
  //         flex-basis: 100px;
  //         .lang.select-css-logout{

  //             border:unset;
  //             background-color: transparent;
  //             color:#000;
  //         }
  //     }
  //     >div:nth-child(3){
  //         margin-right: auto !important;
  //         margin-left:unset!important;

  //     }

  // }
  .bg-landing-page {
    padding: 0.675rem 1rem;
    display: flex;
  }
  .navWapper {
    display: flex;
    width: 100%;
    align-items: center;
    // max-width: 1400px;
    margin: auto;
    .navbar-nav .nav-link {
      color: #000;
    }
    > div:nth-child(2) {
      .lang.select-css-logout {
        border: unset;
        background-color: transparent;
        color: #000;
      }
    }
    // .navbar-brand{margin-left: 280px;}
  }
  .home-title {
    max-width: 469px;
    font-size: 1.75rem;
  }
  .navbar-light {
    backface-visibility: hidden;
    .navbar-nav .nav-link {
      font-weight: bold;
      &:hover {
        // font-weight: bold;
        color: rgba(0, 0, 0, 1);
        .nav-icon,
        .nav-txt {
          color: #000;
        }
      }
      .nav-txt {
        color: #9c9d9d;
      }

      .nav-icon {
        color: #9c9d9d;
      }
      .nav.text:hover ~ .nav-icon {
        color: #000;
      }
    }
    .navbar-nav .nav-link,
    .nav-txt {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      &::after {
        content: attr(data-text);
        content: attr(data-text) / "";
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: bold;
      }
      @media speech {
        display: none;
      }
    }
    .form-control:focus {
      border-color: #9c9d9d;
    }
  }

  .form-control {
    &:focus {
      box-shadow: unset;
      outline: unset;
    }
  }
  input[type="search"].form-control {
    @media screen and (max-width: 769px) and (min-width: 576px) {
      padding-right: 2.5rem;
    }
  }
  // footer cart page
  .footer-carousel__cart {
    .card-body {
      display: flex;
      flex-direction: column;
      > div.row:nth-child(2) {
        > div:nth-child(3) {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }
      }
      // >div.row:nth-child(3){
      //     // margin-top:auto;
      //     // padding-left: 10px;
      // }
    }

    button {
      margin-top: 0.5rem;
      padding: 7px 15px 7px;
      font-size: 0.8rem;
      .icon-cart {
        margin-right: 1em;
        // font-size: 1.3rem;
      }
      // .d-md-inline-block.d-xl-inline-block{
      //     // display: none!important;
      // }
      // .icon-cart .icon-plus{
      //     // right:2.1875rem;

      // }
    }
  }
  .slick-slider.footer-carousel__cart .slick-slide .card-body {
    min-height: 163px;
  }
}
// .slick-slider.slick-initialized .slick-list .slick-track{
//     .card-body{
//         >div.row:nth-child(3){
//             >div:last-child{
//                 button{
//                     @media(max-width: 768px){
//                         font-size: 12px;
//                         // >span:first-child{
//                         //     // font-size: 1.3rem;
//                         // }
//                         >span:last-child{
//                             display: none;
//                         }
//                     }
//                 }
//             }

//         }

//     }

// }

.footer-carousel__cart {
  .slick-slide {
    padding: 20px 15px;
    .recentView-1 {
      margin-bottom: 0;
      .card-body {
        padding: 15px 15px 20px;
      }
    }
  }
}

// .navbar.bg-landing-page{
//     >div:nth-child(2){
//         @media(max-width:375px){
//         margin-right: -3rem;
//         }
//     }
//     >div:nth-child(3){
//         @media(max-width:375px){
//         margin-left: 0!important;
//         }
//     }
//     .navbar-brand{
//         @media(max-width:375px){
//             margin-right: 0;
//         }
//     }
//     .lang.select-css-logout{
//         @media(max-width: 768px){
//             font-size: 12px;
//         }

//     }

// }
.bg-landing-page {
  padding: 0.675rem 1rem;
  display: flex;
}
.navWapper {
  display: flex;
  width: 100%;
  align-items: center;
  // max-width: 1400px;
  margin: auto;
  .navbar-nav .nav-link {
    color: #000;
  }
  > div:nth-child(2) {
    .lang.select-css-logout {
      border: unset;
      background-color: transparent;
      color: #000;
    }
  }
  // .navbar-brand{margin-left: 280px;}
}
@media (max-width: 768px) {
  .navbar {
    ul.navbar-nav {
      display: flex;
      flex-direction: row;
    }

    .mobileSearch {
      button {
        top: 0.6875rem;
        left: 0.6875rem;
        right: unset;
      }
    }
  }
}

.product-page .feature-product {
  .mobile-view {
    @media (max-width: 768px) {
      bottom: -40px;
      left: 30%;
    }
    @media (max-width: 960px) and (orientation: landscape) {
      bottom: -40px;
      left: auto;
      right: -25%;
    }
  }
}
// checkbox he
.checkbox {
  svg {
    right: 0;
  }
}

.filter-on.container {
  .toggle__input {
    left: 0;
    opacity: 0;
  }
}
.modal-open {
  .modal {
    overflow-y: scroll !important;
  }
}
.footer-fixed__mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: #fff;
  padding: 10px;

  > div.row {
    > div:first-child {
      @media (max-width: 375px) {
        font-size: 12px;
      }
      padding-right: 12px !important;
    }
  }
}
// order page
.nav-tabs {
  border-bottom: 0px;
  .nav-link {
    &.active {
      border-color: transparent;
    }
  }
  @media only screen and (max-width: 475px) {
    margin-top: 1.9rem;
  }
}

// product-page
.product-page {
  .feature-products,
  .feature-products + div {
    button {
      // min-width: 12rem;
      // .icon-cart .icon-plus{
      //     // right:2.1875rem;

      // }
    }
  }
  .feature-products + div {
    button {
      margin-left: 1rem;
    }
  }
  .feature-product .card-body {
    > div:nth-child(2) {
      > div {
        // @media(max-width:768px){
        //     margin-left:20%!important;
        // }
        @media (max-width: 768px) {
          margin-left: 0px !important;
        }
        &:first-child {
          order: 2;
        }
      }
    }
  }
  .mob-card.card {
    @media (max-width: 768px) {
      .card-body {
        button {
          font-size: 13px;
          .icon-cart .icon-plus {
            right: -0.4rem;
          }
        }
      }
    }
  }
  .slick-slide.slick-active {
    .card {
      @media only screen and (max-width: 768px) and (min-width: 500px) {
        margin-top: 1rem;
        width: 97% !important;
      }
    }
  }
}

//  order pop up shiping page
.shiping-address {
  width: 100%;
  margin-left: unset;
  margin-right: unset;

  > div {
    display: flex !important;
    align-items: baseline;
    width: 100%;
    @media (max-width: 769px) {
      order: 1 !important;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    > label {
      flex-basis: 100px;
      @media (min-width: 400px) {
        flex-basis: 120px;
      }
    }
    > input {
      flex: 1;
    }
    &:nth-child(2) {
      > label {
        @media (min-width: 769px) {
          flex-basis: 120px;
        }
      }
    }
  }
}
.homeslider {
  .slick-slide {
    padding: 10px;
    // margin-right: 30px;
    .card-body {
      min-height: auto;
    }
  }
}

// reservedstock page
.resered-stock-list {
  button {
    font-size: 12px;
    margin-left: 1rem;
  }
  .icon-cart {
    padding: 0em 1em 0em 0.5em;
    &::before {
      font-size: 2em;
    }
    .icon-plus {
      right: 0.5rem;
      top: -2px;
    }
  }
}

// notification-setting

.notification-setting {
  .card-body {
    > div {
      > div {
        label {
          width: 12rem;
        }
      }
    }
  }
}

.notification_wrapper {
  .bdg-small {
    @media screen and (min-width: 540px) {
      position: absolute;
      right: 0;
    }
  }
}

// cart page
.filter-cart-page {
  @media (max-width: 768px) {
    align-items: flex-end;
    margin-top: -1rem;
  }
  .cart-search-input-mobile {
    margin-top: 0.5rem;
    display: inline-block;
    width: 100%;
    @media (min-width: 769px) {
      display: none;
    }
  }
}
.cart-search-input-desktop {
  display: none;
  @media (min-width: 769px) {
    display: inline-block;
  }
}

.userCart {
  .card-body {
    padding: 20px;
  }
  .z-index-1 {
    @media only screen and (max-width: 960px) {
      z-index: unset;
    }
  }
}

.dates_wrapper {
  vertical-align: middle;
  text-align: left;
  line-height: 1.5rem;
  .dates {
    display: inline-block;
    vertical-align: middle;
  }
  .dropmenu_icon {
    display: inline-block;
    margin-left: 2rem;
    vertical-align: middle;
    .icon-more-v {
      display: block;
      margin-top: 0.15rem;
    }
    @media only screen and (max-width: 475px) {
      margin-left: 0.5rem;
    }
  }
  @media only screen and (max-width: 475px) {
    text-align: left;
  }
}
.complaint_wrapper {
  line-height: 1.5rem;
  .complaint_id {
    display: inline-block;
    vertical-align: middle;
    @media only screen and (max-width: 475px) {
      display: block;
    }
  }
  .complaint_subject {
    display: inline-block;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    margin-right: 2rem;

    @media only screen and (max-width: 475px) {
      margin-left: 0rem;
      max-width: 150px;
      display: block;
    }
  }
}
.Toastify__close-button {
  position: absolute;
  @media only screen and (max-width: 415px) {
    margin-left: 1rem;
  }
}

.resered-stock-list {
  & > .card-body > .row > div:nth-child(3) {
    span {
      @media only screen and (min-width: 1195px) {
        margin-right: -4rem;
        white-space: nowrap;
      }
    }
  }

  @media only screen and (min-width: 1140px) {
    & .quntity {
      margin-right: -2rem;
    }
  }
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    & > .card-body > .row {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto;
      grid-template-areas: "b1 b3" "b2 b5" "b4 b6";
      & > div:nth-child(1) {
        grid-area: b1;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(2) {
        grid-area: b2;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(3) {
        grid-area: b3;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(4) {
        grid-area: b4;
        flex: unset;
        max-width: unset;
        margin-top: 1rem;
        width: 6rem;
      }
      & > div:nth-child(5) {
        grid-area: b5;
        flex: unset;
        max-width: unset;
        text-align: right !important;
      }
      & > div:nth-child(6) {
        grid-area: b6;
        flex: unset;
        max-width: unset;
        align-self: end;
        button {
          font-size: 10px;
        }
        .cta-btn {
          margin-left: 0;
          padding: 7px 5px 7px;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    & > .card-body > .row {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: "b1 b1 b3 b3" "b4 b4 b6 b6" "b2 b2 b7 b7" "b5 b5 b8 b8";
      & > div:nth-child(1) {
        grid-area: b1;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(2) {
        grid-area: b2;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(3) {
        grid-area: b3;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(4) {
        grid-area: b4;
        flex: unset;
        max-width: unset;
        text-align: right !important;
      }
      & > div:nth-child(5) {
        grid-area: b5;
        flex: unset;
        max-width: unset;
        text-align: right !important;
      }
      & > div:nth-child(6) {
        grid-area: b6;
        flex: unset;
        max-width: unset;
        margin-left: -15px;
        text-align: left !important;
      }
      & > div:nth-child(7) {
        grid-area: b7;
        flex: unset;
        max-width: unset;
      }
      & > div:nth-child(8) {
        grid-area: b8;
        flex: unset;
        max-width: unset;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    & > .card-body > .row {
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: "b1 b1 b3 b3" "b4 b4 b6 b6" "b2 b2 b7 b7" "b5 b5 b8 b8";
    }
  }
}

.complaint-card-mb {
  padding: 1.1rem 1rem !important;
  .complaint-card-grid {
    @media only screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 0.5fr 0.5fr;
      grid-template-rows: auto;
      grid-template-areas: "b1 b2 b3";
      .b1 {
        grid-area: b1;
        .complaint_subject {
          max-width: 17rem;
          @media only screen and (max-width: 830px) {
            max-width: 15rem;
          }
        }
      }
      .b2 {
        grid-area: b2;
      }
      .b3 {
        grid-area: b3;
      }
      .b4 {
        grid-area: b4;
        color: gray;
        white-space: nowrap;
      }
      .b5 {
        grid-area: b5;
        .icon-more-v::before {
          display: block;
          transform: rotateZ(90deg);
        }
      }
    }
    @media only screen and (max-width: 830px) {
      grid-template-columns: 1fr 0.5fr 0.6fr;
    }
    @media only screen and (max-width: 960px) and (orientation: landscape) {
      .b1 .complaint_wrapper {
        max-width: 70%;
      }
      .b2 {
        padding: 0;
      }
    }
    @media only screen and (max-width: 475px) {
      grid-template-columns: 55% auto;
      grid-template-rows: auto auto;
      grid-template-areas: "b1 b3" "b1 b2";
    }
  }
}

.complaintHe {
  @media only screen and (max-width: 768px) {
    margin-top: -3.5rem !important;
    button {
      margin-bottom: 1.7rem;
      margin-right: 0 !important;
      white-space: nowrap;
    }
  }
}

.searchResult {
  .card-body > .row > div:nth-child(5) {
    @media only screen and (max-width: 768px) {
      margin-left: -2.7em;
    }
  }
  .card-body > .row > .dropmenu-icon {
    @media only screen and (max-width: 768px) {
      display: unset !important;
      position: absolute;
      left: 5px;
      span {
        top: -8px;
        transform: rotateZ(90deg);
        position: absolute;
        left: 5px;
        color: #00000073;
      }
    }
  }
  @media only screen and (max-width: 475px) {
    margin-top: 4rem;
  }
}
.cart-header {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr 1fr 1fr auto;
  grid-template-areas: "g1 g2 . . ." "g3 g3 g3 g4 g5 ";
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #6c757d;
  margin-bottom: 1rem;
  padding-top: 20px;
  h3 {
    width: max-content;
  }
  .order-search {
    position: relative;
    width: 12rem;
    top: unset;
    right: unset;
    .btn-link {
      left: -1rem;
    }
    input {
      margin-right: 1rem;
      margin-left: 0 !important;
    }
  }
  .ch-1 {
    grid-area: g1;
    align-self: center;
  }
  .ch-2 {
    grid-area: g2;
  }
  .ch-3 {
    grid-area: g3;
    align-self: center;
    margin: 0.5rem 0;
  }
  .ch-4 {
    grid-area: g4;
    justify-self: end;
    align-self: end;
  }
  .ch-5 {
    grid-area: g5;
    justify-self: end;
    align-self: center;
  }
  @media only screen and (max-width: 700px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4" "g3 g4" "g2 g5";
    .ch-2 {
      width: inherit;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      max-width: 20rem;
      input {
        margin-right: 0;
      }
    }
    .ch-4 {
      justify-self: end;
      align-self: start;
      width: 100%;
      button {
        width: 100%;
        margin-right: 0 !important;
      }
    }
    .ch-5 {
      align-self: end;
    }
  }
}

.cart-header-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4" "g3 g4" "g2 g5";
    .ch-2 {
      margin-right: -1rem;
    }
    .ch-4 {
      margin-left: -1rem;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    & > .order-search {
      right: 1rem !important;
      max-width: 190px;
    }
    & > .ch-4 {
      margin-left: unset;
    }
  }
}

.complaint-card-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    & > div:nth-child(2) {
      margin-right: -2rem;
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    grid-template-columns: 55% auto !important;
    grid-template-rows: auto auto !important;
    grid-template-areas: "b1 b3" "b1 b2" !important;
    .complaint_id {
      width: 100%;
    }
    .complaint_subject {
      margin: unset;
    }
    .b2 {
      margin-right: 1rem !important;
    }
  }
}

.order-list {
  & > div:nth-child(6) {
    text-align: right !important;
  }
}

.header-order {
  grid-template-rows: auto auto auto;
  grid-template-areas: "g1 g2 . . ." "g3 g3 g3 . . " "g6 g6 g6 g4 g5";
  .nav.nav-tabs {
    grid-area: g6;
    font-size: 1.2rem;
    margin: 0 0 -0.5rem;
    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      cursor: pointer;
    }
    .nav-link.active {
      background-color: transparent;
      font-weight: bold;
      color: #000;
      border-bottom: 2px solid #000;
      cursor: pointer;
    }
  }
  h4 {
    margin-bottom: 0;
    margin-top: 1rem;
  }
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    grid-template-areas: "g1 g2 . . ." "g3 g3 g3 g4 g5" "g6 g6 g6 g6 g6";
  }
  @media only screen and (max-width: 700px) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4" "g3 g4" "g2 g5" "g6 g6";
    .nav-tabs {
      margin-top: 0;
    }
  }
}

.order-accordion {
  .accordion__panel {
    padding: 0;
  }
  .dropmenu-icon {
    display: unset !important;
    position: absolute;
    left: 5px;
    span {
      top: 5px;
      transform: rotateZ(90deg);
      position: absolute;
      left: 5px;
      color: #00000073;
    }
  }
  .card-body > .row > div:nth-child(5) {
    padding-left: 0 !important;
  }
}

.header-order-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4" "g3 g4" "g2 g5" "g6 g6";
    .nav-tabs {
      margin-top: 0;
    }
    .ch-2 {
      margin-right: -1rem;
    }
    .ch-4 {
      margin-left: -1rem;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    & > .order-search {
      right: 1rem !important;
      max-width: 190px;
    }
    & > .ch-4 {
      margin-left: unset;
    }
  }
}

.order-card-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    & > div:nth-child(2) {
      white-space: nowrap;
    }
    & > div:nth-child(4) {
      white-space: nowrap;
    }
    & > div:nth-child(5) {
      white-space: nowrap;
    }
    & > div:nth-child(6) {
      white-space: nowrap;
    }
  }
}

.slick-track {
  @media only screen and (max-width: 810px) {
    margin-top: 1.2rem;
  }
}
.feature-product-grid {
  flex-direction: row-reverse;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: "a2 a3 a3 a3 a3" "a2 a4 a5 a7 a9" "a2 a4 a6 a8 a12" "a2 a10 a10 a11 a11";
  .ga-1 {
    grid-area: a1;
  }
  .ga-2 {
    grid-area: a2;
    .product-pic {
      width: 9rem;
    }
  }
  .ga-3 {
    grid-area: a3;
  }
  .ga-4 {
    grid-area: a4;
    align-self: center;
    justify-self: start;
    .approve-icon {
      text-align: left;
    }
  }
  .ga-5 {
    grid-area: a5;
  }
  .ga-6 {
    grid-area: a6;
  }
  .ga-7 {
    grid-area: a7;
  }
  .ga-8 {
    grid-area: a8;
    margin-left: 5px;
    // .c-dirham{
    //     margin-left: 5px;
    // }
  }
  .ga-9 {
    grid-area: a9;
  }
  .ga-10 {
    grid-area: a10;
    padding: 10px 0 0 0;
  }
  .ga-11 {
    grid-area: a11;
    align-self: end;
    justify-self: left;
    margin-left: 1rem;
    padding: 10px 0 0 0;
    .icon-cart {
      margin-right: 8px;
    }
  }
  .ga-12 {
    grid-area: a12;
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: auto auto auto auto 0.6fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "a2 a3 a3 a3 a3" "a2 a4 a5 a7 a9" "a2 a4 a6 a8 a12" "a10 a10 . a11 a11";
    min-height: fit-content !important;
    .ga-2 {
      .product-pic {
        width: 7rem;
      }
    }
    .ga-4 {
      align-self: start;
      margin-top: 5px;
      img {
        width: 3rem;
      }
    }
    .ga-5,
    .ga-7,
    .ga-9 {
      align-self: end;
    }
    .ga-10 {
      height: fit-content;
      align-self: end;
    }
    .ga-8 {
      & > span:first-child {
        display: block;
      }
    }
    .ga-11 {
      button {
        min-width: auto;
      }
    }
  }

  @media only screen and (max-width: 810px) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas: "a2 a3 a3 a3 a3" "a2 a4 a5 a5 a5" "a2 a4 a6 a6 a6" "a2 a7 a8 a9 a9" "a2 a7 a8 a12 a12" "a10 a10 a11 a11 a11";
    padding: 20px 2px 20px 2px !important;
    .ga-4 {
      img {
        width: 2rem;
      }
    }
    .ga-7,
    .ga-9 {
      align-self: start;
    }
    .ga-7 {
      display: flex !important;
      flex-direction: column;
      & > span:last-child {
        margin: 0.3rem 0 0 0.4rem !important;
      }
    }
    .ga-12 {
      align-self: end;
      margin-top: 4px;
    }
    .ga-10 {
      .quntity {
        margin-top: 0;
        margin-right: 1rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas: "a2 a3 a3 a3 a3" "a2 a4 a5 a5 a5" "a2 a4 a6 a6 a6" "a2 a7 a8 a9 a9" "a2 a7 a8 a12 a12" "a10 a10 a11 a11 a11";
    padding: 20px 20px 20px 0px !important;
    .ga-4 {
      img {
        width: 2rem;
      }
    }
    .ga-7,
    .ga-9 {
      align-self: start;
    }
    .ga-7 {
      display: flex !important;
      flex-direction: column;
      text-align: center;
      & > span:last-child {
        margin: 0 auto !important;
      }
    }
    .ga-12 {
      align-self: end;
      margin-top: 4px;
    }
    .ga-10 {
      .quntity {
        margin-top: 0;
        margin-right: 1rem;
      }
    }
  }
  @media only screen and (max-width: 740px) and (orientation: landscape) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas:
      "a2 a3 a3 a3 a3" "a2 a4 a5 a5 a5" "a2 a4 a6 a6 a6"
      "a2 a7 a8 a9 a9" "a2 a7 a8 a12 a12" "a10 a10 a11 a11 a11";
    padding: 20px 20px 20px 0px !important;
    .ga-4 {
      align-self: center;
      margin-top: 5px;
      justify-self: center;
    }
    .ga-11 {
      justify-self: left;
      .cta-btn {
        padding: 10px 25px 10px;
      }
    }
  }
}
.header-order + .tab-content {
  .card-body > .row {
    @media only screen and (max-width: 960px) and (orientation: landscape) {
      & > div:nth-child(3) {
        text-align: start !important;
      }
      & > div:nth-child(4) {
        padding-right: 0;
        white-space: nowrap;
      }
      & > div:nth-child(5) {
        text-align: start !important;
        padding-right: 0.25rem !important;
        white-space: nowrap;
      }
      & > div:nth-child(6) {
        text-align: right !important;
      }
    }
  }
}

.buttonList {
  margin-top: 1rem;
  margin-right: 1rem;
  li {
    display: inline-block;
    list-style: none;
    button {
      padding: 5px 25px;
    }
    .btn-radio {
      span {
        color: #333;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  li + li {
    margin-right: 20px;
  }
}
.navbar {
  .dropdownLayer {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 110px;
    right: 0;
    bottom: 0;
    z-index: 1024;
  }
}
.pagination {
  align-self: flex-end;
}

.tire-tips-safety,
.tire-Maintenance,
.sale-made-in-germany,
.noOneInOE-inner-page,
.test-winner-inner-page {
  p > img[style="float:left"],
  div[style="float:left"] {
    margin-right: 1rem;
    width: 90%;
    margin-bottom: 1rem;
    @media only screen and (max-width: 960px) {
      margin-left: 5%;
      margin-right: 5%;
    }
    @media only screen and (min-width: 960px) {
      width: 40%;
    }
  }
  p > img[style="float:right"],
  div[style="float:right"] {
    margin-left: 1rem;
    width: 90%;
    margin-bottom: 1rem;
    @media only screen and (max-width: 960px) {
      margin-left: 5%;
      margin-right: 5%;
    }
    @media only screen and (min-width: 960px) {
      width: 40%;
    }
  }
}

.test-winner-inner-page + video {
  @media only screen and (max-width: 960px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  @media only screen and (min-width: 960px) {
    width: 50%;
    float: left;
  }
}

.sale-made-in-germany-list {
  ul {
    list-style-type: disc;
    padding-right: 1rem;
  }
  @media only screen and (min-width: 960px) {
    & > div:nth-child(1) {
      flex: 0 0 38.666667%;
    }
    & > div:nth-child(2) {
      flex: 0 0 28%;
      max-width: 28%;
    }
  }
}

.tire-technology-tabel {
  margin-top: 1rem;
  div {
    padding-bottom: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: #ffa500;
    border-radius: 50%;
  }
  & tr > td:nth-child(2) {
    text-align: right;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
}

.history-accordion {
  .accordion__button {
    text-align: right;
  }
  .accordion__button:after {
    left: 20px;
    right: auto;
    top: 22px;
  }
  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    top: 18px;
  }
}
.brandNav {
  ul {
    li + li {
      border-right: 1px solid #000;
      border-left: 0px solid #000;
    }
  }
}
.leftImg_rnd {
  width: 35%;
  margin-left: 5%;
  margin-bottom: 10px;
  float: right;
  @media only screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}
.tireContent {
  .imgNo {
    .IconNumber {
      margin-left: 10px;
    }
  }
}

.navWapperWithBrand {
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  .navbar-nav .nav-link {
    color: #000;
  }
  > div:nth-child(2) {
    .lang.select-css-logout {
      border: unset;
      background-color: transparent;
      color: #000;
    }
  }
  .navbar-brand {
    margin-right: 280px;
  }
  .logo-conti {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: -11px;
    right: 0;
    min-width: 260px;
    height: 156px;
    background-color: #ffa500;
    -webkit-transition: 0.3s ease height;
    transition: 0.3s ease height;
    img {
      -webkit-transition: 0.3s ease width;
      transition: 0.3s ease width;
      margin-bottom: 1rem;
    }
  }
  @media screen and (max-width: 1170px) {
    display: flex;
  }
}
.scrolledHeader {
  .logo-conti {
    height: 70px;
    img {
      transform: scale(0.8);
      margin-bottom: 1rem;
    }
  }
}
.navWapper {
  display: flex;
  width: 100%;
  align-items: center;
  // max-width: 1400px;
  margin: auto;
  .navbar-nav .nav-link {
    color: #000;
  }
  > div:nth-child(2) {
    .lang.select-css-logout {
      border: unset;
      background-color: transparent;
      color: #000;
    }
  }
  @media screen and (max-width: 1170px) {
    display: flex;
  }
}
.more_btn {
  span.icon-next {
    display: inline-block;
    transform: rotate(180deg);
  }
}

.promoValidate {
  background: #000;
  padding: 10px 15px;
  h4 {
    color: #fff;
    font-weight: 700;
  }
  p {
    color: #fff;
  }
}
.carousel {
  &.slide {
    margin-top: -20px;
  }
}
.page-link {
  color: #000000 !important;
}
.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.suggestions {
  z-index: 100;
  position: absolute;
  left: 5%;
  border: 1px solid #ccc;
  background: #fff;
  width: 87%;
  li {
    padding: 5px 10px;
  }
  li + li {
    border-top: 1px solid #ccc;
  }
}
