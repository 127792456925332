.main-header {
  .filter-icon-btn {
    margin-right: 10px;

    .icon-open-menu {
      color: #fff;
    }
  }

  @media (min-width: 1200px) {
    gap: 16px;
  }

  &.navbar.bg-darker {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.bg-darker {
    background-color: #141005;
    border-bottom: 1px solid #f1ede3;
  }

  .logo-wrapper {
    max-width: fit-content;
  }

  .user-nav {
    justify-content: space-between;

    .navbar-nav {
      align-items: center;
    }

    .nav-item {
      flex-direction: row;

      .nav-link {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        .nav-icon {
          color: #8c887f;
          line-height: 1;
          width: 20px;
          height: 20px;
          font-size: 20px;
          margin-right: 8px;
        }

        .nav-txt {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #8c887f;
        }

        &:hover,
        &.active {
          .nav-icon {
            color: #fff;
          }

          .nav-txt {
            color: #fff;
          }
        }
      }
    }
  }

  .navigate-nav.navbar-nav {
    .nav-item {
      .nav-link {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }

  .site-setting.navbar-nav {
    cursor: pointer;
    align-items: center;
    padding-left: 16px;

    .nav-item + .nav-item {
      margin-left: 16px;
    }

    .nav-link {
      background-color: #333029;
      border: 1px solid #514e46;
      border-radius: 200px;
      width: 38px;
      height: 38px;
      position: relative;
      display: block;

      .qtylable {
        position: absolute;
        top: -15%;
        right: -20%;
        width: 17px;
        height: 17px;
        padding-top: 1px;
        border-radius: 50%;
        background-color: #fab005;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .user-account-dropdown {
      .img-profile {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .username-detail {
        display: inline-flex;
        flex-direction: column;

        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        .user-name {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #8c887f;
        }
      }
    }

    .noti-dropdown {
      border-radius: 16px;
    }
  }

  .top-search-box {
    position: relative;
    min-width: 250px;
    width: 100%;

    @include media(md) {
      max-width: 300px;
    }

    @include media(lg) {
      max-width: 380px;
    }

    @include media(2xl) {
      max-width: 532px;
    }

    #notebooks {
      input.form-control {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        height: unset;
        padding: 16px;
        padding-left: 16px;
        background-color: #333029;
        border: 1px solid #514e46;
        color: #fff;

        &::placeholder {
          color: #8c887f;
        }
      }
    }

    .search-btn {
      background-color: transparent;
      right: unset;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;

      &:focus,
      &:hover {
        background-color: transparent;
        border: unset;
        color: #fff;
      }
    }
  }

  .select-langs {
    &:hover {
      .ant-select-selector {
        border-color: #514e46 !important;
      }
    }

    .ant-select-selector {
      background-color: #333029;
      border-radius: 48px;
      border: 1px solid #514e46;
      color: #fff;
      padding: 6px;
      padding-right: 20px;
      height: fit-content;

      .ant-select-selection-item {
        line-height: 29px;
        font-size: 16px;

        span[role="img"] {
          margin-right: 8px;
        }

        color: #fff;
      }
    }
  }
}

html[dir="rtl"] {
  .main-header {
    .user-nav {
      .nav-item {
        margin-left: 16px;

        .nav-link {
          .nav-icon {
            margin-left: 8px;
            margin-right: unset;
          }
        }
      }
    }

    .top-search-box {
      #notebooks input.form-control {
        padding-right: 40px;
        padding-left: 16px;
      }

      .search-btn {
        left: unset;
        right: 0;
      }
    }

    .select-langs {
      .ant-select-selector {
        padding-right: 6px;
        padding-left: 20px;

        .ant-select-selection-item span[role="img"] {
          margin-left: 8px;
          margin-right: unset;
        }
      }
    }

    .site-setting {
      padding-right: 16px;
      padding-left: unset;
    }
  }
}

.select-lang-popup {
  width: fit-content !important;
  padding: 0;

  .ant-select-item {
    .ant-select-item-option-content {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      color: #141005;
      text-transform: capitalize;
    }

    &.ant-select-item-option-selected {
      background-color: rgba(250, 176, 5, 0.5);
    }
  }
}

.user-account-dropdown-overlay {
  .ant-dropdown-menu {
    margin-top: 16px;
    padding: unset;

    li.ant-dropdown-menu-item {
      & > svg {
        flex: 0 0 24px;
      }

      .ant-dropdown-menu-title-content {
        flex: 1;
      }

      .nav-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #141005;
        text-transform: capitalize;
      }
    }

    li.ant-dropdown-menu-item:has(.nav-link.active) {
      background-color: rgba(250, 176, 5, 0.5);
    }
  }
}

// mibile nav
.nav.mobile-display {
  margin-left: auto;
  gap: 16px;
  align-items: center;

  @media (max-width: 420px) {
    gap: 10px;
  }

  .nav-item + .nav-item {
    @media (max-width: 420px) {
      margin-left: 5px;
    }
  }

  .top-search-box {
    @media (max-width: 1199px) {
      min-width: 350px;
      max-width: 532px;
    }

    @media (max-width: 767px) {
      display: none;
      visibility: hidden;
      position: relative;
      z-index: -2;
    }
  }

  .hamberger {
    color: #f1ede3;
    cursor: pointer;
  }

  .nav-link {
    cursor: pointer;
    border-radius: 200px;
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    padding: unset;
    color: #fff;

    .nav-icon {
      font-size: unset;
    }

    .qtylable {
      position: absolute;
      top: -15%;
      right: -20%;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: #fab005;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-tires-mobile {
      .tires-tire {
        font-size: 22px;
      }
    }
  }
}

html[dir="rtl"] {
  .nav.mobile-display {
    margin-left: unset;
    margin-right: auto;
  }
}

.mobile-display {
  @media screen and (min-width: 1200px) {
    display: none !important;
    visibility: hidden;
    position: relative;
    z-index: -2;
  }
}

.desktop-display {
  @media screen and (max-width: 1199px) {
    display: none !important;
    visibility: hidden;
    position: relative;
    z-index: -2;
  }
}

// no login
.bg-landing-page {
  .navWapperWithBrand,
  .navWapper {
    justify-content: space-between;

    .main-logo {
      max-width: 150px;
    }

    .header-logout {
      .nav-item {
        margin-left: unset;

        .nav-link {
          padding-left: 8px;
          padding-right: 8px;

          @include media(md) {
            padding-left: 12px;
            padding-right: 12px;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .mobile-navigation {
      display: none;
    }
  }
}

#notebooks .dropdown-and-search-container {
  display: flex;
  align-items: center;

  input {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
    border-left: 0 none !important;
    border: 0 none!important;
    height: 52px!important;

    @include media(md) {
      border: 1px solid #514e46;
      background-color: #333029;
      color: white;
    }
  }

  .warehouse-dropdown {
    // position: absolute;
    // top: 50%;
    // left: 0;
    // transform: translateY(-50%);
    display: inline-block;
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
    height: 52px;
    background-color: white;
    padding-left: 16px;
    border: 0 none!important;
    font-size: 16px;
    color: black;

    @include media(md) {
      background-color: #333029;
      border: 1px solid #514e46;
    }

    border-right: 0 none !important;

    @include media(md) {
      color: white;
    }
  }
}
