// mobile
.mobile-filter-box {
    width: 100vw;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    border: 1px solid #F1EDE3;

    span:not([role=img]) {
        padding: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #141005;
        text-align: center;
        cursor: pointer;
        display: flex;
        column-gap: 8px;
        justify-content: center;

        &:not(:first-child) {
            border-left: 1px solid #F1EDE3;
        }
    }

    @include media(xl) {
        display: none;
        visibility: hidden;
        pointer-events: none;
    }
}

.sort-box {
    display: none;
    visibility: hidden;
    pointer-events: none;

    @include media(dxl) {
        position: fixed;
        z-index: 99;
        top: unset;
        right: 0;
        bottom: 50px;
        transform: translateX(110vw);
        transition: .3s ease;
        height: fit-content;
        display: flex;
        flex-direction: column;
        visibility: visible;
        pointer-events: all;
        border-radius: 16px;
        background-color: #fff;
        border: 1px solid #F1EDE3;
        background-color: #fff;

        width: 100%;
        max-width: 299px;

        &.sort-mobile-open {
            transform: translateX(0%);

            @media screen and (max-width:475px) {
                max-width: 100% !important;
                left: 0;
            }
        }
    }

    .sort-btn.btn {
        background-color: transparent;
        padding: 16px 8px;
        color: #141005;
        border: unset;
        position: relative;

        &:hover {
            background-color: #FAB005;

        }

        &:not(:first-child)::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            height: 1px;
            background-color: #F1EDE3;
        }


    }

}

// filter 
.filter-wrapper {
    position: sticky;
    top: 96px;
    display: flex;
    visibility: visible;
    pointer-events: all;
    flex-direction: column;
    border: 1px solid #F1EDE3;
    background-color: #fff;
    border-radius: 16px;
    align-self: start;
    padding: 16px;

    @media screen and (max-height:768px) {
        overflow-y: scroll;
        height: calc(100vh - 96px);
    }

    @include media(dxl) {
        position: fixed;

        z-index: 99;
        top: unset;
        bottom: 50px;
        transform: translateX(-120%);
        transition: .3s ease;

        &.filter-mobile-open {
            transform: translateX(0%);

            @media screen and (max-width:475px) {
                max-width: 100% !important;
                left: 0;
            }
        }
    }

    &>* {
        &:not(:first-child) {
            margin-top: 12px;
        }

    }

    .filter-headline {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #333029;

        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        align-items: baseline;

        .clear-filter {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #FA5252;

            &:active {
                transform: scale(.98);
            }
        }
    }

    .item-group {
        &.group-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;

        }

        &.group-1,
        .filter-item {
            display: flex;
            flex-direction: column;

            label {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #8C887F;
            }

            .ant-select-selector {
                background-color: transparent;
                border: unset;
                padding: unset;
                height: unset;

                .ant-select-selection-item {
                    &::after {
                        display: none;
                    }
                }
            }

            .select-css {
                font-family: "Calibri", sans-serif;
                padding: 9px 16px;
                background-color: #FCFCFC;
                border: 1px solid #F1EDE3;
                border-radius: 200px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #333029;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9401 6.71249L10.0501 11.6025C9.47256 12.18 8.52756 12.18 7.95006 11.6025L3.06006 6.71249' stroke='%2374706D' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: right 1rem center;
                background-size: 1em;

                &.ant-select-focused {
                    .ant-select-selector {
                        border-color: transparent;
                        box-shadow: unset;
                    }
                }

                .ant-select-selection-item {
                    font-family: "Calibri", sans-serif;
                    font-size: 14px;

                }
            }

            .btn-filter {
                background: #FAB005;
                border-radius: 33px;
                padding: 14px 20px;
                text-align: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
                border: unset;

                &:active {
                    transform: scale(.98);
                }

            }
        }
    }
}

// filter dropdown overlay
.filter-overlay {
    .ant-select-item-option-selected {
        background-color: rgba(250, 176, 5, 0.2) !important;
    }
}

button {
    font-family: "Calibri", sans-serif !important;
}

.btn-secondary:not(:disabled):hover,
button:not(:disabled).btn-qty:hover,
button:not(:disabled):hover {
    color: #fff !important;
    background-color: #5a6268 !important;
    border-color: #545b62 !important;

}

// search

.mobile-display {
    display: none;

    @media(max-width:767px) {
        display: block;
    }

    .top-search-box {

        margin-top: 10px;

        #notebooks {
            position: relative;

            input.form-control {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                height: unset;
                padding: 16px;
                // padding-left: 40px;
                background-color: #fff;
                border: 1px solid #F1EDE3;
                color: #141005;

                &::placeholder {
                    color: #141005;

                }
            }
        }

        .search-btn {
            position: absolute;
            background-color: transparent;
            // right: unset;
            // left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: #141005;
        }
    }
}


html[dir=rtl] {
    .filter-wrapper {
        @media screen and (max-width:1023px) {
            transform: translateX(120%);

            &.filter-mobile-open {
                transform: translateX(0%);
            }
        }

    }

    .item-group {

        .select-css {
            background-position: left 1rem center !important;
        }

    }

    .sort-box {
        @media screen and (max-width:1023px) {
            left: 0;
            right: unset;
            transform: translateX(-110%);

            &.sort-mobile-open {
                transform: translateX(0%);
            }

        }

    }
}

.mobile-display .top-search-box:not(nav.main-header .top-search-box) {
    position: fixed;
    left: 0;
    top: 76px;
    margin-top: 0;
    height: 100px;
    width: 100%;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 2;
    background-color: #f8f9fa;
    background-image: url("../../assets/bg_grey_tile.png");
}

.main-container {
    padding-top: 70px;

    @include media(md) {
        padding-top: 0;
    }
}