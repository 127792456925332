@mixin media($repons) {

    @if ($repons == dmd) {
      @media screen and (max-width: 767px) {
        @content;
      }
    }
    // phone
    @if ($repons == sx) {
      @media screen and (min-width: 375px) {
        @content;
      }
    }
    //  Phone
    @if ($repons == sxm) {
      @media screen and (min-width: 475px) {
        @content;
      }
    }
    // large Phone
    @if ($repons == sm) {
      @media screen and (min-width: 550px) {
        @content;
      }
    }
    // tablet
    @if ($repons == md) {
      @media screen and (min-width: 768px) {
        @content;
      }
    }
     // tablet big
     @if ($repons == txl) {
      @media screen and (min-width: 991px) {
        @content;
      }
    }
       // tablet big
       @if ($repons == dxl) {
        @media screen and (max-width: 1023px) {
          @content;
        }
      }
    // tablet big
    @if ($repons == xl) {
      @media screen and (min-width: 1024px) {
        @content;
      }
    }
   // normal desktop
   @if ($repons == ll) {
    @media screen and (min-width: 1180px) {
      @content;
    }
  }
    // normal desktop
    @if ($repons == lg) {
      @media screen and (min-width: 1300px) {
        @content;
      }
    }
    @if ($repons == 2xl) {
      @media screen and (min-width: 1700px) {
        @content;
      }
    }
  }
  