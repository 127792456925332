.checkbox {
    --background: #fff;
    --border: #D1D6EE;
    --border-hover: #BBC1E1;
    --border-active: #1E2235;
    --tick: #fff;
    position: relative !important;
    left: 0!important;
    vertical-align: middle;
    margin-bottom: 0;
    color: #FFF !important;
    input,
    svg {
        width: 21px;
        height: 21px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: box-shadow .3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: .4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray .6s, stroke-dashoffset .6s;
        }
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce .4s linear forwards .2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
}
.chkbox_label{
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.45rem;
}
@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}




// Radio Btn

.btn-radio {
    cursor: pointer;
    display: inline-block;
    // float: left;
    -webkit-user-select: none;
    user-select: none;
    position: relative !important;
    &:not(:first-child) {
        margin-left: 20px;
      }


    @media screen and (max-width: 480px) {
        .btn-radio {
          display: block;
          float: none;
          &:not(:first-child) {
            margin-left: 0;
            margin-top: 15px;
          }
        }
        
      }


    svg {
        fill: none;
        vertical-align: middle;
        circle {
            stroke-width: 2;
            stroke: #C8CCD4;
          }
          path {
            stroke: #008FFF;
            &.inner {
                stroke-width: 6;
                stroke-dasharray: 19;
                stroke-dashoffset: 19;
              }
              &.outer {
                stroke-width: 2;
                stroke-dasharray: 57;
                stroke-dashoffset: 57;
              }
          }
      }
    input {
        display: none;
        &:checked + svg {
            path {
                transition: all 0.4s ease;
                &.inner {
                    stroke-dashoffset: 38;
                    transition-delay: 0.3s;
                    }
                &.outer {
                    stroke-dashoffset: 0;
                    }
              }
        }
        
        
      }
   
      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        color: #FFF;
      }
  }
  
 

