.form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  
  .form-label-group > label {
    position: absolute;
    top: 0.95rem;
    left: 1rem;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  
  .form-label-group input::placeholder {
    color: transparent;
  }
  
  // .form-label-group input:not(:placeholder-shown) {
  //   padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  //   padding-bottom: calc(var(--input-padding-y) / 2);
  // }
  
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 2);
    padding-bottom: calc(var(--input-padding-y) / 2);
    font-size: 12px;
    color: #777;
    top:0
  }
  

/*
=====
LEVEL 1. CORE STYLES
=====
*/

.toggle{
  --uiToggleSize: var(--toggleSize, 20px);
  --uiToggleIndent: var(--toggleIndent, .4em);
  --uiToggleBorderWidth: var(--toggleBorderWidth, 2px);
  --uiToggleColor: var(--toggleColor, #000);
  --uiToggleDisabledColor: var(--toggleDisabledColor, #868e96);
  --uiToggleBgColor: var(--toggleBgColor, #fff);
  --uiToggleArrowWidth: var(--toggleArrowWidth, 2px);
  --uiToggleArrowColor: var(--toggleArrowColor, #fff);

  display: inline-block;
  position: relative;
}

/* added to fix bug: UI Remember me checkbox issue in login screen */
.login-card{
  .toggle{
    --uiToggleBgColor: none;
    --uiToggleArrowWidth: var(--toggleArrowWidth, 3px);
  }
}
/*==================================================================*/

.toggle__input{
  position: absolute;
  // left: -99999px;
  left:0;
  opacity: 0;
}

.toggle__label{
  display: inline-flex;
  min-height: var(--uiToggleSize);
  padding-left: calc(var(--uiToggleSize) + var(--uiToggleIndent));
}

.toggle__input:not(:disabled) ~ .toggle__label{
  cursor: pointer;
}

.toggle__label::before, 
.toggle__label::after{
  content: "";
  box-sizing: border-box;  
  width: 1em;
  height: 1em;
  font-size: var(--uiToggleSize);

  position: absolute;
  left: 0;
  top: 0;
}

.toggle__label:before{
  border: var(--uiToggleBorderWidth) solid var(--uiToggleColor);
  z-index: 2;
}

.toggle__input:disabled ~ .toggle__label::before{
  border-color: var(--uiToggleDisabledColor);
}

.toggle__input:focus ~ .toggle__label::before{
  box-shadow: 0 0 0 2px var(--uiToggleBgColor), 0 0 0px 4px var(--uiToggleColor);
}

.toggle__input:not(:disabled):checked:focus ~ .toggle__label::after{
  box-shadow: 0 0 0 2px var(--uiToggleBgColor), 0 0 0px 4px var(--uiToggleColor);
}

.toggle__input:not(:disabled) ~ .toggle__label::after{
  background-color: var(--uiToggleColor);
  opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label::after{
  opacity: 1;
}

.toggle__text{
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
}

/*
The arrow size and position depends from sizes of square because I needed an arrow correct positioning from the top left corner of the element toggle
*/

.toggle__text::before{
  content: "";
  box-sizing: border-box;
  width: 0;
  height: 0;
  font-size: var(--uiToggleSize);

  border-left-width: 0;
  border-bottom-width: 0;
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: var(--uiToggleArrowColor);

  position: absolute;
  top: .5428em;
  left: .2em;
  z-index: 3;

  transform-origin: left top;
  transform: rotate(-40deg) skew(10deg);
}

.toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text::before{
  width: .5em;
  height: .25em;
  border-left-width: var(--uiToggleArrowWidth);
  border-bottom-width: var(--uiToggleArrowWidth);
  will-change: width, height;
  transition: width .1s ease-out .2s, height .2s ease-out;
}

/*
=====
LEVEL 2. PRESENTATION STYLES
=====
*/

/* 
The demo skin
*/

.toggle__input:disabled ~ .toggle__label{
  opacity: .24;
  cursor: not-allowed;
}

.toggle__label::before, 
.toggle__label::after{
  border-radius: 2px;
}

/* 
The animation of switching states
*/

.toggle__input:not(:disabled) ~ .toggle__label::before,
.toggle__input:not(:disabled) ~ .toggle__label::after{
  opacity: 1;
  transform-origin: center center;
  will-change: transform;
  transition: transform .2s ease-out;
}

.toggle__input:not(:disabled) ~ .toggle__label::before{
  transform: rotateY(0deg);
  transition-delay: .2s;
}

.toggle__input:not(:disabled) ~ .toggle__label::after{
  transform: rotateY(90deg);
}

.toggle__input:not(:disabled):checked ~ .toggle__label::before{
  transform: rotateY(-90deg);
  transition-delay: 0s;
}

.toggle__input:not(:disabled):checked ~ .toggle__label::after{
  transform: rotateY(0deg);
  transition-delay: .2s;
}

.toggle__text::before{
  opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text::before{
  opacity: 1;
  transition: opacity .1s ease-out .3s, width .1s ease-out .5s, height .2s ease-out .3s;
}

/*
=====
LEVEL 3. SETTINGS
=====
*/

.toggle{
  --toggleColor: #1a1a1a;
  --toggleBgColor: #000000b4;
  --toggleSize: 25px;
}
