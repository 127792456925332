::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.versioncode {
  bottom: -6rem;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
}

.loginpage {
  margin-top: -30px;
  max-width: 100% !important;
  padding: 0;
}

.articleNo {
  position: absolute;
  left: 50px;
  bottom: 5px;
}

footer {
  border-top: 8px solid #ffa500;
  background-color: #000;
  padding: 10px;
  color: #fff;

  .footerWrapper {
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 640px) {
      max-width: 100%;
    }
  }

  .footerMenu {
    display: flex;

    li {
      a {
        display: block;
        padding: 5px;
        color: #fff;
        font-weight: 500;
        margin: 0 10px;
      }

      a:hover {
        color: #fff;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 76.5%;
      margin: auto;
    }
  }

  .copyright,
  .version_code {
    padding: 7px 0;
    display: block;

    @media only screen and (max-width: 640px) {
      text-align: center;
    }
  }
}

.z-index-1 {
  z-index: 500;
}

.wd-1024 {
  @media only screen and (max-width: 1024px) {
    margin-top: 0.5rem !important;
  }

  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 0.5rem !important;
  }
}

.lbl-gr {
  position: absolute;
  left: 0;
}

.erroralert {
  top: 7rem !important;
  position: fixed !important;
}

.link-btn {
  text-decoration: none;
  color: #333;
}

.mb-7 {
  margin-bottom: 2.4rem !important;
}

.mendet {
  color: #f00;
}

.primary-color {
  color: #ffa500;
}

.scroll-arrow {
  float: right;
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.rotate {
  transform: rotateX(180deg);
}

.showCheq {
  height: auto;
}

.hideCheq {
  @media only screen and (max-width: 1024px) {
    height: 410px;
  }

  @media only screen and (max-width: 768px) {
    height: 490px;
  }

  @media only screen and (max-width: 414px) {
    height: 440px;
  }
}

.showInvoice {
  height: auto;
}

.hideInvoice {
  @media only screen and (max-width: 1024px) {
    height: 410px;
  }

  @media only screen and (max-width: 768px) {
    height: 320px;
  }

  @media only screen and (max-width: 414px) {
    height: 320px;
  }
}

.sort-arrow {
  transition-delay: 0.5s;
  position: absolute;
  top: 5px;

  img {
    width: 1rem;
  }
}

.sort-arrow-down {
  transform: rotateZ(0deg);
}

.sort-arrow-up {
  transform: rotateZ(180deg);
}

.product-list {
  .card-body {
    padding: 15px;

    .list-total {
      text-align: left;

      @media only screen and (max-width: 1366px) and (orientation: landscape) {
        padding-left: 25px !important;
      }
    }

    .select-css-price {
      position: relative;
      // display: block;
      font-size: 17px;
      // color: #7d7d7d;
      line-height: 1.3;
      padding: 0rem 0.95rem 0 0;
      // width: 100%;
      // max-width: 75px;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 0.25em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      // background-color: #d3d3d3;
      background-image: url("../../assets/dropdown-arrow.png");
      background-repeat: no-repeat, repeat;
      /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
      background-position: right 0em top 50%, 0 0;
      /* icon size, then gradient */
      background-size: 0.7em auto, 100%;
      border: 1px solid rgba(0, 0, 0, 0);

      option {
        font-weight: normal;
        color: #000;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .list-product-title {
      min-height: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    .list-product-title {
      min-height: 70px;
    }
  }
}

.nav-tabs {
  border-bottom: 0px;

  .nav-link {
    &.active {
      border-color: transparent;
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 475px) {
    margin-top: 1.5rem;
  }
}

.product-page {
  .card {
    margin-bottom: 20px;
  }

  .slick-slide.slick-active {
    .card {
      @media only screen and (max-width: 768px) and (min-width: 500px) {
        margin-top: 1rem;
        width: 97% !important;
      }
    }
  }
}

.modal-header>button {
  margin: -1rem -1rem -1rem -1rem !important;
}

.bdr-saprator {
  display: block;
  background: #000;
  height: 3px;
  margin: 0.75rem 0;
}

.bdr-saprator-2 {
  display: block;
  background: #ccc;
  height: 1px;
  margin: 0.5rem 0;
}

.m-fnt12 {
  font-size: 0.75rem;
}

.text-grey {
  color: #949494 !important;
}

.total-amount {
  font-size: 1.2rem;

  @media only screen and (max-width: 500px) {
    font-size: 1rem;
  }
}

// .rounded-pill {
//     @media (max-width: 767.98px) {
//         height: 32px;
//     }
// }
.alert-danger.show {
  top: -1rem;
  right: 0;
  position: absolute;
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
  transition: all 0.2s ease-in-out;
}

.text_lightgrey {
  color: rgba(0, 0, 0, 0.3);
}

.mobileSearch {
  position: fixed;
  left: 0;
  right: 0;
  top: 58px;
  padding: 10px;
  background: #fff;

  .search-btn {
    top: 11px;
    right: 12px;
  }
}

.factbooks {
  text-align: right;

  div {
    display: inline-block;
  }

  div+a {
    margin-left: 10px;
  }

  a+a {
    margin-left: 10px;
  }
}

.qtylable {
  text-align: center;
  background: orange;
  position: absolute;
  border-radius: 50%;
  right: 0px;
  top: 10px;
  width: 15px;
  height: 15px;
  color: #000;
  font-size: 0.75rem;
  padding: 0;
  border: 1px solid orange;
  line-height: 0.75rem;
}

.nav-notification~.qtylable {
  right: 25px;
}

.reservedStcokqtylable {
  text-align: center;
  background: orange;
  position: absolute;
  border-radius: 50%;
  right: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  color: #000;
  font-size: 0.75rem;
  padding: 0;
  border: 1px solid orange;
  line-height: 0.75rem;
}

.basket {
  max-width: 40px;
  position: relative;
  margin: auto;
}

.enlarged {
  background: #fff;
  z-index: 1;
}

.cursor_pointer_decoration {
  cursor: pointer;
  text-decoration: underline;

  @media only screen and (max-width: 475px) {
    margin-bottom: 5px;
    font-size: 13px;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.order-status {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  // border: 1px solid;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    display: block;
  }

  &.placed {
    border: 1px solid;
    border-color: #616161;
  }

  &.final {
    border: 1px solid;
    border-color: #ffa500;
    background: #ffa500;
  }

  &.confirm {
    border-color: #ffa500;
    background: transparent;
    /* Old browsers */
    background: -moz-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear,
        left top,
        right top,
        color-stop(0%, transparent),
        color-stop(50%, transparent),
        color-stop(50%, #ffa500),
        color-stop(100%, #ffa500));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,
        transparent 0%,
        transparent 50%,
        #ffa500 50%,
        #ffa500 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* IE10+ */
    background: linear-gradient(to right, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='transparent', endColorstr='#FFA500', GradientType=1);
    /* IE6-9 */

    &:after {
      content: "";
      position: absolute;
      right: 0.02rem;
      bottom: 0.01rem;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 0 0 50px 0;
    }
  }

  &.ready-to-ship {
    border-color: #ffa500;
    background: transparent;
    /* Old browsers */
    background: -moz-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear,
        left top,
        right top,
        color-stop(0%, transparent),
        color-stop(50%, transparent),
        color-stop(50%, #ffa500),
        color-stop(100%, #ffa500));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,
        transparent 0%,
        transparent 50%,
        #ffa500 50%,
        #ffa500 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* IE10+ */
    background: linear-gradient(to right, transparent 0%, transparent 50%, #ffa500 50%, #ffa500 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='transparent', endColorstr='#FFA500', GradientType=1);
    /* IE6-9 */

    &:after {
      content: "";
      position: absolute;
      right: 0.02rem;
      bottom: 0.01rem;
      width: 50%;
      height: 0%;
      background-color: white;
      border-radius: 0 0 50px 0;
    }
  }

  &.in-transit {
    border-color: #ffa500;
    background: #ffa500;
    /* Old browsers */

    &:after {
      content: "";
      position: absolute;
      left: 0.02rem;
      top: 0.01rem;
      width: 50%;
      height: 50%;
      background-color: white;
      border-radius: 50px 0 0px 0;
    }
  }

  &.delivered {
    background: #008435;
    border-color: #008435;
    color: #fff;
    font-size: 0.75rem;
    padding: 2px;

    span.icon-check {
      display: block;
    }
  }

  &.cancelled {
    border-color: #8e1216;
    background: #8e1216;

    &::after {
      font-family: "kislev" !important;
      border-radius: 50%;
      content: "\e910";
      position: absolute;
      color: #fff;
      left: 4px;
      top: -3px;
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 740px) and (orientation: landscape) {
    width: 15px;
    height: 15px;
    margin-right: 5px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  @media only screen and (max-width: 475px) {
    width: 15px;
    height: 15px;
    margin-right: 5px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.order-status-track {
  position: relative;

  li {
    padding: 0px 0 30px 45px;
    position: relative;
    color: rgb(155, 155, 155);

    h5 {
      color: rgb(155, 155, 155);
    }

    .status {
      width: 14px;
      height: 14px;
      border: 1px solid #008435;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 15px;
      top: 5px;
      background: #008435;
      z-index: 1;

      &::after {
        font-family: "kislev" !important;
        border-radius: 50%;
        content: "\e90f";
        position: absolute;
        color: #fff;
        left: 2px;
        top: 1px;
        font-size: 8px;
      }
    }

    &:first-child {
      color: #008435;

      h5 {
        color: #008435;
      }

      .status {
        width: 14px;
        height: 14px;
        border: 1px solid #008435;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 15px;
        top: 5px;
        background: #fff;
        z-index: 1;

        &::after {
          border-radius: 50%;
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #008435;
          left: 2px;
          top: 2px;
        }

        // @media only screen and (max-width: 475px){
        //     left: 0px;
        // }
      }
    }

    &.isCancelled {
      color: #8e1216;

      h5 {
        color: #8e1216;
      }

      .status {
        width: 14px;
        height: 14px;
        border: 1px solid #8e1216;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 15px;
        top: 5px;
        background: #fff;
        z-index: 1;

        &::after {
          border-radius: 50%;
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #8e1216;
          left: 2px;
          top: 2px;
        }

        @media only screen and (max-width: 475px) {
          left: 0px;
        }
      }
    }

    &:last-child {
      &::after {
        position: absolute;
        left: 20px;
        top: 5px;
        content: "";
        border-left: 0px solid #008435;
        height: 100%;
      }
    }

    &::after {
      position: absolute;
      left: 20px;
      top: 5px;
      content: "";
      border-left: 3px solid #008435;
      height: 100%;
    }

    @media only screen and (max-width: 475px) {
      padding: 0px 0 30px 25px;
      font-size: 0.9rem;
    }
  }

  .row {
    flex-direction: column-reverse;

    .col {

      /*margin-bottom: 1rem;*/
      h5 {

        /*margin-left: 1.5rem !important;*/
        @media only screen and (max-width: 475px) {
          margin-left: 0.5rem !important;
        }
      }

      .icon-check {
        /*position: relative;
                border-radius: 10px;
                background-color: green;
                color: white;*/
        width: 14px;
        height: 14px;
        border: 1px solid #008435;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 15px;
        top: 5px;
        background: #008435;
        z-index: 1;

        @media only screen and (max-width: 475px) {
          left: 0px;
        }

        &::before {
          border-radius: 50%;
          position: absolute;
          width: 8px;
          height: 8px;
          left: 0px;
          top: 0px;
          color: white;
          transform: scale(0.5);
        }
      }

      span {
        hr {
          position: absolute;
          border: 2px solid #008435;
          top: -4px;
          right: 4px;
          background-color: #008435;
          height: 2.8rem;
          z-index: 3;

          @media only screen and (max-width: 475px) {
            height: 2.5rem;
          }

          @media only screen and (max-width: 413px) {
            height: 4rem;
          }
        }

        .extend_line_1 {
          @media only screen and (max-width: 475px) {
            height: 4rem;
          }
        }

        .extend_line {
          @media only screen and (max-width: 413px) {
            height: 5.4rem;
          }
        }
      }

      span:last-child {
        margin-left: 1.5rem !important;

        @media only screen and (max-width: 475px) {
          margin-left: 0.5rem !important;
        }
      }
    }

    hr {
      position: absolute;
    }
  }

  @media only screen and (max-width: 475px) {
    margin-left: -15px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  right: 10px;
  position: absolute;
}

.order-tab {
  // border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1.2rem;

  .nav-item {
    font-size: 1.2rem;

    .nav-link {
      color: #000;

      &.active {
        background-color: transparent;
        font-weight: bold;
        color: #000;
        border-bottom: 2px solid #000;
      }

      &:hover {
        font-weight: bold;
        border-color: transparent;
        border-bottom: 2px solid #000;
      }
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 475px) {
    padding-top: 0.5rem;
  }
}

.order-list {
  line-height: 1.5rem;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 960px) and (min-width: 540px) {
    &>div:nth-child(3) {
      text-align: left !important;
    }

    &>div:nth-child(5) {
      padding-left: 0px !important;
    }
  }
}

.order-search {
  position: absolute;
  top: 0rem;
  left: 7rem;

  .form-control {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .btn-link {
    padding: 0.45rem 0.75rem 0.3rem 0.75rem;
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0px;
    text-decoration: none;
  }

  &.complaint {
    top: 0rem;
    left: 10rem;
  }

  &.stock_search {
    width: 200px;
    top: 0rem;
    left: 13rem;
  }

  // & > input:first-child{
  //     margin-top: 5px;
  //     // @media only screen and (max-width: 475px){
  //     //     padding-top: 1px;
  //     //     padding-bottom: 1px;
  //     //     height: 2rem;
  //     // }
  // }
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    top: 4rem;
    left: 1em;
    width: 59%;

    .form-control {
      height: 36px;
    }

    .btn-link {
      padding: 0.25rem 0.75rem 0.3rem 0.75rem !important;
      top: 4px;
    }

    &.complaint {
      width: 100%;
      position: relative !important;
      left: 0;
    }

    &.stock_search {
      width: 100%;
      position: relative !important;
      left: 0;
    }

    &+.order-tab>div:first-child {
      margin-top: -1rem;
    }
  }

  @media only screen and (max-width: 475px) {
    top: 5.2em;
    left: 1em;
    width: 63%;

    .form-control {
      height: 36px;
    }

    .btn-link {
      padding: 0.25rem 0.75rem 0.3rem 0.75rem !important;
      top: 4px;
    }

    &.complaint {
      width: 100%;
      position: relative !important;
      left: 0;
    }

    &.stock_search {
      width: 100%;
      left: 0;
      // position: relative !important;
    }

    &+.order-tab>div:first-child {
      margin-top: -1rem;
    }
  }

  @media only screen and (max-width: 376px) {
    top: 4.7em;
    width: 60%;
  }

  @media only screen and (max-width: 375px) {
    font-size: 15px;
    top: 4.1em;
    width: 59%;
  }
}

.Toastify {
  &>.Toastify__toast-container {
    width: fit-content;
  }
}

.complaint-btn {
  margin-bottom: 1rem;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    margin-bottom: 0rem;
  }

  @media only screen and (max-width: 800px) and (orientation: landscape) {
    margin-bottom: 0rem;
  }

  @media only screen and (max-width: 670px) and (orientation: landscape) {
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: 475px) {
    margin-bottom: 1.8rem;
    min-height: 36px;
    min-width: 6.876rem;
  }

  @media only screen and (max-width: 360px) {
    margin-bottom: 1.6rem;
  }
}

.order-complaint {
  margin-top: -4em;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    margin-top: 0em;
  }

  @media only screen and (max-width: 800px) and (orientation: landscape) {
    margin-top: -1.3em;
  }

  @media only screen and (max-width: 670px) and (orientation: landscape) {
    margin-top: -4.3em;
  }

  @media only screen and (max-width: 475px) and (min-width: 376px) {
    margin-top: -4em;
  }

  @media only screen and (max-width: 376px) {
    margin-top: -4.5em;
  }
}

.header-order+.tab-content>.tab-pane>.card {
  @media only screen and (max-width: 475px) and (min-width: 376px) {
    font-size: 15px;
  }
}

.reservedstock {
  @media only screen and (min-width: 415px) {
    margin-top: 1rem;

    &+.order-search {
      margin-top: -0.2rem;
    }
  }

  @media only screen and (max-width: 475px) {
    &+div:nth-child(2) {
      margin-top: 0rem !important;

      &>div:nth-child(2) {
        margin-top: 0.5rem !important;
      }
    }

    &~.row .card-body .rounded-pill {
      padding: 0.5rem 1rem;
    }

    &~.row .card-body .icon-cart {
      margin-right: 1em;

      &+.d-none {
        display: unset !important;
        white-space: nowrap;
      }
    }
  }
}

.resered-stock-list {
  &>.card-body>.row>div:nth-child(3) {
    span {
      @media only screen and (min-width: 1195px) {
        margin-left: -4rem;
        white-space: nowrap;
      }
    }
  }

  @media only screen and (min-width: 1140px) {
    & .quntity {
      margin-left: -2rem;
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    &>.card-body>.row {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto;
      grid-template-areas: "b1 b3""b2 b5""b4 b6";
      grid-gap: 10px;

      &>div:nth-child(1) {
        grid-area: b1;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(2) {
        grid-area: b2;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(3) {
        grid-area: b3;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(4) {
        grid-area: b4;
        flex: unset;
        max-width: unset;
        margin-top: 1rem;
        width: 6rem;
      }

      &>div:nth-child(5) {
        grid-area: b5;
        flex: unset;
        max-width: unset;
        text-align: left !important;
      }

      &>div:nth-child(6) {
        grid-area: b6;
        flex: unset;
        max-width: unset;
        align-self: end;

        .icon-cart {
          margin-right: 5px;
        }

        .cta-btn {
          padding: 7px 5px 7px;
        }
      }
    }
  }

  @media only screen and (max-width: 1195px) {
    &>.card-body>.row {
      display: grid;
      // grid-template-columns: 6rem 6rem auto auto auto auto;
      // grid-template-rows: auto auto;
      // grid-template-areas: "b1 b1 b4 b2 b3 b6" "b5 b5 b5 b7 b7 b7";

      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: "b1 b1 b3 b3""b4 b4 b6 b6""b2 b2 b7 b7""b5 b5 b8 b8";
      grid-gap: 10px;

      &>div:nth-child(1) {
        grid-area: b1;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(2) {
        grid-area: b2;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(3) {
        grid-area: b3;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(4) {
        grid-area: b4;
        flex: unset;
        max-width: unset;
        text-align: left !important;
      }

      &>div:nth-child(5) {
        grid-area: b5;
        flex: unset;
        max-width: unset;
        text-align: left !important;
      }

      &>div:nth-child(6) {
        grid-area: b6;
        flex: unset;
        max-width: unset;
        margin-left: -15px;
        text-align: left !important;
      }

      &>div:nth-child(7) {
        grid-area: b7;
        flex: unset;
        max-width: unset;
      }

      &>div:nth-child(8) {
        grid-area: b8;
        flex: unset;
        max-width: unset;
      }
    }
  }

  @media only screen and (max-width: 769px) {
    &>.card-body>.row {
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: "b1 b1 b3 b3""b4 b4 b6 b6""b2 b2 b7 b7""b5 b5 b8 b8";
      grid-gap: 10px;
    }
  }
}

.card-text:last-child {
  white-space: break-spaces;
  margin-bottom: 0;
}

.transparent-btn {
  background-color: transparent;
  color: #000;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    white-space: nowrap;
  }
}

.sortby {
  .select-css {
    background-color: #e0e0e0;
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    color: #7d7d7d;
    line-height: 1;
    padding: 0.5rem 1.95rem 0.5rem 1rem;
    width: auto;
    max-width: 100%;
    text-align: center;
    box-sizing: border-box;
    margin: 0;
    border-radius: 1.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    // background-color: #FFF;
    // border: none;
    background-image: url("../../assets/dropdown-arrow-bg-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;

    // border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    /* Set options to normal weight */
    option {
      font-weight: 700;
      color: #000;
      text-align: center;
      background: #fff;
      padding: 0.5rem 0;
    }

    &.active {
      background-color: #fff;
      border-radius: 0em;
      border-bottom: 0;
      background-image: url("../../assets/dropdown-arrow-bg-3.png");
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }

    @media only screen and (max-width: 960px) and (orientation: landscape) {
      padding: 0.55rem 2.75rem 0.55rem 2rem;
    }

    @media only screen and (max-width: 475px) {
      padding: 0.55rem 1.95rem 0.55rem 1.15rem;
    }
  }
}

.no-gutters.border-bottom.border-secondary.pb-2.mb-3.d-sm-flex.row {
  @media only screen and (max-width: 475px) {
    flex-direction: column;
  }
}

.lang {
  &.select-css {
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.5rem;
    width: 100%;
    max-width: 55px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f8f9fa;
    background-image: url("../../assets/dropdown-arrow-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.5em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &.select-css-logout {
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.5rem;
    width: 100%;
    max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #d3d3d3;
    background-image: url("../../assets/dropdown-arrow-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.5em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 1);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

.complaint-form {
  font-size: 1.1rem;

  .form-control {
    background: rgb(231, 231, 231);
    height: calc(2em + 0.75rem + 2px);
    border: 1px solid #e7e7e7;
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container input {
      background: rgb(231, 231, 231);
      height: calc(2em + 0.5rem + 2px);
      border: 1px solid #e7e7e7;
      width: 100%;
      border-radius: 0.25em;
      padding-left: 1em;
    }
  }

  .select-css {
    background: #e7e7e7;
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.75rem 0.75rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #e7e7e7;
    border: none;

    /* Set options to normal weight */
    option {
      font-weight: normal;
      color: #000;
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea.form-control {
    height: 320px;
    resize: none;
  }

  /*label{
        position: absolute;
        right: 25px;
        top: 10px;
    }*/
  .email-error {
    color: $danger-color;
    white-space: nowrap;
  }
}

.account-security {
  width: 90%;

  .border-bottom {
    border-bottom: 2px solid #9c9d9d !important;
  }
}

.complaint_status {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 10px;

  &.received {
    border-color: #616161;
  }

  &.processing {
    border-color: #ffa500;
    background: #ffa500;
  }

  &.approve {
    border-color: #008435;
    background: #008435;

    &::after {
      font-family: "kislev" !important;
      border-radius: 50%;
      content: "\e90f";
      position: absolute;
      color: #fff;
      left: 2px;
      top: 1px;
      font-size: 8px;
    }
  }

  &.reject {
    border-color: #8e1216;
    background: #8e1216;

    &::after {
      font-family: "kislev" !important;
      border-radius: 50%;
      content: "\e910";
      position: absolute;
      color: #fff;
      left: 2px;
      top: 1px;
      font-size: 8px;
    }
  }
}

.complaint_wrapper {
  .text-underline {
    text-decoration-skip-ink: none;
  }
}

.login-card {
  position: absolute;
  z-index: 1;
  right: 2%;
  padding: 3rem 2.5rem 1rem;
  width: 21rem;
  z-index: 9999;

  .form-control {
    padding: 1rem 1rem 0.25rem;
    background-color: rgba(246, 246, 246, 1);
    border-radius: 0em;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #000;
    height: auto;
    outline: unset;

    &:focus {
      outline: unset;

      box-shadow: unset;
    }
  }

  label {
    font-size: 1rem;
  }

  /* added to resolve appearing text twice */
  .form-label-group>label {
    visibility: hidden;
  }

  .form-label-group input:not(:placeholder-shown)~label {
    visibility: visible;
    font-size: 0.875rem;
    left: 0.875rem;
  }

  /*=========================*/
}

.minw-22 {
  min-width: 22rem;
  max-width: 22rem;
}

.minw-15 {
  min-width: 15rem;
  max-width: 15rem;
}

.user-nav {
  .dropdown-menu {
    background-color: rgba(26, 26, 26, 1);
    color: #fff;
    left: unset;
    right: -2rem;
    border-radius: 0;
    margin: 0.1rem 0 0 0;
    padding: 0;

    &.show:before {
      content: "";
      position: absolute;
      left: 10rem;
      top: -20px;
      z-index: 1001;
      font-size: 40px;
      line-height: 0;
      color: #fff;
      text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(26, 26, 26, 1);
      border-left: 10px solid transparent;
    }

    .dropdown-item {
      color: #ffff;
      padding: 1rem 1.5rem;
      white-space: pre-wrap;

      &:hover {
        background-color: transparent;
      }

      h5 {
        color: #fff;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        small {
          float: right;
          margin-top: 3px;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .bdg-small {
        margin-right: 5px;
        margin-left: 0;
      }
    }

    .dropdown-item+.dropdown-item {
      border-top: 1px solid rgba(255, 255, 255, 0.35);
    }
  }

  // @media only screen and (max-width: 1280px) {
  //   max-width: 48% !important;
  // }
  // @media only screen and (max-width: 1170px) {
  //   max-width: 88% !important;
  // }

  // @media only screen and (max-width: 475px) {
  //   max-width: 100% !important;
  // }
}

/*added to remove this bug https://app.clickup.com/t/4qtd4r */
.show {
  @media only screen and (max-width: 1024px) {
    &::before {
      display: none;
    }
  }
}

.dropdown-menu {
  left: -8rem;
  border-radius: 0;
  margin: 0.1rem 0 0 0;
  padding: 0;
  // &.show:before{
  //     content: "";
  //     position: absolute;
  //     left: 10rem;
  //     top: -20px;
  //     z-index: 1001;
  //     font-size: 40px;
  //     line-height: 0;
  //     color: #fff;
  //     text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
  //     border-top: 10px solid transparent;
  //     border-right: 10px solid transparent;
  //     border-bottom: 10px solid rgba(26,26,26,1);
  //     border-left: 10px solid transparent;
  // }
}

.dropmenu {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 5px #616161;
  width: 15rem;
  text-align: left;
  // padding: 10px;
  z-index: 1025;
  border-radius: 5px;
  right: 0;

  ul {
    li {
      a {
        color: #000;

        &:hover {
          color: #616161;
        }
      }

      display: block;
      list-style: none;
      padding: 10px 15px;
    }
  }
}

.forgotpassword {
  position: absolute;
  max-width: 339px;
  text-align: center;
  box-shadow: 6px 6px 16px #00000008;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) !important;

  .modal-content {
    border-radius: unset !important;
  }

  .modal-body {
    .form-group {
      margin-top: 2rem !important;

      input[type="text"] {
        background-color: #e0e0e0;
        height: 3rem;
        border-bottom: 0.5px solid rgba(0, 0, 0, 1);
      }
    }

    padding: 3.125rem !important;

    h3 {
      font-size: 1.5rem;
      margin-bottom: 2rem !important;
    }

    .text-center.text-muted {
      font-size: 1rem;
      margin-bottom: 1.4rem;
    }

    button {
      margin-top: 10px !important;
      box-shadow: 0px 3px 6px #00000029;
      height: 3rem;
      display: inline;
    }

    a {
      color: #000;
    }
  }

  // transform: translateY(-50%);
  @media only screen and (max-width: 475px) {
    max-width: 100% !important;
    min-width: 320px;
    transform: translate(-50%, -50%) !important;

    .modal-close {
      right: -10px;
    }

    .modal-body {
      padding: 3rem 2rem !important;
    }
  }

  /*=========================*/

  /* added to resolve appearing text twice */
  .form-label-group>label {
    visibility: hidden;
  }

  .form-label-group input:not(:placeholder-shown)~label {
    visibility: visible;
  }

  /*=========================*/
  .modal-body {
    padding: 3rem 2rem;

    /* Making changes to make the font bold */
    h3 {
      font-weight: bold;
    }

    .form-group {
      .rounded-pill {
        font-weight: bold;

        .icon-next {
          font-weight: bold;
          font-size: 0.8rem;
          margin-left: 0.5em;
        }
      }

      a {
        text-decoration: underline;
      }

      .email-error {
        color: $danger-color;
      }

      .email-error:first-letter {
        text-transform: uppercase;
      }
    }

    /* Making changes to make the font bold END*/
  }

  .form-control {
    padding: 1rem 1rem 0.25rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 0em;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.45);
    color: #000;
    height: auto;
  }
}

.border-secondary {
  .rounded-pill {
    padding: 0.1rem 1.5rem;

    @media only screen and (max-width: 475px) {
      padding: 0.5rem 1rem;
      white-space: nowrap;
    }
  }

  .dropdown-toggle {
    padding: 0.1rem 0.5rem;
  }
}

.filter-icon-btn {
  // position: absolute;
  vertical-align: middle;
  font-size: 1.5rem;
  line-height: 1.5rem;
  // margin-right: 1.5rem;
  display: inline-block;
  cursor: pointer;
  color: #9c9d9d;

  span:hover {
    color: #fff;
  }

  &.active {
    span {
      color: #fff;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0.5rem;
  }
}

.custom_alert {
  transition: none;
  opacity: 1 !important;

  &.alert-dark {
    .col-xl-8 {
      text-align: left;
    }
  }
}

.product-page {
  &>.custom_alert:nth-child(1) {
    margin-top: 0rem;
  }
}

.promo_alert {
  transition: none;
  opacity: 1 !important;

  &.alert-dark {
    background: #ffa500;
    color: #000;
    margin-bottom: 2.5rem;
  }

  .btn-link {
    color: #fff;
  }

  .promocode {
    color: #fff;
  }

  a {
    color: #fff;
    font-weight: 700;
  }
}

.search-btn {
  line-height: 1rem;
  position: absolute;
  top: 1px;
  right: 16px;
  background: #ffa500;
  border: 0;
  padding: 0.563rem 0.625rem;

  left:auto &:hover {
    border: none;
  }

  @media only screen and (max-width: 420px) {
    // top: -20px;
    right: 5px;
  }

  @media only screen and (max-width: 900px) {
    top: 2px;
    padding: 0.675rem 0.625rem;
  }
}

/* Changing the icon color on mobile view */

.search-btn-mob {
  @media only screen and (max-width: 768px) {
    top: 0px;
    right: 0px;
    background-color: darkgray;

    &:hover {
      background-color: darkgray;
    }
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.filters-box {
  overflow-y: auto;
  width: 294px;
  background: url("../../assets/side-bg.png") no-repeat top;
  background-size: cover;
  height: 100%;

  @media (min-width: 768px) {
    height: calc(100% - 80px);
  }

  @media (max-width: 475px) {
    .filter-button_btn {
      position: absolute;
      bottom: 3rem;
      left: 0;
      display: flex;
      width: 100%;
      padding: 1.5rem 2rem 1rem;
      background-color: #000000;

      >div.row {
        margin-right: 0;
        margin-left: 0;

        &:nth-child(1) {
          order: 2;
          flex-basis: 55%;
        }

        &:nth-child(2) {
          flex: 1;
          margin-right: 1rem;
        }

        button.btn {
          height: 40px;
        }
      }
    }
  }

  top: 80px;
  position: fixed;
  z-index: 1050;
  left: -294px;
  color: #fff;
  padding: 2rem 2rem;
  transition: ease-in 2s linear;

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    top: 60px;
    padding-bottom: 4rem;
  }

  @media only screen and (max-width: 475px) {
    top: 58px;
  }

  h3 {
    color: #fff;
    margin: 0 -10px 25px;
  }

  button.btn-primary {
    display: inline;
  }

  &.open {
    left: 0;
  }

  .close {
    color: #fff;
    opacity: 1;
    font-size: 1rem;
    line-height: 1.9rem;
  }

  label {
    color: rgba(255, 255, 255, 0.35);
    position: absolute;
    left: 0.75rem;
    top: 0;
    font-size: 16px;
  }

  .select-css {
    position: relative;
    display: block;
    font-size: 17px;
    color: #fff;
    line-height: 1.3;
    padding: 1.5rem 1rem 0.25rem;
    width: 100%;
    max-width: 100%;
    /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: rgba(255, 255, 255, 0.15);
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
              for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
              
          */
    background-image: url("../../assets/white-arrow.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);

    /* Set options to normal weight */
    option {
      font-weight: normal;
      color: #000;
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }
  }

  .form-control {
    padding: 1.5rem 1rem 0.25rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 0em;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
    color: #fff;
    height: auto;
  }

  .btn-outline-secondary {
    border: 1px solid #fff;
    color: #fff;

    &:hover {
      background: transparent;
      border: 1px solid #ffa500;
      color: #ffa500;
    }
  }

  @media only screen and (max-width: 420px) {
    top: 52px;
    width: 100%;
    left: -100%;
  }

  .react-datepicker-wrapper {
    width: 100%;

    input::placeholder {
      color: #fff;
    }
  }
}

.filters-box>.row:nth-last-child(1) {
  @media only screen and (max-width: 475px) {
    flex-direction: row-reverse;
  }
}

.filter-on {
  &.container {
    width: calc(100% - 320px);
    margin-left: 320px;
    padding-right: 30px;

    @media only screen and (max-width: 960px) and (orientation: landscape) {
      width: calc(100% - 300px);
      margin-left: 300px;
      padding-right: 15px;
      max-width: 100% !important;
    }

    @media only screen and (max-width: 455px) {
      width: 100%;
      margin-left: 0px;
      padding-right: 15px;
      max-width: 100% !important;
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    .cart-header {
      .order-search {
        left: 52%;
      }
    }
  }
}

.mob-card {
  @media only screen and (max-width: 768px) {
    min-height: 156px;
  }

  @media only screen and (max-width: 768px) and (orientation: landscape) {
    min-height: calc(100% - 20px);
  }
}

.mobile-view {
  position: relative;

  @media only screen and (max-width: 1024px) {
    position: absolute;
    max-width: 62%;
    left: -118px;
    bottom: -48px;
    z-index: 1;
  }

  @media only screen and (max-width: 768px) {
    max-width: 60%;
    left: 0px;
    bottom: 20px;
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    max-width: 60%;
    left: -270px;
  }

  @media only screen and (max-width: 400px) {
    max-width: 60%;
    left: -5px;
    bottom: 10px;
  }
}

.counter-align {
  @media only screen and (max-width: 768px) {
    left: -48%;
    //bottom: -24%;
    top: 100%;
  }

  @media only screen and (max-width: 767px) and (min-width: 575px) {
    max-width: 33%;
    left: -30%;
    top: 110%;
  }

  @media only screen and (max-width: 1024px) and (min-width: 992px) {
    left: -45%;
    top: 78%;
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    top: 100%;
  }
}

.left-box {
  overflow-y: auto;
  width: 294px;
  background: url("../../assets/side-bg.png") no-repeat top;
  background-size: cover;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 75px;
  left: -294px;
  color: #fff;
  padding: 2rem 2rem;
  transition: ease-in 2s linear;

  &.open {
    left: 0;
  }

  @media only screen and (max-width: 420px) {
    top: 52px;
    width: 100%;
    left: -100%;
    z-index: 4;
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    top: 0;
  }

  .usernav {
    h3 {
      color: #ffa500;
      margin-bottom: 2rem;
    }

    ul {
      margin: 0 -2rem;

      li {
        white-space: nowrap;
        font-size: 1rem;
        border-bottom: 1px solid #e0e0e075;

        span {
          display: inline-block;
          margin-right: 0.75rem;
        }

        a {
          color: #fff;
          font-weight: normal;
          padding: 0.844rem 2rem;
          display: block;
        }

        &.active {
          background: #ffa500;
          border: 0;

          a {
            color: #000;
            font-weight: bold;
          }
        }

        &:hover {
          background: #ffa500;

          a {
            color: #000;
            font-weight: bold;
          }
        }
      }

      li.active+li {
        border-top: 1px solid transparent;

        &:hover {
          border-top: 1px solid #e0e0e075;
        }
      }

      //li:last-child{border-bottom: 0px;}
    }

    li {
      a {
        display: flex;
        align-items: center;

        span {
          width: 2rem;
        }
      }
    }

    & ul li:hover img {
      filter: invert(1);
    }
  }
}

.userCard {
  min-height: 460px;
  margin-bottom: 30px;

  .userField {
    background: rgba(222, 222, 222, 0.35);
    padding: 0.75rem 1rem;
    margin-top: 0.35rem;
  }

  .editfield {
    display: inline-block;
    padding: 0.5rem;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }

  .userField-underline {
    padding: 0.75rem 0rem;

    /*margin-top: 0.35rem;*/
    @media only screen and (max-width: 768px) {
      padding: 0.35rem 0rem 0.75rem;
      margin-top: 0rem;
    }
  }

  .text-mutede {
    color: #9c9d9d !important;
  }

  .select-css {
    background: #e7e7e7;
    position: relative;
    display: block;
    font-size: 17px;
    color: #7d7d7d;
    line-height: 1.3;
    padding: 0.75rem 0.75rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #f8f9fa;
    background-image: url("../../assets/dropdown-arrow-2.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.5em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // border-bottom: 2px solid rgba(255, 255, 255, 0.25);

    /* Set options to normal weight */
    option {
      font-weight: normal;
      color: #000;
    }

    /* Hide arrow icon in IE browsers */
    &::-ms-expand {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.nav-item {
  position: relative;
}

.nav-item+.nav-item {
  margin-left: 1rem;

  @media only screen and (max-width: 1024px) and (min-width: 769px) {
    margin-left: 0px;
  }
}

.user-nav {
  display: flex;
  flex: 1;
  gap: 16px;
}

.nav-icon {
  font-size: 1.5rem;
  display: block;
  text-align: center;
}

.nav-txt {
  font-size: 0.9rem;
  display: block;
  text-align: center;
}

// .border-bottom{ border-bottom: 2px solid  !important;}
.filter-tag {
  display: inline-block;
  padding: 3px 5px;
  border: 1px solid #949494;
  border-radius: 25px;
  margin-left: 10px;
  margin-bottom: 6px;
}

.filter-btn {
  .btn-outline-secondary {
    background-color: transparent;
    color: $theme-font-color;
  }

  .dropdown-menu {
    padding: 10px 0;
    min-width: 100%;
    transform: none !important;
  }

  .dropdown-item {
    padding: 0.25rem 0.75rem;
  }
}

.bdg {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-left: 5px;

  [class^="icon-"] {
    font-size: 0.65rem;
    line-height: 0.9rem;
  }

  @media only screen and (max-width: 1024px) {
    width: 19px;
    height: 19px;
  }

  @media only screen and (max-width: 475px) {
    width: 17px;
    height: 17px;
    margin-left: 0.25rem;

    [class^="icon-"] {
      font-size: 0.5rem;
      line-height: 0.7rem;
    }
  }
}

.bdg-small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;

  [class^="icon-"] {
    font-size: 0.65rem;
    line-height: 0.9rem;
  }
}

.bdg-big {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;

  [class^="icon-"] {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.fnt13 {
  font-size: 0.6875rem;
}

.fnt12 {
  font-size: 0.75rem;
}

.fnt13 {
  font-size: 0.95rem;
}

.fnt16 {
  font-size: 1rem;
}

.quntity {
  border: 1px solid #ccc;
  border-radius: 1px;
  font-weight: 700;
  min-width: 120px;
  display: inline-flex;

  .form-control {
    background: #e0e0e0;
    padding: 0;
    text-align: center;
    border-radius: 0;
    font-weight: 700;
    border: 0;
  }

  [class^="icon-"] {
    line-height: 2rem;
  }

  // @media only screen and (max-width: 960px) and (orientation:landscape){
  //     float: right;
  // }
  @media only screen and (max-width: 475px) {
    /* added to privent overlaping with total in mobile */
    // min-width: 6rem;
    margin-top: 0.8rem;

    .cursor_pointer {
      text-decoration: unset !important;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: progress;
  }
}

.approve-icon {
  font-size: 3rem;
  text-align: center;
  line-height: 3rem;

  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    line-height: 2rem;
  }

  @media only screen and (max-width: 475px) {
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 0.75rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.page-title {
  padding: 20px;
  margin: 0px -20px;
  position: relative;

  .page-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .page-title-heading,
  .page-title-subheading {
    margin: 0;
    padding: 0;
  }

  .page-title-heading {
    font-size: 1.75rem;
    font-weight: 700;
    display: flex;
    align-content: center;
    align-items: center;
    color: $theme-heading-color;
  }

  .page-title-subheading {
    padding: 3px 0 0;
    font-size: 0.88rem;
    opacity: 0.6;
  }

  .page-title-icon {
    font-size: 2rem;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 0.8333333333rem;
    margin: 0 30px 0 0;
    background: #fff;
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
      0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
    border-radius: 0.25rem;
    width: 60px;
    height: 60px;

    i {
      margin: auto;
    }
  }

  .rounded-circle {
    margin: 0 20px 0 0;
  }
}

.cta-btn {
  max-width: 152px;
  width: 100%;
  padding: 7px 20px 7px;

  .icon-cart {
    // margin-right: 1em;
    position: relative;

    .icon-plus {
      background: #000;
      position: absolute;
      border-radius: 50%;
      right: -6px;
      top: -5px;
      width: 12px;
      height: 12px;
      color: orange;
      font-size: 0.45rem;
      padding: 1px;
      border: 1px solid;
    }
  }

  @media only screen and (max-width: 1366px) {
    padding: 7px 17px 7px;
  }

  @media only screen and (max-width: 810px) and (orientation: landscape) {
    padding: 10px 15px 9px;

    .icon-cart {
      font-size: 1.2rem;
    }
  }

  @media only screen and (max-width: 740px) and (orientation: landscape) {
    padding: 10px 10px 9px;
  }

  @media only screen and (max-width: 667px) and (orientation: landscape) {
    padding: 10px 10px 9px;
  }

  @media only screen and (max-width: 475px) {
    margin-top: 10px;
    padding: 10px 17px 10px;
  }
}

.cta-btn-carousel {
  padding: 10px 20px 10px;

  .icon-cart {
    position: relative;

    .icon-plus {
      background: #000;
      position: absolute;
      border-radius: 50%;
      right: -6px;
      top: -5px;
      width: 12px;
      height: 12px;
      color: orange;
      font-size: 0.45rem;
      padding: 1px;
      border: 1px solid;
    }
  }

  @media only screen and (max-width: 1366px) {
    padding: 7px 17px 7px;
  }

  @media only screen and (max-width: 740px) {
    margin-top: -8px;
    padding: 7px 32px 7px;

    .icon-cart {
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 475px) {
    margin-top: 0px;
    padding: 10px 32px 7px;

    .icon-cart {
      font-size: 18px;
    }
  }
}

.cta-btn-margin {
  @media only screen and (max-width: 1024px) and (min-width: 992px) {
    margin-left: 85%;
  }
}

/* added text underline class*/
.text-underline {
  text-decoration: underline;
}

.navbar {
  padding: 0.2rem 1rem;
}

.list-product-title {
  font-size: 1rem;
  font-weight: 700;
  text-decoration: underline;

  @media only screen and (max-width: 475px) {
    max-width: 46%;
  }
}

.list-product-detail {
  @media only screen and (max-width: 475px) {
    max-width: 50%;
    padding-left: 1rem !important;
  }
}

.featureTag {
  background: #ffa500;
  padding: 1px 15px;
  position: absolute;
  left: 0;
  top: -12px;
  font-weight: 700;
  font-size: 0.95rem;
}

.product-title {
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 1rem;

  @media only screen and (max-width: 475px) {
    margin-bottom: 0.7rem;
  }
}

.total {
  font-size: 1.25rem;
  line-height: 1.25rem;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    font-size: 1rem;
    line-height: 1rem;

    .text-danger {
      font-size: 75%;
    }
  }

  @media only screen and (max-width: 475px) {
    .text-danger {
      font-size: 65%;
    }
  }
}

.product-pic {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  right: -20px;
  top: -20px;
  color: #fff;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: rotate(90deg);
    cursor: pointer;
  }
}

.product-details {
  .modal-body {
    padding: 2rem 1.5rem 1rem;
  }

  .title-wrapper {
    // border-bottom: 2px solid #ffa500;
  }

  .product-title {
    font-size: 1.8rem;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;
  }

  .product-size {
    font-size: 1.6rem;
    display: inline-block;
    margin-left: 1rem;
  }

  .product-type {
    font-size: 1.6rem;
    display: inline-block;
    margin-left: 1rem;
  }

  .product-detail-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 25px;
    right: auto;
    z-index: 1;

    &>.row>div:first-child,
    &>.row>div:nth-child(5) {
      @media only screen and (min-width: 1200px) {
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
      }
    }

    &>.row>div:first-child .danger {
      @media only screen and (min-width: 1200px) {
        position: absolute;
        left: 13px;
        top: 14px;
      }
    }

    &>.row>div:nth-child(5) {
      @media only screen and (max-width: 475px) {
        text-align: left !important;
      }
    }

    @media only screen and (max-width: 1080px) and (orientation: landscape) {
      // transform: translate(-50%, 0px);
    }

    @media only screen and (max-width: 810px) and (orientation: landscape) {
      width: 100%;
    }

    @media only screen and (max-width: 810px) {
      left: 0;
      width: 100%;
      max-width: 496px;
      // transform: translate(31.5%, 0px);
    }

    @media only screen and (max-width: 768px) {
      left: 0;
      width: 100%;
      max-width: 496px;
      // transform: translate(26.5%, 0px);
    }

    @media only screen and (max-width: 769px) and (orientation: landscape) {
      transform: translate(0);
      padding: 15px 40px 15px 15px;
      margin: 0 auto;
      left: 0;
      right: 0;

      div {
        &> :nth-child(5) {
          text-align: left !important;
          flex: 0 0 25%;
          max-width: 25%;
          margin: 0.5rem auto 0;
        }
      }
    }

    @media only screen and (max-width: 475px) {
      width: 100%;
      padding: 15px 15px 15px 15px;
      left: 0;
      transform: translate(0%, 0px);

      // .cursor_pointer{
      //     text-decoration: underline;
      // }
      &>.row>div:last-child>button {
        padding: 8px 25px 4px 25px;

        .icon-cart::before {
          font-size: 1.3rem;
        }

        // .icon-plus{
        //     top:-10px;
        // }
      }
    }

    .select-css-price {
      position: relative;
      // display: block;
      font-size: 17px;
      // color: #7d7d7d;
      line-height: 1.3;
      padding: 0rem 0.95rem 0 0;
      // width: 100%;
      // max-width: 70px;
      box-sizing: border-box;
      margin: 0 auto;
      border-radius: 0.25em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      // background-color: #d3d3d3;
      background-image: url("../../assets/dropdown-arrow.png");
      background-repeat: no-repeat, repeat;
      /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
      background-position: right 0em top 50%, 0 0;
      /* icon size, then gradient */
      background-size: 0.7em auto, 100%;
      border: 1px solid rgba(0, 0, 0, 0);

      option {
        font-weight: normal;
        color: #000;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  .product-body {
    display: flex;
    flex-wrap: wrap;

    .product-gallery {
      flex: 0 0 40%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;

      .product-img {
        max-width: 250px;
        flex: 0 0 80%;
        margin-right: 1rem;

        .imgwrapper {
          img {
            object-fit: contain;
          }
        }
      }

      ul {
        padding: 0;
        flex: 0 0 20%;
        list-style: none;

        li {
          border: 1px solid #ccc;
          position: relative;
          width: 60px;
          height: 60px;
          background: #fff;

          img {
            width: 90%;
            height: 90%;
            object-fit: contain;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        li+li {
          margin-top: 1rem;
        }
      }
    }

    .product-overview {
      flex: 0 0 60%;
      padding: 0 0rem 0 2rem;

      ul.features {
        list-style: none;
        padding: 0;
        margin-top: 1rem;

        li {
          display: inline-block;
          // border: 2px solid #949494;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          vertical-align: middle;
          position: relative;
          font-size: 1rem;
          color: #949494;
        }

        li:first-child {
          font-size: 3.2rem;
          margin-right: 10px;
          border: none;
          color: #000;
        }

        li+li {
          margin-left: 10px;
        }

        li {
          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          img {
            width: 80%;
            height: 80%;
            object-fit: contain;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      ul.eu-label {
        list-style: none;
        padding: 0;
        margin-top: 1rem;

        li {
          display: inline-block;
          vertical-align: top;
          position: relative;
          font-size: 0.95rem;
          color: #000;
          width: 18.2%;
        }

        li+li {
          margin-left: 10px;
        }
      }

      @media only screen and (max-width: 900px) {
        padding: 0 0rem 0 0rem;
      }
    }

    .other-details {
      padding-top: 15px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media only screen and (max-width: 900px) {
      .product-gallery {
        flex: 0 0 100%;
        flex-wrap: nowrap;

        .product-img {
          flex: 0 0 80%;
          max-width: 370px;

          .imgwrapper {
            margin: auto;
          }
        }

        ul {
          margin-bottom: 1rem;
          flex: 0 0 15%;
        }
      }

      .product-overview {
        flex: 0 0 100%;

        ul.eu-label {
          li {
            width: 18%;
          }

          li+li {
            margin-left: 10px;
          }
        }
      }
    }

    @media only screen and (max-width: 475px) {
      font-size: 13px;

      .product-gallery {
        flex: 0 0 100%;

        .product-img {
          flex: 0 0 76%;
          margin-right: 0.5rem;
        }

        ul {
          margin-bottom: 1rem;
          flex: 0 0 20%;
          max-width: 60px;
        }
      }

      .product-overview {
        flex: 0 0 100%;
        padding: 0;

        ul.features {
          li {
            width: 40px;
            height: 40px;
            font-size: 0.7rem;

            .h2 {
              font-size: 1.35rem;
            }
          }

          li:first-child {
            font-size: 2.2rem;
          }

          li+li {
            margin-left: 6px;
          }
        }

        ul.eu-label {
          li {
            width: 18%;
            font-size: 13px;

            img {
              width: 100% !important;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 375px) {
      .product-overview {
        ul.eu-label {
          li {
            width: 17%;
            font-size: 13px;

            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 475px) {
    .modal-close {
      right: 0px;
      top: -30px;
    }
  }

  @media only screen and (max-width: 768px) {
    .product-title {
      font-size: 1rem;
      margin-bottom: 0rem;
    }

    .product-size {
      font-size: 1rem;
      margin-left: 0rem;
      margin-bottom: 1rem;
    }

    .product-type {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .modal-body {
      padding: 1rem 1.5rem;

      @media only screen and (max-width: 376px) {
        padding: 1rem 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 475px) {
    margin: 2.5rem 0.5rem 9rem;

    .product-title {
      font-size: 1rem;
      margin-bottom: 0rem;
      display: block;
    }
  }
}

/*added a class to make align to details*/
.align-card-details {
  margin-right: 1%;
}

.complaint-file-c {
  // position: relative;
  top: -4.5rem;
  right: -1rem;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    position: absolute;
    top: -4.2rem;
    right: -0.6rem;
    padding: 0.4rem 0.8rem !important;
  }

  @media only screen and (max-width: 475px) {
    position: absolute;
    top: -4.2rem;
    right: -0.6rem;
    padding: 0.4rem 0.8rem !important;
  }
}

// .order-search.complaint{
//     @media only screen and (max-width: 475px){
//         top: 3.3rem;
//         left: 1rem;
//         width: 12em;
//     }
// }

.userCard.card .card-body .font-weight-bold.text-muted.col-xl-6,
.userCard.card .card-body .text-right.col-xl-6 {
  @media only screen and (min-device-width: 1025px) and (max-width: 1201px) and (min-width: 769px) {
    width: 50%;
  }
}

// .order-search.complaint + .row button.rounded-pill.mr-3.transparent-btn.complaint-file-c.btn.btn-secondary{
//     margin-top: -1rem;
// }

.header-order+.tab-content>.tab-pane.active .card {
  @media only screen and (max-width: 475px) {
    padding-left: 0.5rem;
  }
}

.header-order+.tab-content>.tab-pane.active .card-body>.row>div:nth-child(5) {
  @media only screen and (max-width: 475px) {
    //margin-left: -2.7em;
    padding-left: 0 !important;
  }
}

.header-order+.tab-content>.tab-pane.active .card-body>.row>.dropmenu-icon {
  @media only screen and (min-width: 768px) {
    order: 7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  @media only screen and (max-width: 768px) {
    display: unset !important;
    position: absolute;
    right: -5px;

    span {
      top: -8px;
      transform: rotateZ(90deg);
      position: absolute;
      right: 1rem;
      color: #00000073;
    }
  }

  @media only screen and (max-width: 475px) {
    display: unset !important;
    position: absolute;
    right: -5px;

    span {
      top: 5px;
      transform: rotateZ(90deg);
      position: absolute;
      right: 1rem;
      color: #00000073;
    }
  }
}

.product-page .product-list {
  @media only screen and (max-width: 1024px) {
    .quntity {
      margin-top: 0.85rem;
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    .card-body>.row {

      &> :nth-child(6),
      &> :nth-child(5) {
        padding: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .quntity {
      margin-top: 0.85rem;
    }
  }

  @media only screen and (max-width: 740px) and (orientation: landscape) {
    .card-body {
      padding: 1rem 0;
    }

    .card-body>.row {
      width: 100%;
      margin-right: 0;
      margin-left: 0;

      &> :nth-child(1) {
        max-width: 60%;
        flex: 0 0 60%;
        margin-bottom: 0.75rem;
      }

      &> :nth-child(3) {
        padding: 0;
        max-width: 40%;
        flex: 0 0 40%;
        margin-bottom: 0.75rem;
      }

      &> :nth-child(6) {
        margin-left: 1rem;
        margin-top: 1rem;
      }

      &> :nth-child(8) {
        max-width: 45%;
        padding: 0;
        margin-left: 1rem;
      }
    }

    .quntity {
      margin-top: 0rem;
    }
  }
}

.cart-modal {
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    .modal-body>.card-body>div:last-child>div:last-child {
      width: 12rem;
      margin: auto;
    }
  }
}

.complaint-card-mb {
  padding: 1.1rem 1rem !important;

  .complaint-card-grid {
    @media only screen and (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 0.5fr 0.5fr;
      grid-template-rows: auto;
      grid-template-areas: "b1 b2 b3";

      .b1 {
        grid-area: b1;

        .complaint_subject {
          max-width: 17rem;

          @media only screen and (max-width: 830px) {
            max-width: 15rem;
          }
        }
      }

      .b2 {
        grid-area: b2;
      }

      .b3 {
        grid-area: b3;
      }

      .b4 {
        grid-area: b4;
        color: gray;
        white-space: nowrap;
      }

      .b5 {
        grid-area: b5;

        .icon-more-v::before {
          display: block;
          transform: rotateZ(90deg);
        }
      }
    }

    @media only screen and (max-width: 830px) {
      grid-template-columns: 1fr 0.5fr 0.6fr;
    }

    @media only screen and (max-width: 960px) and (orientation: landscape) {
      &>.complaint_wrapper {
        max-width: 70%;
      }

      .b2 {
        padding: 0;
      }
    }

    @media only screen and (max-width: 475px) {
      grid-template-columns: 55% auto;
      grid-template-rows: auto auto;
      grid-template-areas: "b1 b3""b1 b2";
    }
  }

  &:hover {
    background: #f1f1f1;
  }
}

.menu-box {
  overflow-y: auto;
  width: 280px;
  background: url("../../assets/side-bg.png") no-repeat top;
  background-size: cover;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0px;
  right: -100%;
  color: #fff;
  padding: 2rem 0px;
  transition: ease-in 2s linear;

  &.active {
    right: 0;
  }

  &>h3:first-child {
    padding-right: 1rem;
  }

  .close {
    color: #fff;
    opacity: 1;
    font-size: 1rem;
    line-height: 1.9rem;
    float: none;
  }

  ul {
    margin: 0;
    padding: 0;
    font-size: 1rem;

    li {
      list-style: none;
      padding: 10px 2em;
      position: relative;

      &.has-submenu {
        &::before {
          display: inline-block;
          content: "";
          height: 10px;
          width: 10px;
          border-bottom: 2px solid currentColor;
          border-right: 2px solid currentColor;
          transform: rotate(-135deg);
          position: absolute;
          right: 10px;
          top: 20px;
          margin-right: 1em;
        }

        &.on::before {
          transform: rotate(45deg);
          top: 15px;
        }
      }

      ul {
        margin: 10px -2em 0;
        border-top: 2px solid #fff;

        li {
          padding-left: 4.2em;
        }

        &>li:last-child {
          border-bottom: 1px solid #ffffff85;
        }
      }
    }

    li+li {
      border-top: 1px solid #ffffff85;
    }
  }

  a {
    color: #fff;
  }
}

.cart-modal {
  margin-top: 2.5rem;

  .modal-close {
    right: 0px;
    top: -25px;
  }
}

.mrg-2 {
  margin-right: 2px;
}

.total-wrapper {
  flex: 20%;
  max-width: 20%;
}

.delete-icon {
  position: absolute;
  top: 25px;
  right: 10px;

  .btn-link {
    color: #616161;
  }

  @media only screen and (max-width: 1366px) {
    top: 35px;
  }

  @media only screen and (max-width: 475px) {
    top: 10px;
    right: 10px;

    .btn-link {
      color: #616161;
    }
  }
}

.shipment_detail-icon {
  font-size: 1rem;
  color: #949494;
  margin-top: 1.2rem;
  padding-top: 0.5rem;
  line-height: 1rem;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    margin-top: 0.75rem;
  }
}

.shipment_detail {
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  font-size: 1rem;
  // font-weight: 700;
  color: #949494;
  margin-top: 0.75rem;
  padding-top: 0.5rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      padding: 0px 10px;
      vertical-align: middle;
    }

    li+li {
      border-left: 1px solid #000;
    }
  }

  @media only screen and (max-width: 500px) {
    border-top: 0;
    margin-top: 0rem;
    padding-top: 0rem;
  }
}

.promocode_apply {
  .form-control {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.02);
  }

  .btn-secondary {
    background-color: #000;
  }
}

.modal-open {
  .modal {
    overflow-y: scroll !important;
  }
}

.product-details {
  .modal-body>.row:nth-child(1) {

    &>div:nth-child(1),
    &>div:nth-child(2) {
      @media only screen and (max-width: 475px) {
        width: fit-content;
      }
    }
  }

  .modal-body>.row:nth-child(3) {
    &>div:nth-child(1) {
      @media only screen and (max-width: 475px) {
        order: 2;
      }

      &>div.item-detail {
        @media only screen and (max-width: 960px) and (orientation: landscape) {
          display: grid;
          grid-template-columns: 30px 320px auto;
          grid-template-rows: auto auto;
          grid-template-areas: "b1 b2 b3""b1 b2 b4";
        }

        @media only screen and (max-width: 475px) {
          display: grid;
          grid-template-columns: 30px 240px auto;
          grid-template-rows: auto auto;
          grid-template-areas: "b1 b2 b3""b1 b2 b4";
        }

        @media only screen and (max-width: 375px) {
          display: grid;
          grid-template-columns: 30px 210px auto;
          grid-template-rows: auto auto;
          grid-template-areas: "b1 b2 b3""b1 b2 b4";
        }

        &>div:nth-child(1) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b1;
          }

          @media only screen and (max-width: 475px) {
            grid-area: b1;
          }
        }

        &>div:nth-child(2) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b2;
            padding-right: 10px !important;
          }

          @media only screen and (max-width: 475px) {
            grid-area: b2;
            padding-right: 10px !important;
          }
        }

        &>div:nth-child(3) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b3;
          }

          @media only screen and (max-width: 475px) {
            grid-area: b3;
          }
        }

        &>div:nth-child(4) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            grid-area: b4;
          }

          @media only screen and (max-width: 475px) {
            grid-area: b4;
          }
        }
      }

      &>div:nth-child(3),
      &>div:nth-child(4),
      &>div:nth-child(5) {
        &>div:nth-child(2) {
          @media only screen and (max-width: 960px) and (orientation: landscape) {
            text-align: left !important;
          }

          @media only screen and (max-width: 475px) {
            text-align: left !important;
          }
        }

        // & > div:nth-child(3){
        //     @media only screen and (max-width: 475px){
        //         display: none;
        //     }
        // }
        // & > div:nth-child(4){
        //     @media only screen and (max-width: 475px){
        //         width: fit-content;
        //     }
        // }
      }
    }

    &>div:nth-child(2) {
      @media only screen and (max-width: 475px) {
        order: 1;
        display: grid;
        grid-template-columns: 45% 55%;
        grid-template-rows: auto auto;
        grid-template-areas: "b1 b2""b1 b3";
      }

      &>div:nth-child(1) {
        @media only screen and (max-width: 475px) {
          grid-area: b1;
          padding-right: 10px;
        }
      }

      &>div:nth-child(2) {
        @media only screen and (max-width: 475px) {
          grid-area: b2;
        }
      }

      &>div:nth-child(3) {
        @media only screen and (max-width: 475px) {
          grid-area: b3;
        }
      }
    }
  }
}

.listTitleDeferred>.card-body>div>div {
  padding: 0;

  &>.text-muted:last-child {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1024px) {
  .product-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  // .w-100{ width: auto !important;}
}

@media only screen and (max-width: 767px) {
  .carousel-item {
    margin-top: 20px;
  }

  .navbar-nav {
    display: block;
    text-align: right;
  }

  .nav-item {
    display: inline-block;
  }

  // .col-sm-6 {
  //     flex: 0 0 100%;
  //     max-width: 100%;
  // }
  // .product-details{
  //     margin: 2.5rem 1.2rem 5rem;
  //     .product-title{
  //         font-size: 1rem;
  //         margin-bottom: 0rem;
  //     }
  //     .product-size{
  //         font-size: 1rem;
  //         margin-left: 0rem;
  //         margin-bottom: 1rem;
  //     }
  //     .product-type{
  //         font-size: 1rem;
  //         margin-bottom: 1rem;
  //     }
  //     .modal-body{padding: 1rem;}
  // }
  // .filter-on{
  //     &.container{width: 100%; margin-left:0px; padding-right: 30px; }
  // }
}

.changepassword {
  max-width: 320px;
  margin: 5rem auto;

  .modal-body {
    padding: 3rem 2.5rem 3rem;

    .rounded-pill {
      padding: 0.6em;
    }
  }

  .btn-link {
    color: #000;
  }

  .form-group {
    position: relative;
    margin-bottom: 1.5rem;

    input {
      padding-top: 0.4em;
      padding-bottom: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 2em #f6f6f6 inset;
      box-shadow: 0 0 0px 2em #f6f6f6 inset;
    }
  }

  .form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
  }

  .form-control:focus+.form-control-placeholder,
  .form-control:valid+.form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -1em, 0);
    opacity: 1;
    padding-left: 1em;
    padding-top: 0;
    margin-bottom: 0;
    top: 0.5em;
    //border-color: #9C9D9D;
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    background-color: rgb(246, 246, 246);

    &:focus {
      box-shadow: none;
    }
  }

  .showpassword {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 20px;
  }

  label {
    width: 100%;
    background: #f6f6f6;
  }

  @media only screen and (max-width: 475px) {
    margin-top: 11rem;

    .modal-close {
      right: 0px;
      top: -22px;
    }
  }
}

.TrackOrder {
  @media only screen and (max-width: 475px) {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-template-areas: "b2""b1";

    .b1 {
      grid-area: b1;
    }

    .b2 {
      grid-area: b2;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      grid-template-areas: "b1 b2""b1 b3";

      .b2-1 {
        grid-area: b1;
      }

      .b2-2 {
        grid-area: b2;
      }

      .b2-3 {
        grid-area: b3;
      }
    }
  }
}

.homeslider {
  .slick-slide {
    padding: 10px;

    // margin-right: 30px;
    .card-body {
      min-height: auto;
    }
  }
}

.slick-slide {
  // margin-right: 30px;
  padding: 0 15px;
  box-sizing: border-box;

  .card-body {
    min-height: 176px;

    @media only screen and (max-width: 475px) {
      padding: 15px;
    }
  }

  @media only screen and (max-width: 768px) {
    // margin-right: 5px;
  }

  @media only screen and (max-width: 475px) {
    padding: 15px 0;
  }
}

.slick-list {
  overflow-x: hidden;
  overflow-y: visible;
  height: auto !important;
}

.slick-track {
  @media only screen and (max-width: 810px) {
    margin-top: 1.2rem;
  }
}

#featureProductSliderProductPage {
  .slick-slide {
    //  margin-right: 30px;
    padding: 0 0px;
    box-sizing: border-box;

    .card-body {
      min-height: 190px;

      @media only screen and (max-width: 475px) {
        padding: 20px 10px 20px 20px;

        /*Adding this to fix alignment*/
        &>.row>div:nth-child(2)>.row>div:first-child {
          margin-left: 0.2em;
        }

        &>div:last-child>div {
          margin-top: 0.7rem !important;

          .icon-cart {
            font-size: 1.5rem;
          }
        }

        .approve-icon {
          margin-top: 0;
        }

        .stock-txt {
          line-height: 1.5rem;
        }

        .bdg {
          display: block;
          margin: 3px auto 0;
        }
      }

      @media only screen and (max-width: 360px) {
        .stock-txt {
          line-height: 1.2rem;
        }

        .bdg {
          display: block;
          margin: 0px auto 0;
        }
      }
    }

    @media only screen and (max-width: 960px) and (orientation: landscape) {
      margin-top: 15px;
      padding: 0 10px 0 0;
      margin-left: -2px;
    }

    @media only screen and (max-width: 810px) {
      padding: 0 10px 0 0;
      margin-left: -2px;
    }

    @media only screen and (max-width: 767px) {
      margin-top: 15px;
      padding: 0 10px 0 0;
      margin-left: -2px;
    }

    @media only screen and (max-width: 475px) {
      margin-top: 15px;
      padding: 0 10px 0 0;
      margin-left: -2px;
    }
  }

  +.row .card-body>.row {
    @media only screen and (max-width: 475px) {
      &>div:nth-child(3) {
        align-self: start;
        padding-left: 0rem;
      }

      &>div:nth-child(4) {
        margin-left: 1em;
      }

      &>div:nth-child(7) {
        white-space: nowrap;
        padding-left: 0px;
        margin-left: -1rem;
        margin-top: -1.5rem;
        text-align: left !important;
      }

      &>div:last-child {
        text-align: left !important;

        button {
          padding-top: 0.7em;
          padding-bottom: 0.6em;
          margin-left: 1em;

          .icon-cart {
            font-size: 1.5em;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 475px) {
    margin-bottom: 15px;
  }
}

.container>.row:nth-child(5) span.d-none.d-md-inline-block.d-lg-none.d-xl-inline-block {
  @media only screen and (max-width: 475px) {
    display: unset !important;
  }
}

.container>h3.mb-2.font-weight-bold,
.container>h3.mb-2.font-weight-bold+p {
  @media only screen and (max-width: 475px) {
    margin-bottom: 0 !important;
  }
}

.order-search+.order-tab.no-gutters.row .filter-btn,
.order-search+.order-tab.no-gutters.row .rounded-pill {
  @media only screen and (max-width: 475px) {
    white-space: nowrap;
  }
}

.order-search+.no-gutters.border-bottom.border-secondary.pb-2.my-3.align-items-center.row {
  @media only screen and (max-width: 475px) {
    padding-bottom: 1rem !important;
  }

  &>div:first-child {
    text-transform: lowercase;

    @media only screen and (max-width: 475px) {
      margin-top: -1em;
      margin-bottom: 0em !important;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

// .col-3.order-4.col-md-2.order-md-3{
//     @media only screen and (max-width: 475px){
//         padding-left: .6rem !important;
//     }
// }

.order-search.stock_search {
  &+.no-gutters.row {
    &>div:nth-child(2) {
      padding-left: 1rem;
    }
  }
}

.order-search+.order-tab>div:last-child .sortby button {
  @media only screen and (max-width: 455px) {
    padding-left: 0 !important;
    min-width: unset !important;

    span {
      padding-left: 0.5rem;
      padding-right: 1.5rem;
    }
  }
}

.order-search~.tab-content {
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    .card-body>div {
      &>div:nth-child(3) {
        text-align: left !important;
      }

      &>div:nth-child(4) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      &>div:nth-child(5) {
        text-align: left !important;
        margin-left: 0 !important;
      }

      &>div:nth-child(6) {
        font-size: 1rem;
      }
    }
  }
}

.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;

  &::before {
    font-size: 40px !important;
  }
}

.slick-prev {
  left: -55px !important;
  z-index: 1;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    left: -21px !important;
  }

  @media only screen and (max-width: 769px) {
    left: -20px !important;
  }

  @media only screen and (max-width: 475px) {
    left: -21px !important;
  }
}

.slick-next {
  right: -55px !important;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    right: -21px !important;
  }

  @media only screen and (max-width: 769px) {
    right: -20px !important;
  }

  @media only screen and (max-width: 475px) {
    right: -21px !important;
  }
}

// .feature-product{
//     .slick-slide{
//         margin-right:10px;
//     }
// }
.dark-btn {
  color: #000;
  border-color: #000;
  line-height: 1rem;
}

.slick-prev:before,
.slick-next:before {
  @media only screen and (max-width: 475px) {
    color: #cacaca;
  }
}

.slick-next:focus,
.slick-prev:focus {
  outline: unset;
}

.recentView-1 {
  .counter-align {
    right: unset;
    top: unset;
    left: 0px;
    bottom: 10px;
  }

  .cta-btn {
    @media only screen and (max-width: 667px) and (orientation: landscape) {
      margin-left: 5px;
    }
  }

  .select-css-price {
    position: relative;
    // display: block;
    font-size: 17px;
    // color: #7d7d7d;
    line-height: 1.3;
    padding: 0rem 1rem 0rem 0.25rem;
    // width: 100%;
    // max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    // background-color: #d3d3d3;
    background-image: url("../../assets/dropdown-arrow.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 0);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

.recent-view-counter {
  position: absolute;
  left: 15px;
  bottom: 10px;

  @media only screen and (max-width: 1024px) {
    max-width: 40%;
  }

  @media only screen and (max-width: 475px) {
    max-width: 45%;
  }
}

.recent_view_cta {
  position: absolute;
  right: 15px;
  bottom: 10px;
  // @media only screen and (max-width: 475px){
  //     position: relative;
  // }
}

.feature-products {
  @media only screen and (max-width: 1024px) {
    .card {
      max-height: fit-content;
    }

    .card-body {
      min-height: 230px;

      .counter-align {
        top: 100%;
      }

      .featur_btn {
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
  }

  .select-css-price {
    position: relative;
    // display: block;
    font-size: 17px;
    // color: #7d7d7d;
    line-height: 1.3;
    padding: 0rem 0.95rem 0 0;
    // width: 100%;
    // max-width: 75px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0.25em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    // background-color: #d3d3d3;
    background-image: url("../../assets/dropdown-arrow.png");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.7em auto, 100%;
    border: 1px solid rgba(0, 0, 0, 0);

    option {
      font-weight: normal;
      color: #000;
    }

    &::-ms-expand {
      display: none;
    }
  }
}

// add ditional
.logo-wrapper {
  width: 100%;
  max-width: 150px;

  @media(max-width:380px) {
    max-width: 100px;
  }
}

.main-logo {
  width: 100%;

}

.main-logo,
.navbar-brand {
  img {
    max-height: 38px;
    width: 100%;
  }
}

.navbar-nav {
  .login-link.active {
    font-weight: bold;
  }

  li.nav-item.dropdown {
    >.dropdown-menu {
      .dropdown-item {
        h5 {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }
  }
}

.navbar-nav .nav-link,
.navbar-nav .nav-link a {
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
  font-weight: bold;

  &:hover {
    //  font-weight: bold;
    color: rgba(0, 0, 0, 1);

    .nav-icon,
    .nav-txt {
      color: #000;
    }
  }

  .nav-txt {
    color: #9c9d9d;
  }

  .nav-icon {
    color: #9c9d9d;
  }

  .nav.text:hover~.nav-icon {
    color: #000;
  }
}

.footer-carousel__cart {
  .slick-slide {
    padding: 20px 15px;

    .recentView-1 {
      margin-bottom: 0;

      .card-body {
        padding: 15px 15px 20px;
        gap: 15px;
      }
    }
  }
}

.navWapperWithBrand {
  display: flex;
  width: 100%;
  align-items: center;
  // max-width: 1400px;
  margin: auto;

  .navbar-nav .nav-link {
    color: #000;
  }

  >div:nth-child(2) {
    .lang.select-css-logout {
      border: unset;
      background-color: transparent;
      color: #000;
    }
  }

  // .navbar-brand{margin-left: 280px;}
  @media screen and (max-width: 1170px) {
    display: flex;
  }
}

.scrolledHeader {
  .logo-conti {
    height: 70px;

    img {
      transform: scale(0.8);
      margin-bottom: 1rem;
    }
  }
}

.navWapper {
  display: flex;
  width: 100%;
  align-items: center;
  // max-width: 1400px;
  margin: auto;

  .navbar-nav .nav-link {
    color: #000;
  }

  // >div:nth-child(1){
  //     flex-basis: 18%;
  // }
  >div:nth-child(2) {

    // display: inline-block;
    // flex-basis: 100px;
    .lang.select-css-logout {
      border: unset;
      background-color: transparent;
      color: #000;
    }
  }

  // .navbar-brand{margin-left: 280px;}
  @media screen and (max-width: 1170px) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .bg-landing-page {
    padding: 0.675rem 1rem;
    display: flex;
  }

  .home-title {
    max-width: 469px;
    font-size: 1.75rem;
  }

  .navbar-light {
    backface-visibility: hidden;

    .navbar-nav .nav-link,
    .navbar-nav .nav-link a {
      color: rgba(0, 0, 0, 1);

      &:hover {
        font-weight: bold;
        color: rgba(0, 0, 0, 1);

        .nav-icon,
        .nav-txt {
          // color: #000;
        }
      }

      .nav.text:hover~.nav-icon {
        // color: #000;
      }
    }

    .navbar-nav .nav-link,
    .nav-txt {


      &::after {
        content: attr(data-text);
        content: attr(data-text) / "";
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: bold;
      }

      @media speech {
        display: none;
      }
    }

    .form-control:focus {
      border-color: #9c9d9d;
    }
  }

  .form-control {
    &:focus {
      box-shadow: unset;
      outline: unset;
    }
  }

  // footer cart page
  .footer-carousel__cart {
    .card-body {
      display: flex;
      flex-direction: column;

      // >div.row:nth-child(3){
      //     // margin-top:auto;
      //     // padding-left: 10px;
      // }
      >div.row:nth-child(2) {
        >div:nth-child(3) {
          flex-wrap: wrap;
        }
      }
    }

    button {
      font-size: 13px;
    }
  }

  .logo-conti {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: -11px;
    left: 0;
    min-width: 260px;
    height: 156px;
    background-color: #ffa500;
    -webkit-transition: 0.3s ease height;
    transition: 0.3s ease height;

    img {
      -webkit-transition: 0.3s ease width;
      transition: 0.3s ease width;
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    @media (max-width: 455px) {
      .search-btn-mob {
        right: 0;
        //left:-20px;
      }
    }

    ul.navbar-nav {
      display: flex;
      flex-direction: row;
    }
  }
}

//  order pop up shiping page
.shiping-address {
  width: 100%;
  margin-left: unset;
  margin-right: unset;

  >div {
    display: flex !important;
    align-items: baseline;
    width: 100%;

    @media (max-width: 769px) {
      order: 1 !important;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    >label {
      flex-basis: 100px;

      @media (min-width: 400px) {
        flex-basis: 120px;
      }
    }

    >input {
      flex: 1;
    }

    &:nth-child(2) {
      >label {
        @media (min-width: 769px) {
          flex-basis: 120px;
        }
      }
    }
  }
}

.notification-setting {
  .card-body {
    >div {
      >div {
        label {
          position: relative;
          z-index: 0;
        }
      }
    }
  }
}

// cart page
.filter-cart-page {
  @media (max-width: 768px) {
    align-items: flex-end;
    margin-top: -1rem;

    >div {
      font-size: 14px;
    }
  }

  .cart-search-input-mobile {
    margin-top: 0.5rem;
    display: inline-block;
    width: 190px;

    @media (min-width: 769px) {
      display: none;
    }
  }
}

.cart-search-input-desktop {
  display: none;

  @media (min-width: 769px) {
    display: inline-block;
  }
}

.footer-fixed__mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: #fff;
  padding: 10px;

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    .row>div:last-child {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  >div.row {
    >div:first-child {
      padding-right: 12px !important;
    }
  }
}

.dates_wrapper {
  vertical-align: middle;
  text-align: right;
  line-height: 1.5rem;

  .dates {
    display: inline-block;
    vertical-align: middle;
  }

  .dropmenu_icon {
    display: inline-block;
    margin-left: 2rem;
    vertical-align: middle;

    .icon-more-v {
      display: block;
      margin-top: 0.15rem;
    }

    @media only screen and (max-width: 475px) {
      margin-left: 0.5rem;
    }
  }

  @media only screen and (max-width: 475px) {
    text-align: left;
  }
}

.complaint_wrapper {
  line-height: 1.5rem;

  .complaint_id {
    display: inline-block;
    vertical-align: middle;

    @media only screen and (max-width: 475px) {
      display: block;
    }
  }

  .complaint_subject {
    display: inline-block;
    margin-left: 2rem;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;

    @media only screen and (max-width: 475px) {
      margin-left: 0rem;
      max-width: 150px;
      display: block;
    }
  }
}

.searchResult {
  .card-body>.row>div:nth-child(5) {
    @media only screen and (max-width: 768px) {
      margin-left: -2.7em;
    }
  }

  .card-body>.row>.dropmenu-icon {
    @media only screen and (max-width: 768px) {
      display: unset !important;
      position: absolute;

      span {
        top: -8px;
        transform: rotateZ(90deg);
        position: absolute;
        right: 1rem;
        color: #00000073;
      }
    }
  }

  @media only screen and (max-width: 475px) {
    margin-top: 4rem;
  }
}

.userCart {
  .card-body {
    padding: 20px;
  }

  .z-index-1 {
    @media only screen and (max-width: 960px) {
      z-index: unset;
    }
  }
}

.value-wrapper {
  font-size: 1rem;
  line-height: 1rem;
}

.text-danger {
  &.text-nowrap {
    @media only screen and (max-width: 475px) {
      font-size: 80%;
    }

    @media only screen and (max-width: 360px) {
      font-size: 90%;
    }
  }
}

.cart-header {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr 1fr 1fr auto;
  grid-template-areas: "g1 g2 . . .""g3 g3 g3 g4 g5 ";
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #6c757d;
  margin-bottom: 1rem;

  h3 {
    width: max-content;
  }

  .order-search {
    position: relative;
    width: 12rem;
    top: unset;
    left: unset;

    .btn-link {
      right: -1rem;
    }
  }

  .ch-1 {
    grid-area: g1;
    align-self: center;
  }

  .ch-2 {
    grid-area: g2;
  }

  .ch-3 {
    grid-area: g3;
    align-self: center;
    margin: 0.5rem 0;
  }

  .ch-4 {
    grid-area: g4;
    justify-self: end;
    align-self: end;
  }

  .ch-5 {
    grid-area: g5;
    justify-self: end;
    align-self: center;
  }

  // @media only screen and (max-width: 810px) and (orientation:landscape){
  //     .order-search{
  //         left: 52%;
  //     }
  // }

  @media only screen and (max-width: 700px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4""g3 g4""g2 g5";

    .ch-2 {
      width: inherit;
      max-width: 20rem;
      margin-right: 0.5rem;
      margin-top: 0.5rem;

      input {
        margin-left: 0 !important;
      }

      .btn-link {
        right: 0;
      }
    }

    .ch-4 {
      justify-self: end;
      align-self: start;
      width: 100%;

      button {
        width: 100%;
        margin-right: 0 !important;
      }
    }

    .ch-5 {
      align-self: end;
    }
  }
}

.cart-header-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4""g3 g4""g2 g5";

    .ch-2 {
      margin-left: -1rem;
    }

    .ch-4 {
      margin-right: -1rem;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    &>.order-search {
      left: 1rem !important;
      max-width: 190px;
    }

    &>.ch-4 {
      margin-right: unset;
    }
  }
}

.complaint-card-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    &>div:nth-child(2) {
      margin-left: -2rem;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    grid-template-columns: 55% auto !important;
    grid-template-rows: auto auto !important;
    grid-template-areas: "b1 b3""b1 b2" !important;

    .complaint_id {
      width: 100%;
    }

    .complaint_subject {
      margin: unset;
    }

    .b2 {
      margin-left: 1rem !important;
    }
  }
}

.header-order {
  grid-template-rows: auto auto auto;
  grid-template-areas: "g1 g2 . . .""g3 g3 g3 . . ""g6 g6 g6 g4 g5";

  .nav.nav-tabs {
    grid-area: g6;
    font-size: 1.2rem;
    margin: 0 0 -0.5rem;

    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      cursor: pointer;
    }

    .nav-link.active {
      background-color: transparent;
      font-weight: bold;
      color: #000;
      border-bottom: 2px solid #000;
      cursor: pointer;
    }
  }

  h4 {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 769px) and (min-width: 540px) {
    grid-template-areas: "g1 g2 . . .""g3 g3 g3 g4 g5""g6 g6 g6 g6 g6";
  }

  @media only screen and (max-width: 700px) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4""g3 g4""g2 g5""g6 g6";

    .nav-tabs {
      margin-top: 0;
    }
  }
}

.order-accordion {
  .accordion__panel {
    padding: 0;
  }

  .dropmenu-icon {
    display: unset !important;
    position: absolute;
    right: -5px;

    span {
      top: 5px;
      transform: rotateZ(90deg);
      position: absolute;
      right: 1rem;
      color: #00000073;
    }
  }

  .card-body>.row>div:nth-child(5) {
    padding-left: 0 !important;
  }
}

.header-order-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas: "g1 g4""g3 g4""g2 g5""g6 g6";

    .nav-tabs {
      margin-top: 0;
    }

    .ch-2 {
      margin-left: -1rem;
    }

    .ch-4 {
      margin-right: -1rem;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    &>.order-search {
      left: 1rem !important;
      max-width: 190px;
    }

    &>.ch-4 {
      margin-right: unset;
    }
  }
}

.order-card-collapse {
  @media only screen and (max-width: 769px) and (min-width: 540px) {
    &>div:nth-child(2) {
      white-space: nowrap;
    }

    &>div:nth-child(4) {
      white-space: nowrap;
    }

    &>div:nth-child(6) {
      white-space: nowrap;
    }
  }
}

.feature-product-grid {
  display: grid;
  grid-template-columns: auto auto auto auto 160px;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "a2 a3 a3 a3 a3"
    "a2 a4 a5 a7 a9"
    "a2 a4 a6 a8 a12"
    "a2 a10 a10 a11 a11";

  .ga-1 {
    grid-area: a1;
  }

  .ga-2 {
    grid-area: a2;

    .product-pic {
      width: 9rem;
    }
  }

  .ga-3 {
    grid-area: a3;
  }

  .ga-4 {
    grid-area: a4;
    align-self: center;

    .approve-icon {
      text-align: left;
    }
  }

  .ga-5 {
    grid-area: a5;
  }

  .ga-6 {
    grid-area: a6;
  }

  .ga-7 {
    grid-area: a7;
  }

  .ga-8 {
    grid-area: a8;
    margin-right: 5px;
    // .c-dirham{
    //     margin-left: 5px;
    // }
  }

  .ga-9 {
    grid-area: a9;
  }

  .ga-10 {
    grid-area: a10;
    padding: 0;
    margin-top: 1rem;
  }

  .ga-11 {
    grid-area: a11;
    align-self: end;
    justify-self: right;
    margin-top: 1rem;
    margin-right: 1rem;

    .icon-cart {
      margin-right: 8px;
    }
  }

  .ga-12 {
    grid-area: a12;
    min-height: 50px;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: auto auto auto auto 0.6fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "a2 a3 a3 a3 a3"
      "a2 a4 a5 a7 a9"
      "a2 a4 a6 a8 a12"
      "a10 a10 . a11 a11";
    min-height: fit-content !important;

    .ga-2 {
      .product-pic {
        width: 7rem;
      }
    }

    .ga-4 {
      align-self: start;
      margin-top: 5px;

      img {
        width: 3rem;
      }
    }

    .ga-7 {
      align-self: center;
    }

    .ga-5,
    .ga-9 {
      align-self: end;
    }

    .ga-10 {
      height: fit-content;
      align-self: end;
    }

    .ga-10,
    .ga-11 {
      margin-top: 0;
    }

    .ga-8 {
      &>span:first-child {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 810px) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas:
      "a2 a3 a3 a3 a3"
      "a2 a4 a5 a5 a5"
      "a2 a4 a6 a6 a6"
      "a2 a7 a8 a9 a9"
      "a2 a7 a8 a12 a12"
      "a10 a10 a11 a11 a11";
    padding: 20px 2px 20px 20px !important;

    .ga-2 {
      .product-pic {
        width: 7rem;
      }
    }

    .ga-4 {
      align-self: start;
      margin-top: 5px;

      img {
        width: 3rem;
      }
    }

    .ga-5,
    .ga-9 {
      align-self: end;
    }

    .ga-10 {
      height: fit-content;
      align-self: end;
    }

    .ga-8 {
      &>span:first-child {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas:
      "a2 a3 a3 a3 a3"
      "a2 a4 a5 a5 a5"
      "a2 a4 a6 a6 a6"
      "a2 a7 a8 a9 a9"
      "a2 a7 a8 a12 a12"
      "a10 a10 a11 a11 a11";
    padding: 20px 2px 20px 2px !important;

    .ga-2 {
      .product-pic {
        width: 6.5rem;
        margin-left: 0px;
      }
    }

    .ga-4 {
      img {
        width: 2rem;
      }
    }

    .ga-7,
    .ga-9 {
      align-self: start;
    }

    .ga-7 {
      display: flex !important;
      flex-direction: column;

      &>span:last-child {
        margin: 0.3rem 0 0 0.4rem !important;
      }
    }

    .ga-12 {
      align-self: end;
      margin-top: 4px;
    }

    .ga-10 {
      .quntity {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }

  @media only screen and (max-width: 667px) and (orientation: landscape) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas:
      "a2 a3 a3 a3 a3"
      "a2 a4 a5 a5 a5"
      "a2 a4 a6 a6 a6"
      "a2 a7 a8 a9 a9"
      "a2 a7 a8 a12 a12"
      "a10 a10 a11 a11 a11";
    padding: 20px 2px 20px 2px !important;

    .ga-11 {
      .cta-btn {
        padding: 10px 25px 10px;
      }
    }
  }

  @media only screen and (max-width: 475px) {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto;
    grid-template-areas:
      "a2 a3 a3 a3 a3"
      "a2 a4 a5 a5 a5"
      "a2 a4 a6 a6 a6"
      "a2 a7 a8 a9 a9"
      "a2 a7 a8 a12 a12"
      "a10 a10 a11 a11 a11";
    padding: 20px 2px 20px 2px !important;

    .ga-2 {
      .product-pic {
        width: 6.5rem;
        margin-left: 0px;
      }
    }

    .ga-4 {
      img {
        width: 2rem;
      }
    }

    .ga-7,
    .ga-9 {
      align-self: start;
    }

    .ga-7 {
      display: flex !important;
      flex-direction: column;

      &>span:last-child {
        margin: 0.3rem 0 0 0.4rem !important;
      }
    }

    .ga-12 {
      align-self: end;
      margin-top: 4px;
      min-height: 40px;
    }

    .ga-10 {
      .quntity {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
}

.list-grid {
  align-items: center;
  display: grid;
  grid-template-columns: 18% 5% 17% 10% 12% 11% 17% 10%;
  grid-template-rows: auto;
  grid-template-areas: "a1 a2 a3 a4 a5 a6 a7 a8";

  .ga-3 {
    padding-left: 5%;
  }

  @media only screen and (max-width: 1440px) {
    grid-template-columns: 18% 5% 17% 8% 13% 10% 16% 13%;
  }

  @media only screen and (max-width: 1366px) {
    grid-template-columns: 18% 5% 17% 8% 13% 10% 16% 13%;
  }
}

.header-order .header-order+.tab-content {
  .card-body>.row {
    @media only screen and (max-width: 960px) and (orientation: landscape) {
      &>div:nth-child(3) {
        text-align: start !important;
      }

      &>div:nth-child(4) {
        padding-right: 0;
        white-space: nowrap;
      }

      &>div:nth-child(5) {
        text-align: start !important;
        padding-left: 0.5rem !important;
        white-space: normal;
        margin-left: 0;
      }
    }
  }
}

.buttonList {
  margin-top: 1rem;
  margin-left: 1rem;

  li {
    display: inline-block;
    list-style: none;

    button {
      padding: 5px 25px;
    }

    .btn-radio {
      span {
        color: #333;
      }
    }
  }

  li+li {
    margin-left: 30px;
  }
}

.navbar {
  .dropdownLayer {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    top: 110px;
    right: 0;
    bottom: 0;
    z-index: 1024;
  }
}

.callofflist {
  align-items: center;
  display: grid;
  grid-template-columns: 250px auto auto;
  grid-template-rows: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;

  .delete-icon {
    position: relative;
    right: 0;
    top: 0;
  }

  @media only screen and (max-width: 1280px) {
    grid-template-columns: 170px auto auto;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 150px auto auto;
  }

  // &+.callofflist{
  //     padding-top: 0.75rem;
  //     margin-top: 0.75rem;
  //     border-top: 1px solid rgba(0, 0, 0, 0.3);
  // }
}

.pagination {
  align-self: flex-end;
}

.top_search_box {
  @media only screen and (max-width: 1280px) {
    max-width: 35% !important;
  }

  @media only screen and (max-width: 1024px) and (min-width: 769px) {
    margin-left: 1rem;
  }
}

.bg-none {
  background-color: transparent !important;
  box-shadow: none;
}

.dropdownLayer {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1024;
}

.tire-tips-safety,
.tire-Maintenance,
.sale-made-in-germany,
.noOneInOE-inner-page,
.test-winner-inner-page {

  p>img[style="float:left"],
  div[style="float:left"] {
    margin-right: 1rem;
    width: 90%;
    margin-bottom: 1rem;

    @media only screen and (max-width: 960px) {
      margin-left: 5%;
      margin-right: 5%;
    }

    @media only screen and (min-width: 960px) {
      width: 40%;
    }
  }

  p>img[style="float:right"],
  div[style="float:right"] {
    margin-left: 1rem;
    width: 90%;
    margin-bottom: 1rem;

    @media only screen and (max-width: 960px) {
      margin-left: 5%;
      margin-right: 5%;
    }

    @media only screen and (min-width: 960px) {
      width: 40%;
    }
  }
}

.test-winner-inner-page+video {
  @media only screen and (max-width: 960px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media only screen and (min-width: 960px) {
    width: 50%;
    float: right;
  }
}

.sale-made-in-germany-list {
  ul {
    list-style-type: disc;
    padding-left: 1rem;
  }

  @media only screen and (min-width: 960px) {
    &>div:nth-child(1) {
      flex: 0 0 38.666667%;
    }

    &>div:nth-child(2) {
      flex: 0 0 28%;
      max-width: 28%;
    }
  }
}

.tire-technology-tabel {
  margin-top: 1rem;

  div {
    padding-bottom: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: #ffa500;
    border-radius: 50%;
  }

  & tr>td:nth-child(2) {
    text-align: left;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
}

.promoValidate {
  background: #000;
  padding: 10px 15px;

  h4 {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
  }
}

.carousel {
  &.slide {
    margin-top: -20px;
  }
}

.page-link {
  color: #000000 !important;
}

.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.suggestions {
  z-index: 100;
  border-radius: 16px;
  position: absolute;
  left: 5%;
  border: 1px solid #F1EDE3;
  background: #fff;
  width: 87%;

  li {
    padding: 5px 10px;
  }

  li+li {
    border-top: 1px solid #F1EDE3;
  }
}