.feature-products {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;


}



.card-feature {
    position: relative;
    border: 1px solid #F1EDE3;
    border-radius: 16px;
    background-color: #FFFFFF;
    padding: 16px;
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: 300px;


    @include media(sxm) {
        max-width: 400px;
    }

    @include media(md) {
        max-width: 500px;
    }

    @include media(xl) {
        max-width: 544px;
    }

    .image-box {
        position: absolute;
        width: 50px;
        height: 50px;

        @include media(md) {
            position: static;
            width: 168px;
            height: unset;
        }

        border-radius: 5px;
        background-color: #FBF7ED;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            height: 40px;

            object-fit: contain;

            @include media(md) {

                height: 139px;
            }
        }
    }

    .card-detail {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;

        .card-title {
            margin-left: 66px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #141005;
            display: inline-flex;
            gap: 8px;
            margin-bottom: 3px;
            cursor: pointer;
            // margin-right: 50px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;

            @include media(md) {
                margin-left: unset;
            }

            @include media(md) {
                font-size: 24px;
                line-height: 29px;
            }

            .certificate {
                display: inline-block;
                width: 30px;
                height: 30px;

                position: absolute;
                top: 0;
                right: 0;


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .oeKey {
                display: inline-block;
                position: relative;
                top: -4px;
                border: 1px solid #ddd;
                font-weight: normal;
                padding: 2px 6px;
                font-size: 14px;
                margin-left: 5px;
                line-height: 20px;
            }
        }

        .size {
            margin-left: 66px;

            @include media(md) {
                margin-left: unset;
            }

            display: flex;
            align-items: center;

            .size-description {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #8C887F;

                @include media(xl) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            .segment {
                margin-left: 15px;
                position: relative;


                .icon-segment {
                    display: inline-flex;
                    width: 24px;
                    height: 24px;
                    align-items: center;

                    img {
                        width: 100%;
                    }

                    &::before {
                        position: absolute;
                        left: -7.5px;
                        top: 50%;
                        transform: translateY(-50%);
                        display: inline-block;
                        content: "";
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background-color: #FAB005;

                    }
                }

                .certificate {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 5px;
                    img {
                        display: block;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .store-box {
            display: flex;
            margin-top: 16px;
            margin-bottom: 16px;

            .item-2 {
                display: flex;
                flex-direction: column;
                flex: 1;

                label {
                    font-weight: 500;

                    color: #8C887F;
                    margin-bottom: unset;

                    font-size: 14px;
                    line-height: 16px;

                }

                .price {
                    color: #333029;
                }

                &>span {
                    font-weight: 500;
                    margin-top: 4px;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: capitalize;

                    @include media(md) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }



            }
        }

        .footer-action {
            // margin-top: 16px;
            display: flex;
            justify-content: space-between;

            margin-top: auto;

            button.btn-action {
                .btn-action-text {
                    // display: none;
                    // @include media(sm){
                    //     display: inline;
                    // }
                }
            }
        }
    }
}


html[dir=rtl] {
    .card-feature {
        .card-detail {
            .card-title {
                margin-left: 50px;
                margin-right: 66px;

                @include media(md) {
                    margin-right: unset;
                }

                .certificate {
                    right: unset;
                    left: 0;
                }
            }

            .size {
                margin-right: 66px;

                @include media(md) {
                    margin-right: unset;
                }
            }

            .footer-action {
                .btn-action.btn {
                    .btn-action-text {
                        margin-left: unset;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

}


//  with carousel
#featureProductSliderProductPage {

    // slider
    .slick-list {
        .slick-track {
            display: flex;
            align-items: stretch;
        }

        .slick-slide {
            height: unset;

            &>div {
                height: 100%;

                .card-feature {
                    height: 100%;
                }

            }
        }
    }

}

.slick-slider.feature-product {
    @media(min-width:810px) {
        margin-top: 24px;
    }

    @include media(xl) {
        margin-bottom: 16px;
    }

    .card-feature {
        display: flex !important;
        margin-right: 16px;
    }

    .slick-dots {
        position: relative;
        margin-top: 16px;
        bottom: unset;

        li {
            button {
                &::before {
                    font-size: 10px;
                }
            }

            &.slick-active {
                display: inline-flex;
                justify-content: center;

                button {
                    width: 10px;
                    height: 10px;
                    margin-right: 0;
                    border-radius: 50%;
                    border: 2px solid #FAB005;
                    color: transparent;
                    padding: unset;

                    &::before {
                        display: none;
                    }

                }
            }
        }
    }
}

.footer-carousel__cart.slick-slider {
    .slick-list {
        .slick-track {
            display: flex;
            align-items: stretch;

            .slick-slide {
                height: unset;

                &>div {
                    height: 100%;

                    .card-feature {
                        height: 100%;
                    }
                }

            }
        }
    }

    .slick-arrow {
        &:hover {
            background-color: transparent !important;
            color: #545b62 !important;


        }
    }

}

.card-recent {
    // border-radius: unset;

    .card-detail {

        .card-title,
        .size {
            margin-left: unset;
        }

        .footer-action {
            justify-content: space-between;
        }
    }
}