.ModalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ModalTitle {
    margin-bottom: 0rem !important;
    font-size: 1.5rem;
    font-weight: bold;
}

.ModalSubTitle {
    margin-bottom: 0rem !important;
    font-size: 0.875rem;
    font-weight: normal;
}

.ModalCallTextWrapper {
    margin-top: 40px;
    display: flex;
    align-items: center;
}

.ModalCallText {
    margin-bottom: 0rem !important;
    font-size: 1.25rem;
    font-weight: normal;
}