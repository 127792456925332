.cart-wrapper {
  border-radius: 16px;
  overflow: hidden;
}

.cart-wrapper-mobile {
  .cart-item-wrapper {
    overflow: hidden;
    border-radius: 16px;

    @include media(txl) {
      border-radius: unset;
      overflow: unset;
    }
  }
}

.cart-item-wrapper {
  .cart-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 8px;
    background-color: #fff;
    padding: 16px;
    flex-wrap: wrap;
    row-gap: 16px;

    @media (min-width: 1181px) {
      flex-wrap: nowrap;
    }
    @media (max-width: 1180px) {
      .item-box.title {
        flex: 1 100%;
        max-width: none;
      }
      .item-box.detail-box {
        flex: 1 100%;
        max-width: none;
      }
    }

    .item-box {
      display: flex;
      column-gap: 8px;
      flex-direction: column;
      flex: 0 0 calc(50% - 8px);
      max-width: calc(50% - 8px);

      @include media(txl) {
        flex: unset;
        max-width: unset;
      }

      label {
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    .stock-box {
      svg {
        flex: 0 0 16px;
      }
    }

    .oeKey {
      border: 1px solid #ddd;
      font-weight: normal;
      font-size: 14px;
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
      padding: 2px 6px;
      line-height: 22px;
    }

    .oeIcon {
      display: inline-block;
      width: 24px;
      height: 24px;
    }

    .segment {
      display: inline-block;
      // width: 30px;

      i.icon-segment {
        display: inline-block;
        width: 30px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .counting-box {
      flex: 0 0 calc(50% - 8px);
      max-width: calc(50% - 8px);

      @include media(txl) {
        flex: unset;
        max-width: unset;
      }
    }

    .total-box {
      flex: 0 0 calc(100% - 8px);
      max-width: calc(100% - 8px);

      @include media(txl) {
        flex: unset;
        max-width: unset;
      }
    }

    .counting-box,
    .total-box {
      @include media(txl) {
        align-self: center;
      }
    }

    .close-btn {
      position: absolute;
      top: -5px;
      right: -10px;

      @include media(txl) {
        position: static;
      }
    }
  }
}

.recentView-1 {
  .icon-segment {
    padding-left: 8px;
    margin-left: 8px;
    display: inline-block;
    width: 38px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #fab005;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .year-box,
  .stock-box {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}
