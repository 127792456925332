* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}

// header offer

.offers-header-box {
  display: none;

  @media screen and (min-width: 960px) {
    display: block;
  }

  margin-top: 14px;
  margin-bottom: 14px;

  .btn.btn-offer {
    border-radius: 32px;
    padding: 14px 24px;
    background-color: transparent;
    border: unset;
    color: #8c887f;
    font-weight: 600;

    &:active,
    &:focus {
      background-color: rgba(250, 176, 5, 0.5) !important;
      outline-color: transparent !important;
      border-color: transparent !important;

      box-shadow: unset !important;
    }

    &:active {
      transform: scale(0.98);
    }

    &:hover {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
    }

    &.active {
      background-color: #fab005 !important;
      color: #fff;
    }
  }
}

// counter qty nuber
.add-item-qty {
  display: flex;
  align-items: center;

  .btn-qty {
    width: 28px;
    height: 28px;
    border-radius: 50px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;

    span[class*="icon-"] {
      font-size: 9px;
      font-weight: 600;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .decrement-btn {
    background-color: #f1f3f5;
    color: #74706d;
  }

  .increment-btn {
    background-color: #fab005;
    color: #fff;
  }

  .input-quty {
    min-width: 28px;

    input.form-control {
      background-color: transparent;
      text-align: center;
      width: fit-content;
      max-width: 50px;
      border: unset;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #46413c;
    }
  }
}

// icon status
.icon-stock {
  font-family: "Calibri", sans-serif !important;

  &::before {
    width: 16px;
    height: 17px;
    display: inline-block;
  }

  &.icon-green {
    color: #12b886;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 1.83334C4.32659 1.83334 1.33325 4.82668 1.33325 8.50001C1.33325 12.1733 4.32659 15.1667 7.99992 15.1667C11.6733 15.1667 14.6666 12.1733 14.6666 8.50001C14.6666 4.82668 11.6733 1.83334 7.99992 1.83334ZM7.49992 5.83334C7.49992 5.56001 7.72659 5.33334 7.99992 5.33334C8.27325 5.33334 8.49992 5.56001 8.49992 5.83334V9.16668C8.49992 9.44001 8.27325 9.66668 7.99992 9.66668C7.72659 9.66668 7.49992 9.44001 7.49992 9.16668V5.83334ZM8.61325 11.42C8.57992 11.5067 8.53325 11.5733 8.47325 11.64C8.40659 11.7 8.33325 11.7467 8.25325 11.78C8.17325 11.8133 8.08659 11.8333 7.99992 11.8333C7.91325 11.8333 7.82659 11.8133 7.74659 11.78C7.66659 11.7467 7.59325 11.7 7.52659 11.64C7.46659 11.5733 7.41992 11.5067 7.38659 11.42C7.35325 11.34 7.33325 11.2533 7.33325 11.1667C7.33325 11.08 7.35325 10.9933 7.38659 10.9133C7.41992 10.8333 7.46659 10.76 7.52659 10.6933C7.59325 10.6333 7.66659 10.5867 7.74659 10.5533C7.90659 10.4867 8.09325 10.4867 8.25325 10.5533C8.33325 10.5867 8.40659 10.6333 8.47325 10.6933C8.53325 10.76 8.57992 10.8333 8.61325 10.9133C8.64659 10.9933 8.66659 11.08 8.66659 11.1667C8.66659 11.2533 8.64659 11.34 8.61325 11.42Z' fill='%2312B886'/%3E%3C/svg%3E%0A");
    }
  }

  &.icon-yellow {
    color: #fab005;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 1.83334C4.32659 1.83334 1.33325 4.82668 1.33325 8.50001C1.33325 12.1733 4.32659 15.1667 7.99992 15.1667C11.6733 15.1667 14.6666 12.1733 14.6666 8.50001C14.6666 4.82668 11.6733 1.83334 7.99992 1.83334ZM7.49992 5.83334C7.49992 5.56001 7.72659 5.33334 7.99992 5.33334C8.27325 5.33334 8.49992 5.56001 8.49992 5.83334V9.16668C8.49992 9.44001 8.27325 9.66668 7.99992 9.66668C7.72659 9.66668 7.49992 9.44001 7.49992 9.16668V5.83334ZM8.61325 11.42C8.57992 11.5067 8.53325 11.5733 8.47325 11.64C8.40659 11.7 8.33325 11.7467 8.25325 11.78C8.17325 11.8133 8.08659 11.8333 7.99992 11.8333C7.91325 11.8333 7.82659 11.8133 7.74659 11.78C7.66659 11.7467 7.59325 11.7 7.52659 11.64C7.46659 11.5733 7.41992 11.5067 7.38659 11.42C7.35325 11.34 7.33325 11.2533 7.33325 11.1667C7.33325 11.08 7.35325 10.9933 7.38659 10.9133C7.41992 10.8333 7.46659 10.76 7.52659 10.6933C7.59325 10.6333 7.66659 10.5867 7.74659 10.5533C7.90659 10.4867 8.09325 10.4867 8.25325 10.5533C8.33325 10.5867 8.40659 10.6333 8.47325 10.6933C8.53325 10.76 8.57992 10.8333 8.61325 10.9133C8.64659 10.9933 8.66659 11.08 8.66659 11.1667C8.66659 11.2533 8.64659 11.34 8.61325 11.42Z' fill='%23FAB005'/%3E%3C/svg%3E%0A");
    }
  }

  &.icon-red {
    color: #fa5252;

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 1.83334C4.32659 1.83334 1.33325 4.82668 1.33325 8.50001C1.33325 12.1733 4.32659 15.1667 7.99992 15.1667C11.6733 15.1667 14.6666 12.1733 14.6666 8.50001C14.6666 4.82668 11.6733 1.83334 7.99992 1.83334ZM7.49992 5.83334C7.49992 5.56001 7.72659 5.33334 7.99992 5.33334C8.27325 5.33334 8.49992 5.56001 8.49992 5.83334V9.16668C8.49992 9.44001 8.27325 9.66668 7.99992 9.66668C7.72659 9.66668 7.49992 9.44001 7.49992 9.16668V5.83334ZM8.61325 11.42C8.57992 11.5067 8.53325 11.5733 8.47325 11.64C8.40659 11.7 8.33325 11.7467 8.25325 11.78C8.17325 11.8133 8.08659 11.8333 7.99992 11.8333C7.91325 11.8333 7.82659 11.8133 7.74659 11.78C7.66659 11.7467 7.59325 11.7 7.52659 11.64C7.46659 11.5733 7.41992 11.5067 7.38659 11.42C7.35325 11.34 7.33325 11.2533 7.33325 11.1667C7.33325 11.08 7.35325 10.9933 7.38659 10.9133C7.41992 10.8333 7.46659 10.76 7.52659 10.6933C7.59325 10.6333 7.66659 10.5867 7.74659 10.5533C7.90659 10.4867 8.09325 10.4867 8.25325 10.5533C8.33325 10.5867 8.40659 10.6333 8.47325 10.6933C8.53325 10.76 8.57992 10.8333 8.61325 10.9133C8.64659 10.9933 8.66659 11.08 8.66659 11.1667C8.66659 11.2533 8.64659 11.34 8.61325 11.42Z' fill='%23FA5252'/%3E%3C/svg%3E%0A");
    }
  }
}

// product main page
.product-wrapper {
  display: flex;
  flex-direction: column;

  @include media(xl) {
    flex-direction: row;
    margin: -12px;

    & > * {
      margin: 12px;
    }
  }

  .filter-wrapper {
    width: 100%;
    max-width: 299px;
  }

  .product-list {
    flex: 1;
  }
}

// product list
.product-list {
  margin-bottom: 50px;
  font-family: "Calibri", sans-serif !important;

  .headline {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #333029;

    @media (max-width: 1024px) {
      margin-top: 16px;
    }

    @media (min-width: 1300px) {
      display: none;
    }
  }

  .card-cover-list-table {
    display: none;
    border-radius: 16px 16px;
    border: 1px solid #f1ede3;
    box-shadow: unset;

    @media (min-width: 1300px) {
      display: block;
    }

    .ant-card-head {
      .ant-card-head-title {
        font-family: "Calibri", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #333029;
      }
    }

    .ant-card-body {
      padding: 0px;
    }
  }
}

// mobile card
.mobile-product-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @include media(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include media(lg) {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }

  .product-card-item {
    border: 1px solid #f1ede3;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 12px;

    .detail {
      display: flex;

      .image-box {
        background: #fbf7ed;
        border-radius: 6px;
        padding: 15px;
        width: 120px;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .detail-text {
        position: relative;
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        flex: 1;

        .brand {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #fab005;
        }

        .name {
          margin-top: 2px;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #141005;
          cursor: pointer;

          img.certificate {
            margin-left: 5px;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0;
            right: 0;
          }

          .oeKey {
            // display: inline-block;
            border: 1px solid #ddd;
            font-weight: normal;
            padding: 2px 6px;
            font-size: 14px;
            // margin-left: 5px;
            line-height: 20px;
            vertical-align: middle;
          }
        }

        ul {
          margin-top: 8px;
          display: flex;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #8c887f;
          align-items: baseline;

          .segmant {
            margin-left: 15px;
            position: relative;

            .icon-segment {
              display: inline-block;
              width: 24px;
              height: 24px;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              &::before {
                // vertical-align: middle;
                position: absolute;
                left: -7.5px;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                content: "";
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #fab005;
              }
            }
          }
        }

        .price-box {
          position: relative;
          border: 1px solid #e5e1d7;
          border-radius: 6px;
          margin-top: 12px;

          display: flex;
          align-items: baseline;

          .ant-select {
            position: relative;
            // margin-left:8px;
            border: unset;
            z-index: 1;
            width: 100%;
            display: inline-block;

            &.ant-select-focused {
              outline-color: transparent;
              box-shadow: unset;
              border: unset;

              .ant-select-selector {
                z-index: 0;
                outline-color: transparent;
                box-shadow: unset !important;
                border: unset !important;
                border-color: transparent !important;
              }
            }

            .ant-select-selector {
              z-index: 0;
              border: unset;
              padding: 0;
              padding-top: 2px;

              background-color: transparent;
              height: inherit;
              display: block;
              position: relative;

              .ant-select-selection-item {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #646159;
                padding-right: 35px;

                .price {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  color: #333029;
                  display: inline-block;
                  position: relative;
                  padding-right: 5px;

                  &::before {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 60%;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #f1ede3;
                  }
                }

                .year {
                  margin-left: auto;
                  margin-right: 8px;
                }
              }
            }

            .ant-select-arrow {
              z-index: -1;

              span[role="img"] {
                width: 16px;

                svg {
                  width: 16px;
                }
              }
            }

            .ant-select-selection-item {
              padding-left: 8px;
              display: inline-flex;
              width: 100%;
              justify-content: space-between;

              .icon-stock {
                display: inline-flex;
                position: relative;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                align-items: flex-end;
                margin-left: auto;

                &::before {
                  margin-top: -1px;
                  margin-right: 3px;
                }
              }
            }
          }

          .show-stock {
            position: absolute;
            right: 50px;
          }
        }
      }
    }

    .footer-product-card {
      margin-top: 10px;
      display: flex;
      gap: 12px;
      justify-content: space-between;
    }
  }
}

// button
// button action yellow
button.btn-action.btn {
  background-color: #fab005;
  display: flex;
  align-items: center;
  border-radius: 32px;
  width: fit-content;
  border: unset;

  span[class*="icon"] {
    width: 20px;
    height: 20px;

    &::before {
      vertical-align: middle;
    }
  }

  .btn-action-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-left: 8px;
    margin-top: 3px;
  }

  &.disabled {
    opacity: 0.5;
    background-color: #fab005;
  }
}

// table list product

.table-list-product {
  display: none;

  @include media(lg) {
    display: block;
  }

  width: 100%;
  box-shadow: unset;

  .ant-table {
    font-family: "Calibri", sans-serif;

    .ant-table-container {
      border-radius: 0;

      border: 1px solid #f1ede3;
    }

    table {
      border-radius: 0;
    }

    table > thead > tr:first-child > *:first-child {
      border-start-start-radius: 0;
    }

    table > thead > tr:first-child > *:last-child {
      border-start-start-radius: 0;
    }
  }

  .ant-table-selection-column {
    label {
      display: flex;
      margin-bottom: 0;
    }
  }

  thead.ant-table-thead {
    border-radius: 0;

    tr {
      th {
        padding: 18px;
        color: #8c887f;
        background-color: #ffffff;
        border-color: #f1ede3;

        &::before {
          background-color: transparent !important;
        }
      }
    }
  }

  tbody.ant-table-tbody {
    tr {
      td {
        padding: 13px 18px;
        border-color: transparent;
      }

      &:nth-child(2n + 2) {
        & > td {
          background-color: #f8f8f8;
        }
      }
    }

    .ant-table-row-selected > td {
      background-color: rgba(#fab005, 0.1) !important;
    }
  }

  .ant-table-selection-column {
    .ant-checkbox-wrapper {
      .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: #fab005;
      }

      .ant-checkbox-inner {
        width: 20px;
        height: 20px;

        &:hover {
          border-color: #fab005;
        }
      }

      .ant-checkbox:hover {
        .ant-checkbox-inner {
          border-color: #fab005;
        }
      }

      .ant-checkbox-checked {
        &:hover {
          &::after {
            border-color: #fab005;
          }

          .ant-checkbox-inner {
            border-color: #fab005;
            background-color: #fab005;
          }
        }

        .ant-checkbox-inner {
          border-color: #fab005 !important;
          background-color: #fab005 !important;
        }
      }
    }
  }

  .name-wrapper {
    display: flex;

    .image-cover {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fbf7ed;
      border-radius: 6px;
      padding: 8px;
      width: 50px;
      height: 50px;

      img {
        width: auto;
        height: 34px;
        object-fit: content;
      }
    }

    .item-detail {
      margin-left: 12px;

      .title {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #141005;

        .certificate {
          margin-left: 5px;
          display: block;
          width: 24px;
          height: 24px;
        }

        .oeKey {
          display: block;
          border: 1px solid #ddd;
          font-weight: normal;
          padding: 2px 6px;
          font-size: 14px;
          margin-left: 5px;
          line-height: 20px;
        }
      }

      .detail {
        margin-top: 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #8c887f;
        display: flex;
        align-items: center;

        .size {
          margin-bottom: 0;
        }

        .segment {
          margin-left: 14px;
          position: relative;
          margin-bottom: 0;

          .icon-segment {
            display: inline-block;
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            &::before {
              display: inline-block;
              content: "";
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background-color: #fab005;
              position: absolute;
              left: -7.5px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .cerfifiedIcon {
          margin-bottom: 0;
          .certificate {
            margin-left: 5px;
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .brand-wrapper {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
      aspect-ratio: 1/1;
    }

    .text {
      margin-left: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #333029;
    }
  }

  .price-wrapper {
    border: 1px solid #e5e1d7;
    border-radius: 9px;
    padding: 8px 12px;

    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;

    .ant-select {
      width: 100%;
      border: unset;

      z-index: 1;

      &.ant-select-focused {
        outline-color: transparent;
        box-shadow: unset;
        border: unset;

        .ant-select-selector {
          z-index: 0;
          outline-color: transparent;
          box-shadow: unset !important;
          border: unset !important;
          border-color: transparent !important;
        }
      }

      .ant-select-selector {
        z-index: 0;
        border: unset;
        padding: 0;
        padding-top: 2px;
        background-color: transparent;
        height: inherit;

        .ant-select-selection-item {
          color: #646159;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;

          .price {
            justify-self: start;

            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #333029;
            padding-right: 8px;
            position: relative;
            margin-right: auto;

            &::after {
              content: "";
              width: 1px;
              height: 16px;
              background-color: #f1ede3;
              position: absolute;
              top: 2px;
              right: 0;
            }
          }

          .tire-stock {
            margin-left: 8px;
            display: flex;

            align-self: center;

            .icon-stock {
              display: inline-flex;
              align-items: baseline;
              column-gap: 3px;

              &::before {
                position: relative;
                bottom: -3px;
              }
            }
          }
        }
      }

      .ant-select-arrow {
        z-index: -1;
        height: 20px;
        inset-inline-end: -4px;
        margin-top: -9px;

        span[role="img"] {
          width: 16px;

          svg {
            width: 16px;
          }
        }
      }
    }
  }

  // button action yellow
  button.btn-action.btn {
    background-color: #fab005;
    display: flex;
    align-items: center;
    border-radius: 32px;
    min-width: 146px;
    justify-content: center;
    width: fit-content;
    border: unset;

    span[class*="icon"] {
      width: 20px;
      height: 20px;

      &::before {
        vertical-align: middle;
      }
    }

    .btn-action-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      margin-left: 8px;
      margin-top: 3px;

      @media screen and (max-width: 1402px) {
        // display: none;
      }
    }

    &.disabled {
      opacity: 0.5;
      background-color: #fab005;
    }
  }
}

// select
.stock-tire {
  .icon-stock {
    display: inline-flex;
    align-items: baseline;

    &::before {
      position: relative;
      bottom: -2px;
      margin-right: 5px;
    }
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    background-color: rgba(250, 176, 5, 0.5);
  }
}

.product-list {
}

// overlay select list

.select-price-list-overlay {
  padding: 0;
  width: 200px !important;

  .ant-select-item-option-content {
    display: flex;

    .price {
      margin-right: 8px;
      padding-right: 8px;
      position: relative;

      &::after {
        content: "";
        width: 1px;
        height: 16px;
        background-color: #f1ede3;
        position: absolute;
        top: 2px;
        right: 0;
      }
    }

    .tire-stock {
      margin-left: 8px;

      .icon-stock {
        &::before {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }

  .ant-select-item-option-selected {
    background-color: rgba(250, 176, 5, 0.2) !important;
  }
}

.product-details {
  .modal-content {
    border-radius: 16px !important;
    border-color: #f1ede3;
    margin: 0 auto;

    .title-wrapper {
      border-bottom: 2px solid #fab005;
      display: flex;
      row-gap: 5px;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 16px;
      padding-bottom: 8px;

      @include media(md) {
        padding-bottom: 16px;
      }

      .product-title {
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: unset;

        @include media(md) {
          font-size: 24px;
          line-height: 24px;
        }

        @include media(ll) {
          font-size: 32px;
          line-height: 32px;
        }

        .oeKey {
          display: inline-block;
          vertical-align: middle;
          border: 1px solid #ddd;
          font-weight: normal;
          padding: 2px 6px;
          font-size: 16px;
          margin-left: 5px;
          line-height: 20px;
        }
      }

      .product-size {
        font-size: 16px;
        color: #8c887f;
        margin-left: 0;
      }

      .product-type {
        padding-left: 20px;
        position: relative;

        i.icon-segment {
          display: inline-block;
          width: 30px;

          &::before {
            content: "";
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #fab005;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .product-detail-footer {
      .row {
        justify-content: space-between;
        row-gap: 16px;
      }

      .item-box {
        display: flex;
        column-gap: 8px;
        align-items: center;

        label {
          margin-bottom: 0px;
          font-weight: bold;
        }
      }

      .footer-action {
        display: inline-flex;
        justify-content: flex-end;

        @media (min-width: 992px) {
          min-width: 230px;
        }

        button.btn-action {
          padding: 8px 24px;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .table-list-product {
    .name-wrapper {
      .item-detail {
        margin-left: unset;
        margin-right: 12px;

        .title img.certificate {
          margin-left: unset;
          margin-right: 5px;
        }

        .segment {
          margin-left: unset;
          margin-right: 14px;

          &::before {
            left: unset;
            right: -7.5px;
          }
        }
      }
    }

    .brand-wrapper {
      .text {
        margin-left: unset;
        margin-right: 8px;
      }
    }

    .price-wrapper {
      .ant-select {
        margin-left: unset;
        // margin-right: 8px;
        right: unset;
        left: 0;

        .ant-select-arrow {
          inset-inline-end: 3px;
        }

        .ant-select-selection-item {
          .price {
            margin-left: 8px;
            margin-right: unset;
            padding-left: 8px;
            padding-right: unset;

            &::after {
              right: unset;
              left: 0;
            }
          }

          .tire-stock {
            margin-right: 8px;
            margin-left: unset;
            align-items: baseline;
          }
        }
      }
    }

    .btn-action.btn {
      .btn-action-text {
        margin-left: unset;
        margin-right: 8px;
      }
    }
  }

  .select-price-list-overlay {
    .ant-select-item-option-content {
      .price {
        margin-left: 8px;
        margin-right: unset;
        padding-left: 8px;
        padding-right: unset;

        &::after {
          right: unset;
          left: 0;
        }
      }

      .tire-stock {
        margin-right: 8px;
        margin-left: unset;
        align-items: baseline;
      }
    }
  }

  // mobile card list
  .product-card-item {
    .detail {
      .detail-text {
        padding-right: 12px;
        padding-left: unset;

        .name img.certificate {
          margin-right: 5px;
          margin-left: unset;
          right: unset;
          left: 0;
        }

        .segmant {
          margin-right: 15px;
          margin-left: unset;

          &::before {
            right: -7.5px;
            left: unset;
          }
        }

        .price-box {
          .ant-select {
            margin-left: unset;
            margin-right: 8px;

            .ant-select-selector {
              &::before {
                left: unset;
                right: 0;
              }

              .ant-select-selection-item {
                padding-left: 35px;
                padding-right: 8px;

                .price {
                  padding-right: unset;
                  padding-left: 5px;

                  &::before {
                    left: 0;
                    right: unset;
                  }
                }

                .year {
                  margin-right: auto;
                  margin-left: unset;
                }

                .icon-stock {
                  margin-left: unset;
                  margin-right: 8px;

                  &::before {
                    margin-left: 3px;
                    margin-right: unset;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-product-card {
      .btn-action.btn {
        .btn-action-text {
          margin-left: unset;
          margin-right: 8px;
        }
      }
    }
  }
}
